import React, { Component } from 'react'
import Header from '../Header/Header'
import Fade from 'react-reveal/Fade'
// import Slide from 'react-reveal/Slide'
import BannerImage from '../Reusable/BannerImage/BannerImage'
import QuickMenu from '../Reusable/QuickMenu/QuickMenu'
import PlanCard from './PlanCard'
import Footer from '../../components/Footer/Footer'
import { getDefaultCity } from '../../global.js'
import OverlayLayer from '../OverlayComponents/Overlay/Overlay'
// css
import './MemberShip.css'
import './MemberShipResponsive.css'

class MemberShip extends Component {
  constructor (props) {
    super(props)
    this.state = {
      componentName: '',
      price: '',
      planList: [{
        planId: 1,
        planName: 'privilege',
        planPrice: '100k',
        valuePaid: '100000',
        planWorth: '160000',
        planValidity: '20 month',
        menuList: ['Services Worth 160K', 'Validity 20 months*']
      }, {
        planId: 2,
        planName: 'Platinum',
        planPrice: '50k',
        valuePaid: '50000',
        planWorth: '75000',
        planValidity: '18 month',
        menuList: ['Services Worth 75K', 'Validity 18 months*']
      }, {
        planId: 3,
        planName: 'Diamond',
        planPrice: '35k',
        valuePaid: '35000',
        planWorth: '50000',
        planValidity: '12 month',
        menuList: ['Services Worth 50K', 'Validity 12 months*']
      }, {
        planId: 4,
        planName: 'gold',
        planPrice: '25k',
        valuePaid: '25000',
        planWorth: '35000',
        planValidity: '9 month',
        menuList: ['Services Worth 35K', 'Validity 9 months*']
      }, {
        planId: 5,
        planName: 'silver',
        planPrice: '10k',
        valuePaid: '10000',
        planWorth: '13000',
        planValidity: '6 month',
        menuList: ['Services Worth 13K', 'Validity 6 months*']
      }, {
        planId: 6,
        planName: 'crystal',
        planPrice: '5k',
        valuePaid: '5000',
        planWorth: '6000',
        planValidity: '3 month',
        menuList: ['Services Worth 6K', 'Validity 3 months*']
      }],
      bannerItem: {
        imageUrl: '/assets/images/membership_banner.jpg',
        title: 'membership',
        description: 'We invite you to escape the chaos of daily life and rejuvenate in our private retreat.',
        overlayComp: 'Membership'
      },
      terms: ['Advance appointment is mandatory for all members.',
        'The membership card needs to be presented each time a service is redeemed.',
        'This card is transferable.',
        'This card cannot be refunded or exchanged for cash.',
        'Promotions and discounts cannot be clubbed with the usage of membership card.',
        'In case of lost or stolen card, please write to info@aromathai.net or inform the outlet where the membership was purchased at. ',
        'Use of membership card constitutes acceptance of the T&C as amended.',
        'Replacement of the membership card will be at cost of Rs. 300/- per card. ',
        'Once a membership is purchased, card has to be collected at outlet where the payment has been made.',
        'This membership card can be redeemed in all the outlets except for franchise outlets.',
        'Membership cards that are bought in Kochi can only be redeemed only where it was bought. This is for the franchises only.',
        'Validity of membership card starts from the date of purchase.',
        // 'The validity of membership card can be extended with a grace period of 30 days only.'
      ],
      amount: 0
    }
    this.handleOverlay = this.handleOverlay.bind(this)
    this.handleCorefitOverlay = this.handleCorefitOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    // this.paymentHandler = this.paymentHandler.bind(this)
    // this.changeAmount = this.changeAmount.bind(this)
  }

  handleCorefitOverlay () { this.setState({ componentName: 'Corefit' }) }

  handleOverlay (comp, planDet) {
    if (planDet !== undefined) {
      // console.log('plan price', planDet)
      this.setState({ price: planDet })
    } else { this.setState({ price: '' }) }
    this.setState({ componentName: comp })
  }

  hideOverlay () { this.setState({ componentName: '' }) }

  componentDidMount () {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome
    window.localStorage.setItem('pathName', 'ME')
    // if (window.localStorage.getItem('cityName') === null) {
    //   this.handleOverlay('Places')
    // }
    if (getDefaultCity() === null) {
      this.handleOverlay('Places')
    }
  }

  render () {
    let { planList, bannerItem, componentName } = this.state

    const componentOpen = componentName === 'Membership'
      ? <OverlayLayer typeOfComponent='Membership' hideOverlay={this.hideOverlay} planDetails={this.state.price} size='lg' />
      : this.state.componentName === 'Places'
        ? <OverlayLayer typeOfComponent='Places' hideOverlay={this.hideOverlay} size='lg' />
        : this.state.componentName === 'Terms'
          ? <OverlayLayer typeOfComponent='Terms' hideOverlay={this.hideOverlay} terms={this.state.terms} size='lg' />
          : this.state.componentName === 'Corefit'
            ? <OverlayLayer typeOfComponent='Corefit' hideOverlay={this.hideOverlay} />
            : ''

    return (
      <div>
        {componentOpen}
        <Header handleCorefitOverlay={this.handleCorefitOverlay} />
        <div id='membership'>
          <BannerImage banner={bannerItem} social={false} bannerBtn handleOverlay={() => this.handleOverlay('Membership')} btnText={'buy now'} />
          <QuickMenu handleOverlay={() => this.handleOverlay('Membership')} />
          <div className='content-blk'>
            <div className='content-inner-blk'>
              <div className='price-header'>
                <span className='price-table-icon' />
                <div className='hr-line' />
                <Fade bottom distance='30px'>
                  <h3>pricing tables</h3>
                  <p className='descp'> Choose from various tiers of Memberships at AromaThai that include both savings &amp; value-added bonuses to suit your relaxation and wellness goals.</p>
                </Fade>
              </div>
              <div className='plan-blk'>
                {planList.map((plan, index) => {
                  return <PlanCard key={index} transition={(index + 1) * 200} plan={plan} handleOverlay={this.handleOverlay} />
                })
                }
              </div>
              {/* <p className='conditions-apply-to'>*Conditions apply lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.</p> */}
              <div className='terms-cond' data-testid='testtermsoverlay' onClick={() => this.handleOverlay('Terms')}>*Terms and Conditions</div>
            </div>
            <div className='side-img-blk'>
              {/* <Slide right duration={2000}> */}
              <img src='/assets/images/ic_stone1.jpg' alt='' />
              {/* </Slide> */}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default MemberShip
