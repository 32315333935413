import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'
// import 'react-datepicker/dist/react-datepicker.css'
import OverlayHead from '../OverlayHead/Overlayheader'
import 'react-datetime/css/react-datetime.css'
import { fetchData } from '../../../API/ApiRequest.js'
import { validateEmail, stateDetails, firebase } from '../../../global'
import './Franchise.css'
import ReCAPTCHA from "react-google-recaptcha";
// import { getDate } from 'date-fns'

const db = firebase.firestore()

class Franchise extends Component {
  constructor (props) {
    super(props)
    this.itemRender = (li, itemProps) => {
      let itemChildren
      if (itemProps.dataItem.disabled) {
        itemChildren = <span style={{ color: '#ccc', cursor: 'auto' }}>{li.props.children}</span>
      } else {
        itemChildren = <span>{li.props.children}</span>
      }

      return React.cloneElement(li, li.props, itemChildren)
    }
    this.state = {
      locationDetailsArr: stateDetails(),
      // servicesDataArr: this.props.servicesData,
      errMsg: '',
      mailMessage: '',
      mailStatus: 'pre-suc-response',
      addedAmount: 0,
      disablebtn: 'outer-btn disablebtn',
      defaultCityId: '',
      exRadioIndustry: '',
      industryPlaceDesc: '',
      formFields: {
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        occupation: '',
        industry: '',
        placeDetails: '',
        investmentRange: '',
        exRadioIndustry: '',
        placeState: '',
        city: '',
        location: '',
        bookingLocationId: '',
        // bookingLocation: this.props.services ? this.props.services.location.location : '',
        exRadio: 'no',
        placeRadio: 'no'
      },
      formErrors: {
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        placeState: '',
        occupation: '',
        industry: '',
        investmentRange: '',
        state: '',
        city: '',
        location: '',
        // placeDetails: '',
        // exRadioIndustry:'',
        exRadio: ''
      },
      locationList: [], // Location list based on city selection
      exRadio: 'no',
      placeRadio: 'no',
      fromWhere: false,
      overlayHead: {
        title: 'FRANCHISE FORM',
        overlayDesc: "Feel free to contact us if you're ready to work with AromaThai. You must, of course, be prepared for instant success.",
        imgClass: 'booking-register'
      },
      investmentDetails: [
        { 'code': '1', 'name': 'Less than 40 Lakhs' },
        { 'code': '2', 'name': '40 Lakhs to 60 Lakhs' },
        { 'code': '3', 'name': '60 Lakhs to 80 Lakhs' },
        { 'code': '4', 'name': '80 Lakhs to 1 Cr' },
        { 'code': '5', 'name': 'Above 1 Cr' }
      ],
      branchEmail: '',
      token: null
    }

    this.captchaRef = React.createRef();

    this.handleDropDownChange = this.handleDropDownChange.bind(this)
    this.onChange = this.onChange.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this) // For update state when change the input
    this.experienceOnChange = this.experienceOnChange.bind(this) // Radio button onchange event for experiance
    this.placeOnChange = this.placeOnChange.bind(this) // Radio button onchange event for place
    this.handleBookingDetails = this.handleBookingDetails.bind(this) // Form Validation default
    this.handleEmptyFormCheck = this.handleEmptyFormCheck.bind(this)
    this.itemRender = this.itemRender.bind(this)
  }

  onChange(value) {
    this.setState({token:value, disablebtn: 'outer-btn'});
  }

  // Membership details submision.
  handleBookingDetails () {
    let { firstName, lastName, email, mobile, occupation, industry, investmentRange, city, placeState, location, placeDetails, exRadioIndustry } = this.state.formFields
    let formErrors = this.state.formErrors
    this.setState({ disablebtn: 'outer-btn disablebtn' })

    if (this.handleEmptyFormCheck()) {
      // fields validation
      if (!(/^[a-zA-Z ]+$/.test(firstName))) {
        formErrors.firstName = 'error'
        this.setState({ errMsg: 'Please enter valid first name' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }
      if (!(/^[a-zA-Z ]+$/.test(lastName))) {
        formErrors.name = 'error'
        this.setState({ errMsg: 'Please enter valid last name' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }
      if (!validateEmail(email)) {
        formErrors.email = 'error'
        this.setState({ errMsg: 'Invalid email! Please enter valid email' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }
      if (!(/^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/.test(mobile))) {
        formErrors.mobile = 'error'
        this.setState({ errMsg: 'Invalid mobile number! Please enter valid mobile number' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }
      this.setState({ errMsg: '' })

      let FranchiseContactDetails = {
        'Name': firstName + ' ' + lastName,
        'Mobile No.': mobile,
        'Email Id': email,
        'Preferred State': placeState,
        'Preferred City': city,
        'Preferred Location': location,
        'Occupation': occupation,
        'Industry': industry,
        'Investment Value': investmentRange,
        'Franchise Experience': (this.state.exRadio === 'yes' ? 'Yes' : 'No'),
        'Industry Type': exRadioIndustry,
        'Place Owned': (this.state.placeRadio === 'yes' ? 'Yes' : 'No'),
        'Place Details': placeDetails
      }
      // console.log('franchise details', FranchiseContactDetails)
      this.setState({ mailMessage: 'Please wait...' })
      let reqRouteRecipient = '/ATFranchiseFormAcknowledgementToHO'
      fetchData( { FranchiseContactDetails: FranchiseContactDetails , token: this.state.token }, reqRouteRecipient).then(response => {
        if (response.message === 'SUCCESS') {
          db.collection('franchise').add({
            first_name: firstName,
            last_name: lastName,
            mobile: mobile,
            email: email,
            preferred_state: placeState,
            preferred_city: city,
            preferred_location: location,
            occupation: occupation,
            industry: industry,
            investment_range: investmentRange,
            franchise_experience: (this.state.exRadio === 'yes' ? 'Yes' : 'No'),
            industry_type: exRadioIndustry,
            place_owned: (this.state.placeRadio === 'yes' ? 'Yes' : 'No'),
            place_details: placeDetails,
            mailType: 'Franchise',
            objMailData: FranchiseContactDetails
          })
          // console.log('Franchise mail Sent')
          this.setState({ mailStatus: 'suc-response', disablebtn: 'outer-btn', mailMessage: 'Thank you for contact us! We will get back to you soon.' })
          setTimeout(function () {
            this.setState({ mailMessage: '' })
            this.props.hideOverlay()
          }.bind(this), 3000)
          const formFields = this.state.formFields
          formFields.firstName = formFields.lastName = formFields.placeState = formFields.city = formFields.location = formFields.occupation = formFields.industry = formFields.investmentRange = formFields.exRadioIndustry = formFields.placeDetails = ''
          this.setState({ branchEmail: '', formFields: formFields })
        } else {
          console.log('Franchise mail Failure')
        }
      })
      this.captchaRef.current.reset();
    } else {
      this.setState({ disablebtn: 'outer-btn disablebtn' })
      this.captchaRef.current.reset();
    }
  }
  // to check all fields are entered or not.
  handleEmptyFormCheck () {
    let valid = true
    const formFields = this.state.formFields
    const errorFields = this.state.formErrors
    Object.keys(formFields).forEach(key => {
      let value = formFields[key]
      if (!((key === 'exRadioIndustry') || (key === 'placeDetails'))) {
        // console.log('leys', key)
        if (value === null || value.length <= 0) {
          if (Object.keys(this.state.formErrors).indexOf(key) >= 0) {
            errorFields[key] = 'error'
            valid = false
            this.setState({ errMsg: '*Please enter mandatory fields' })
          }
        }
      }
    })
    if ((this.state.exRadio === 'yes') && (this.state.formFields.exRadioIndustry.length <= 0)) {
      errorFields['exRadioIndustry'] = 'error'
      valid = false
      this.setState({ errMsg: '*Please enter mandatory fields' })
    } else {
      errorFields['exRadioIndustry'] = ''
      // valid = true
    }
    if ((this.state.placeRadio === 'yes') && (this.state.formFields.placeDetails.length <= 0)) {
      errorFields['placeDetails'] = 'error'
      this.setState({ errMsg: '*Please enter mandatory fields' })
      valid = false
    } else {
      errorFields['placeDetails'] = ''
      // valid = true
    }
    // console.log('error fields', errorFields)
    this.setState({ errorFields })
    // this.setState({ formFields: formFields })
    return valid
  }

  // Input change event
  handleInputChange (e) {
    const { name, value } = e.target
    let formErrors = this.state.formErrors
    if (name === 'firstName') formErrors.firstName = ''
    else if (name === 'lastName') formErrors.lastName = ''
    else if (name === 'mobile') formErrors.mobile = ''
    else if (name === 'email') formErrors.email = ''
    else if (name === 'location') formErrors.location = ''
    else if (name === 'occupation') formErrors.occupation = ''
    else if (name === 'industry') formErrors.industry = ''
    else if (name === 'placestate') formErrors.placeState = ''
    else if (name === 'placeDetails') formErrors.placeDetails = ''
    else if (name === 'city') formErrors.city = ''
    else if (name === 'exRadioIndustry') formErrors.exRadioIndustry = ''

    this.setState({ formErrors, errMsg: '' })
    const formfieldsState = this.state.formFields
    if (name === 'mobile') {
      if (!isNaN(value)) {
        // console.log('skdjvbjsd')
        formfieldsState[e.target.name] = e.target.value
      }
    } else {
      formfieldsState[e.target.name] = e.target.value
    }
    // console.log('djkvn', formfieldsState)
    this.setState({ formfieldsState })
  }

  // Basic Details Dropdown changes.
  handleDropDownChange (event, objType) {
    let { locationDetailsArr } = this.state
    // const updatedStates = this.handleDropdownState(event) // return the updated values from drop down
    // this.setState({ updatedStates })
    const formError = this.state.formErrors
    // console.log(event.target.name)
    formError[event.target.name] = ''
    this.setState({ formErrors: formError })
    const removedError = { ...this.state.formErrors }
    removedError[event.target.name] = ''
    this.setState({ removedError })
    if (objType === 'bookingState') {
      // console.log('state', this.state)
      // to get city details based on city selection
      const data = locationDetailsArr.filter((values) => values.city === event.target.value.city)
      this.setState({ locationList: data[0].locationList })

      // Remove the drop down value when change the city drop down
      const formFields = this.state.formFields
      formFields.placeState = event.target.value.name
      // console.log(event.target.value.name)
      this.setState({ formFields: formFields })

      // to get location list based on city selection
      this.setState({ locationList: data[0].locationList })
    } else if (objType === 'bookingLocation') {
      const data = this.state.locationList.filter((values) => values.location === event.target.value.location)
      let formFields = this.state.formFields
      /*if (data[0].locationId === 13 || data[0].locationId === 14) {
        formFields.bookingLocation = ''
        formFields.bookingLocationId = ''
      } else {
        formFields.bookingLocation = data[0].location
        formFields.bookingLocationId = data[0].locationId
      }*/

      formFields.bookingLocation = data[0].location
      formFields.bookingLocationId = data[0].locationId

      this.setState({ formFields: formFields, branchEmail: data[0].email })
    } else if (objType === 'investment') {
      const data = this.state.investmentDetails.filter((values) => values.name === event.target.value.name)
      let formFields = this.state.formFields
      formFields.investmentRange = data[0].name
      // console.log(data[0].name)
      this.setState({ formFields: formFields })
    }
  }

  // Clean service Drop down value
  cleanServiceDropDownValues () {
    const servicefields = [...this.state.serviceDetails]
    servicefields.forEach(function (val, idx) {
      Object.keys(val.serviceDetail).forEach(key => { val.serviceDetail[key] = '' })
    })
    this.setState({ servicefields })
  }

  // Radio button on change event
  experienceOnChange (event) { this.setState({ exRadio: event.target.value, exRadioIndustry: '' }) }
  placeOnChange (event) { this.setState({ placeRadio: event.target.value, industryPlaceDesc: '' }) }
  // Add Service details

  // this function returns service type based on corresponding service name
  // returnedServiceTypes (serviceName) { console.log(serviceName) }

  render () {
    let { locationDetailsArr } = this.state
    let { firstName, lastName, email, mobile, occupation, industry, placeState, city, investmentRange, location, exRadioIndustry } = this.state.formFields
    return (
      <div id='franchise-container'>
        <div className='wrapper'>
          <OverlayHead overlaycontent={this.state.overlayHead} />
          <div className='form-container booking-form'>
            <div className='left-container'>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>First Name*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='firstname' name='firstName' className={'form-field ' + this.state.formErrors.firstName} autoComplete='off' value={firstName} placeholder='Enter your First Name' onChange={this.handleInputChange} maxLength='50' />
                  </div>
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Email Id*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='email' name='email' className={'form-field ' + this.state.formErrors.email} autoComplete='off' value={email} onChange={this.handleInputChange} placeholder='Enter your Email Id' maxLength='50' />
                  </div>
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Occupation*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='occupation' name='occupation' className={'form-field ' + this.state.formErrors.occupation} value={occupation} autoComplete='false' onChange={this.handleInputChange} placeholder='Enter your Occupation' maxLength='50' />
                  </div>
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Investment Range*</label>
                  <div className='field-dropdown'>
                    <DropDownList
                      data={this.state.investmentDetails}
                      textField='name'
                      name='investmentRange'
                      id='code'
                      dataItemKey='code'
                      className={this.state.formErrors.investmentRange}
                      // value={{ code: null, investmentRange: (investmentRange !== '') ? investmentRange : '-Select Range-' }}
                      defaultValue={{ id: '', name: (investmentRange !== '') ? investmentRange : 'Select Range' }}
                      onChange={(e) => this.handleDropDownChange(e, 'investment')}
                    />
                  </div>
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups input-dropdown'>
                  <label className='field-caption'>preferred City*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='city' name='city' className={'form-field ' + this.state.formErrors.city} autoComplete='false' value={city} onChange={this.handleInputChange} placeholder='Enter your City Name' maxLength='50' />
                  </div>
                </div>
              </div>
              <div className='bottom full-width-sec'>
                <div className='input-container radio-sec'>
                  <div className='input-groups radio-field run-franchise'>
                    <label className='field-caption stl-normal'>Do you have experience <br />of running a Franchise*</label>
                    <div className='radio-input-section '>
                      <label className='radio-container' onClick={this.experienceOnChange}>Yes<input type='radio' checked={this.state.exRadio === 'yes'} value='yes' data-testid='exRadio' name='exRadio' className='form-field-radio' /> <span className='checkmark' /></label>
                      <label className='radio-container' onClick={this.experienceOnChange}>No<input type='radio' checked={this.state.exRadio === 'no'} value='no' name='exRadio' className='form-field-radio' /><span className='checkmark' /></label>
                    </div>
                  </div>
                </div>
                {(this.state.exRadio === 'yes')
                  ? <div className='input-container membership_card_sec'>
                    <div className='input-groups'>
                      <label className='field-caption'>Which Industry*</label>
                      <div className='field-input'>
                        <input type='text' name='exRadioIndustry' className={'form-field ' + this.state.formErrors.exRadioIndustry} autoComplete='off' value={exRadioIndustry} onChange={this.handleInputChange} placeholder='Enter your Industry Name' maxLength='50' />
                      </div>
                    </div>
                  </div>
                  : ''
                }
              </div>
            </div>
            <div className='right-container'>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Last Name*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='lastname' name='lastName' className={'form-field ' + this.state.formErrors.lastName} autoComplete='off' value={lastName} placeholder='Enter your Last Name' onChange={this.handleInputChange} maxLength='50' />
                  </div>
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Mobile No*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='mobileno' name='mobile' className={'form-field ' + this.state.formErrors.mobile} autoComplete='false' value={mobile} onChange={this.handleInputChange} placeholder='Enter your Mobile No' maxLength='10' />
                  </div>
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Industry*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='industry' name='industry' className={'form-field ' + this.state.formErrors.industry} value={industry} autoComplete='false' onChange={this.handleInputChange} placeholder='Enter your Industry' maxLength='50' />
                  </div>

                </div>
              </div>

              <div className='input-container'>
                <div className='input-groups input-dropdown'>
                  <label className='field-caption'>preferred State*</label>
                  <div className='field-dropdown'>
                    <DropDownList
                      data={locationDetailsArr}
                      dataItemKey='code'
                      textField='name'
                      name='placeState'
                      id='code'
                      className={this.state.formErrors.placeState}
                      defaultValue={{ id: '', name: (placeState !== '') ? placeState : 'Select State' }}
                      onChange={(e) => this.handleDropDownChange(e, 'bookingState')}
                    />
                  </div>
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>preferred Location*</label>
                  <div className='field-input'>
                    <input type='text' data-testid='location' name='location' className={'form-field ' + this.state.formErrors.location} autoComplete='false' value={location} onChange={this.handleInputChange} placeholder='Enter your Location Name' maxLength='50' />
                  </div>
                </div>
              </div>
              <div className='bottom full-width-sec'>
                <div className='input-container radio-sec'>
                  <div className='input-groups radio-field'>
                    <label className='field-caption stl-normal'>Do you own the place?*</label>
                    <div className='radio-input-section'>
                      <label className='radio-container' onClick={this.placeOnChange}>Yes<input type='radio' data-testid="ownplace" checked={this.state.placeRadio === 'yes'} value='yes' name='placeRadio' className='form-field-radio' /> <span className='checkmark' /></label>
                      <label className='radio-container' onClick={this.placeOnChange}>No<input type='radio' checked={this.state.placeRadio === 'no'} value='no' name='placeRadio' className='form-field-radio' /><span className='checkmark' /></label>
                    </div>
                  </div>
                </div>
                {(this.state.placeRadio === 'yes')
                  ? <div className='input-container textarea-sec'>
                    <div className='input-groups input-textarea'>
                      <label className='field-caption note-filed'>Place Details*</label>
                      <div className='field-input'>
                        <textarea name='placeDetails' data-testid='placedetails' className={'cls-notes form-field ' + this.state.formErrors.placeDetails} value={this.state.formFields.placeDetails} autoComplete='false' onChange={this.handleInputChange} placeholder='Enter address/landmark of the place' maxLength='500' />
                      </div>
                    </div>
                  </div>
                  : ''
                }

              </div>

            </div>
          </div>
          
          <ReCAPTCHA
            sitekey="6LfAZK0lAAAAAO5kStWR98uVO5X3eFWedbiyYDUn"
            onChange={this.onChange}
            ref={this.captchaRef}
          />

           {/* Error message */}
          <div className='cls-err-msg'>{this.state.errMsg}&nbsp;</div>
          <div className={this.state.mailStatus}>{this.state.mailMessage}&nbsp;</div>

          {/* Submit button */}
          <div className='form-submit-section'>
            <div className={this.state.disablebtn}>
              <div className='btn register-booking' data-testid='registerbooking' onClick={this.handleBookingDetails}>SUBMIT</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Franchise)
