import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import OverlayHead from "../OverlayHead/Overlayheader";
import ToggleButton from "../ToggleButton/ToggleButton";
import SelfMembership from "./Self/Self";
import GiftMembership from "./Gift/Gift";
import {
  firebase,
  validateEmail,
  cityDetails,
  ccavMerchantId,
  ccavRedirectURL,
  ccavCancelURL,
  getDefaultCity,
} from "../../../global";
import "./Membership.css";
// import { fetchData } from '../../../API/ApiRequest.js'

// Check default form state is empty
// const formValid = (formErrors) => {
//   let valid = true
//   Object.values(formErrors).forEach(val => { val.length >= 0 && (valid = false) })
//   return valid
// }

const db = firebase.firestore();

class Membership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleIsChecked: false,
      dropDownDefaultValue: null,
      defaultCity:
        getDefaultCity() === null ? "" : getDefaultCity().toLowerCase(),
      defaultCityId: "",
      planDetails: this.props.planDetails ? this.props.planDetails : "",
      isNumber: "",
      errMsg: "",
      name: "",
      mobileNo: "",
      email: "",
      plan: "",
      city: "",
      location: "",
      recipientName: "",
      recipientMobile: "",
      recipientEmail: "",
      mailMessage: "",
      mailStatus: "",
      disablebtn: "outer-btn",
      branchEmail: "",
      formfields: {
        name: "",
        mobileNo: "",
        email: "",
        plan: "",
        city: "",
        location: "",
      },
      recipientDetails: {
        recipientName: "",
        recipientMobile: "",
        recipientEmail: "",
      },
      cityDetails: cityDetails(), // Default city and location details from
      locationDetails: [], // Location list based on city
      formErrors: {
        name: "",
        mobileNo: "",
        email: "",
        city: "",
        plan: "",
        location: "",
        recipientName: "",
        recipientMobile: "",
        recipientEmail: "",
      },
      overlayHead: {
        title: "MEMBERSHIP DETAILS",
        overlayDesc:
          "An investment for yourself or your loved ones can be done in a few seconds conveniently below.",
        imgClass: "membership-details",
      },
      servicePrice: 0,
      serviceWorth: 0,
      validity: "",
      plans: [
        {
          planId: 1,
          plan: "PRIVILEGE - ₹100K",
          price: 100000,
          serviceWorth: "160000",
          validity: "20 month",
        },
        {
          planId: 2,
          plan: "PLATINUM - ₹50K",
          price: 50000,
          serviceWorth: "75000",
          validity: "18 month",
        },
        {
          planId: 3,
          plan: "DIAMOND - ₹35K",
          price: 35000,
          serviceWorth: "50000",
          validity: "12 month",
        },
        {
          planId: 4,
          plan: "GOLD - ₹25K",
          price: 25000,
          serviceWorth: "35000",
          validity: "9 month",
        },
        {
          planId: 5,
          plan: "SILVER - ₹10K",
          price: 10000,
          serviceWorth: "13000",
          validity: "6 month",
        },
        {
          planId: 6,
          plan: "CRYSTAL - ₹5K",
          price: 5000,
          serviceWorth: "6000",
          validity: "3 month",
        },
      ],
    };
    this.itemRender = (li, itemProps) => {
      let itemChildren;
      // console.log('sdkvb<d',itemProps.dataItem['disabled'])
      // console.log('sdkvb<d',itemProps)
      if (itemProps.dataItem.disabled) {
        itemChildren = (
          <span style={{ color: "#ccc", cursor: "auto" }}>
            {li.props.children}
          </span>
        );
      } else {
        itemChildren = <span>{li.props.children}</span>;
      }

      return React.cloneElement(li, li.props, itemChildren);
    };
    this.toggleClick = this.toggleClick.bind(this); // Toggle Gift/self Mode
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this); // For update state when change the input
    this.handleDropDownChange = this.handleDropDownChange.bind(this); // For update state when change the dropdown Values
    this.onFormSubmit = this.onFormSubmit.bind(this); // Form Validation default
    this.defaultValidationError = this.defaultValidationError.bind(this); // Check dropdown value selected or not
    this.cleanInputErrors = this.cleanInputErrors.bind(this);
  }

  // Onsubmit form
  onFormSubmit(e) {
    e.preventDefault();
    var { name, email, recipientName, recipientEmail } = this.state;
    const formErrors = this.state.formErrors;

    this.setState({ disablebtn: "outer-btn disablebtn" });
    if (this.defaultValidationError()) {
      if (!/^([a-zA-Z ])+$/.test(name)) {
        formErrors.name = "error";
        this.setState({
          errMsg: "*Please enter valid name",
          disablebtn: "outer-btn",
        });
        return;
      }
      // if (!(/^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/.test(mobileNo))) {
      //   formErrors.mobileNo = 'error'
      //   this.setState({ errMsg: '*Please enter valid mobile number', disablebtn: 'outer-btn' })
      //   return
      // }
      if (!validateEmail(email)) {
        formErrors.email = "error";
        this.setState({
          errMsg: "*Please enter valid email address",
          disablebtn: "outer-btn",
        });
        return;
      }
      if (this.state.toggleIsChecked) {
        if (!/^[a-zA-Z ]+$/.test(recipientName)) {
          formErrors.recipientName = "error";
          this.setState({
            errMsg: "*Please enter valid recipient name",
            disablebtn: "outer-btn",
          });
          return;
        }
        // if (!(/^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/.test(recipientMobile))) {
        //   formErrors.recipientMobile = 'error'
        //   this.setState({ errMsg: '*Please enter valid receipient mobile number', disablebtn: 'outer-btn' })
        //   return
        // }
        if (!validateEmail(recipientEmail)) {
          formErrors.recipientEmail = "error";
          this.setState({
            errMsg: "*Please enter valid recipient email",
            disablebtn: "outer-btn",
          });
          return;
        }
        if (recipientEmail.toLowerCase() === email.toLowerCase()) {
          formErrors.recipientEmail = "error";
          this.setState({
            errMsg: "*Email and recipient email should not be same",
            disablebtn: "outer-btn",
          });
          return;
        }
      }
    } else {
      this.setState({ formErrors });
    }

    // Form Submition
    let valid = true;
    Object.values(this.state.formErrors).forEach((val) => {
      if (val.length > 0) {
        valid = false;
        this.setState({ disablebtn: "outer-btn" });
      }
    });

    if (valid) {
      const { name, mobileNo, email, city, location, plan } =
        this.state.formfields;
      const { recipientName, recipientMobile, recipientEmail } =
        this.state.recipientDetails;

      this.setState({ disablebtn: "outer-btn disablebtn" });

      const tempDate = new Date();
      const getDate =
        tempDate.getDate() < 10 ? "0" + tempDate.getDate() : tempDate.getDate();
      const getMonth =
        tempDate.getMonth() < 9
          ? "0" + (tempDate.getMonth() + 1)
          : tempDate.getMonth() + 1;
      const membershipDate =
        getDate + "-" + getMonth + "-" + tempDate.getFullYear();
      const orderId = tempDate.getTime().toString();
      const templateData = {
        templateData: {
          to: recipientEmail,
          subject:
            "AromaThai Day Spa Membership Card and Order Id - " + orderId,
          descriptionUser: this.state.toggleIsChecked
            ? "Thank you for purchasing a gift membership card for your loved one. An Email with the details has been sent to them. <br/><br /> Don’t mind giving them a ring!"
            : "Thank you for purchasing a membership card.",
          descriptionRecipient:
            "Congratulations! You have received a gift membership card from " +
            name +
            " who truly cares about you. Enjoy your gifted card and remember, a healthy body holds a healthy mind.",
        },
      };

      let memberShipDetails;

      // to form an object based on client's email order format.
      if (this.state.toggleIsChecked) {
        memberShipDetails = {
          "Order Id": orderId,
          Date: membershipDate,
          "Membership Type": this.state.toggleIsChecked ? "Gift" : "Self",
          "Recipient Name": recipientName,
          "Recipient Mobile": recipientMobile,
          "Recipient Email Id": recipientEmail,
          Name: name,
          Mobile: mobileNo,
          "Email Id": email,
          City: city,
          Location: location,
          BranchEmail: this.state.branchEmail,
          // 'BranchEmail': 'radhakrishnan.y@tringapps.com',
          Plan: plan,
          "Value Paid": "₹" + this.state.servicePrice,
          "Services Worth": "₹" + this.state.serviceWorth,
          Validity: this.state.validity,
        };
      } else {
        memberShipDetails = {
          "Order Id": orderId,
          Date: membershipDate,
          "Membership Type": this.state.toggleIsChecked ? "Gift" : "Self",
          Name: name,
          Mobile: mobileNo,
          "Email Id": email,
          City: city,
          Location: location,
          BranchEmail: this.state.branchEmail,
          // 'BranchEmail': 'radhakrishnan.y@tringapps.com',
          Plan: plan,
          "Value Paid": "₹" + this.state.servicePrice,
          "Services Worth": "₹" + this.state.serviceWorth,
          Validity: this.state.validity,
        };
      }
      const templateObject = { ...memberShipDetails, ...templateData };
      // console.log(templateObject)

      window.localStorage.setItem(
        "orderDetail",
        JSON.stringify(templateObject)
      );
      window.localStorage.setItem("orderDetailType", "MEMBERSHIP");
      if (this.state.toggleIsChecked) {
        db.collection("payment")
          .doc(orderId)
          .set({
            order_id: orderId,
            date: membershipDate,
            membership_type: this.state.toggleIsChecked ? "Gift" : "Self",
            recipient_name: recipientName,
            recipient_mobile: recipientMobile,
            recipient_email_id: recipientEmail,
            name: name,
            mobile: mobileNo,
            email_id: email,
            city: city,
            location: location,
            branch_email: this.state.branchEmail,
            plan: plan,
            value_paid: "₹" + this.state.servicePrice,
            services_worth: "₹" + this.state.serviceWorth,
            validity: this.state.validity,
            mailType: "MEMBERSHIP",
            objMailData: templateObject,
          })
          .then((docRef) => {
            console.log("Document written");
            const orderAmount = parseFloat(this.state.servicePrice);
            const orderKey =
              "merchant_id=" +
              ccavMerchantId +
              "&order_id=" +
              orderId +
              "&currency=INR&amount=" +
              orderAmount +
              "&redirect_url=" +
              ccavRedirectURL +
              "&cancel_url=" +
              ccavCancelURL +
              "&language=EN";
            this.props.history.push("/ccavenue", { orderKey: orderKey });
          })
          .catch((error) => {
            console.log("Error", error);
          });
      } else {
        db.collection("payment")
          .doc(orderId)
          .set({
            order_id: orderId,
            date: membershipDate,
            membership_type: this.state.toggleIsChecked ? "Gift" : "Self",
            name: name,
            mobile: mobileNo,
            email_id: email,
            city: city,
            location: location,
            branch_email: this.state.branchEmail,
            plan: plan,
            value_paid: "₹" + this.state.servicePrice,
            services_worth: "₹" + this.state.serviceWorth,
            validity: this.state.validity,
            mailType: "MEMBERSHIP",
            objMailData: templateObject,
          })
          .then((docRef) => {
            console.log("Document written");
            const orderAmount = parseFloat(this.state.servicePrice);
            const orderKey =
              "merchant_id=" +
              ccavMerchantId +
              "&order_id=" +
              orderId +
              "&currency=INR&amount=" +
              orderAmount +
              "&redirect_url=" +
              ccavRedirectURL +
              "&cancel_url=" +
              ccavCancelURL +
              "&language=EN";
            this.props.history.push("/ccavenue", { orderKey: orderKey });
          })
          .catch((error) => {
            console.log("Error", error);
          });
      }
      // to make querystring to get ccavenue payment page.
      // const orderAmount = parseFloat(this.state.servicePrice)
      // const orderKey = 'merchant_id=' + ccavMerchantId + '&order_id=' + orderId + '&currency=INR&amount=' + orderAmount + '&redirect_url=' + ccavRedirectURL + '&cancel_url=' + ccavCancelURL + '&language=EN'
      // this.props.history.push('/ccavenue', { orderKey: orderKey })
    }
  }

  defaultValidationError() {
    const ErrorObj = this.state.formErrors;
    let valid = true;
    Object.keys(this.state.formfields).forEach((key) => {
      const value = this.state.formfields[key];
      if (value.length <= 0) {
        ErrorObj[key] = "error";
        valid = false;
        this.setState({ errMsg: "*Please enter mandatory fields" });
      }
    });
    // When toggled gift section
    if (this.state.toggleIsChecked) {
      Object.keys(this.state.recipientDetails).forEach((key) => {
        const recipientValue = this.state.recipientDetails[key];
        if (recipientValue.length <= 0) {
          ErrorObj[key] = "error";
          valid = false;
          this.setState({ errMsg: "*Please enter mandatory fields" });
        }
      });
    }
    this.setState({ ErrorObj });
    return valid;
  }

  // Clean the input errors
  cleanInputErrors() {
    const ErrorObj = this.state.formErrors;
    const FieldObj = this.state.formfields;
    Object.keys(this.state.formfields).forEach((key) => {
      const value = this.state.formfields[key];
      if (value.length <= 0) {
        ErrorObj[key] = "";
      }
    });
    Object.keys(this.state.recipientDetails).forEach((key) => {
      const recipientValue = this.state.recipientDetails[key];
      if (recipientValue.length <= 0) {
        ErrorObj[key] = "";
      }
    });
    this.setState({ mobileNo: "", recipientMobile: "" });
    // Object.keys(this.state.recipientDetails).forEach(key => {
    //   let recipientValue = this.state.recipientDetails[key]
    //   if (recipientValue.length <= 0) {
    //     ErrorObj[key] = ''
    //   }
    // })
    // Clear the form fields when toggle
    Object.keys(this.state.formfields).forEach((key) => {
      if (key !== "plan" && key !== "city") {
        FieldObj[key] = "";
      }
    });
    this.setState({ ErrorObj });
    this.setState({ formfields: FieldObj });
  }

  // Toggle function
  toggleClick(event) {
    this.setState({ toggleIsChecked: !this.state.toggleIsChecked });
    this.cleanInputErrors();
    this.setState({ errMsg: "" });
  }

  // Input change event
  handleOnChangeInput(e) {
    const { name, value } = e.target;
    const formErrors = this.state.formErrors;
    switch (name) {
      case "name":
        formErrors.name = "";
        this.setState({ name: value });
        break;
      case "mobileNo":
        formErrors.mobileNo = "";
        if (!isNaN(value)) {
          this.setState({ mobileNo: value });
        }
        break;
      case "email":
        this.setState({ email: value });
        formErrors.email = "";
        break;
      case "recipientName":
        this.setState({ recipientName: value });
        formErrors.recipientName = "";
        break;
      case "recipientMobile":
        if (!isNaN(value)) {
          this.setState({ recipientMobile: value });
        }
        formErrors.recipientMobile = "";
        break;
      case "recipientEmail":
        this.setState({ recipientEmail: value });
        formErrors.recipientEmail = "";
        break;

      default:
        break;
    }
    this.setState({ errMsg: " " });
    this.setState({ formErrors });

    const formfieldsState = e.target.classList.contains("recipient_details")
      ? this.state.recipientDetails
      : this.state.formfields;
    formfieldsState[e.target.name] = e.target.value;
    this.setState({ formfieldsState });
  }

  // Drop down event
  handleDropDownChange(event, reference) {
    const formfields = this.state.formfields;
    const formError = this.state.formErrors;
    formError[event.target.name] = "";
    this.setState({ formErrors: formError });

    if (reference === "city") {
      const data = this.state.cityDetails.filter(
        (values) => values.cityId === event.target.value.cityId
      );
      formfields.city = event.target.value.city;
      formfields.location = "";
      this.setState({
        locationDetails: data[0].locationList,
        formfields: formfields,
      });
    }
    if (reference === "location") {
      // const data = this.state.locationDetails.filter((values) => values.location === event.target.value.location)
      const formfields = this.state.formfields;
      const data = this.state.locationDetails.filter(
        (values) => values.locationId === event.target.value.locationId
      );

      /*if (data[0].locationId === 13 || data[0].locationId === 14) {
        formfields.location = ''
        formfields.locationId = ''
      } else {
        formfields.location = data[0].location
        formfields.locationId = data[0].locationId
      }*/

      formfields.location = data[0].location;
      formfields.locationId = data[0].locationId;

      this.setState({ formfields: formfields, branchEmail: data[0].email });
      this.setState({ errMsg: "" });
    }
    if (reference === "plan") {
      // const data = this.state.locationDetails.filter((values) => values.location === event.target.value.location)
      const formfields = this.state.formfields;
      // const data = this.state.locationDetails.filter((values) =>
      // values.locationId === event.target.value.locationId)
      formfields.plan = event.target.value.plan;
      // formFields.locationId = data[0].locationId
      this.setState({
        formfields: formfields,
        servicePrice: event.target.value.price,
        serviceWorth: event.target.value.serviceWorth,
        validity: event.target.value.validity,
      });
      this.setState({ errMsg: "" });
    }
  }

  // construct the object for drop down with this name
  handleDropdownState(event) {
    const formfields = this.state.formfields;
    formfields[event.target.name] = event.target.value[event.target.name];
    return formfields[event.target.name];
  }

  componentDidMount() {
    const bookingCity = this.state.formfields;
    const priceDet = this.state.planDetails;

    if (this.state.defaultCity !== "") {
      const data = this.state.cityDetails.filter(
        (values) => values.city.toLowerCase() === this.state.defaultCity
      );
      this.setState({
        defaultCityId: data[0].cityId,
        locationDetails: data[0].locationList,
      });
      bookingCity.city = data[0].city;
    }

    if (priceDet) {
      bookingCity.plan =
        priceDet.planName.toUpperCase() + " - ₹" + priceDet.planPrice;
      this.setState({
        planPrice:
          priceDet.planName.toUpperCase() + " - ₹" + priceDet.planPrice,
        planId: priceDet.planId,
      });
      this.setState({
        servicePrice: priceDet.valuePaid,
        serviceWorth: priceDet.planWorth,
        validity: priceDet.planValidity,
      });
    }
    if (this.props.memToggle === "gift") {
      this.setState({ toggleIsChecked: !this.state.toggleIsChecked });
    }

    this.setState({ formFields: bookingCity });
  }

  render() {
    return (
      <div id="membership-container">
        <div className="wrapper">
          <OverlayHead overlaycontent={this.state.overlayHead} />
          <ToggleButton
            checkedStatus={this.state.toggleIsChecked}
            toggleValue="Self,Gift"
            toggleClick={this.toggleClick}
          />
          {!this.state.toggleIsChecked ? (
            <SelfMembership
              itemRender={this.itemRender}
              handleDropDownChange={this.handleDropDownChange}
              handleInputChange={this.handleOnChangeInput}
              isNumber={this.state.mobileNo}
              currentState={this.state}
              planPrice={this.props.planDetails}
            />
          ) : (
            <GiftMembership
              itemRender={this.itemRender}
              handleDropDownChange={this.handleDropDownChange}
              isNumber={this.state.mobileNo}
              isRecepNumber={this.state.recipientMobile}
              currentState={this.state}
              handleInputChange={this.handleOnChangeInput}
              planPrice={this.props.planDetails}
            />
          )}
          <div className="page-note-container">
            Note: Whether you are planning to purchase the membership for
            yourself or as a gift, please present an online generated invoice at
            the location you selected above to collect the membership card.
          </div>
          <div className="cls-err-msg">{this.state.errMsg}</div>
          <div className={this.state.mailStatus}>
            {this.state.mailMessage}&nbsp;
          </div>
          <div className="form-submit-section">
            <div className={this.state.disablebtn}>
              <div onClick={this.onFormSubmit} className="btn register-booking">
                BUY NOW
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(Membership);
