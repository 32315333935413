import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import './ReviewCarousel.css'
import './ReviewCarouselResponsive.css'

// export default ReviewCarousel
class ReviewCarousel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      intervalId: '',
      componentName: '',
      slideIndex: 1,
      circleOne: true,
      circleTwo: false,
      circleThree: false,
      circleFour: false,
      btnClicked: false,
      bannerArr: [
        { imagePath: 'assets/images/home_banner.jpg', imageTitle: 'SPA REVIEWS 1', imageDesc: 'I love AromaThai spa. I have tried a few local brands in the area but nothing beats the experience at AromaThai. The place is done up aesthetically and they hire experienced and professional massage therapists.', btnText: 'book now', btnNav: 'Booking', authTitle: 'Kamal Jain', authRole: '' },
        { imagePath: 'assets/images/gift_banner.jpg', imageTitle: 'SPA REVIEWS 2', imageDesc: 'I am a regular at AromaThai and I signed up after I had a sports accident and underwent surgery. The massage has helped me in the rehab as the sore muscles are dealt with very well and all the stress and the knots in the muscles were taken out.', authTitle: 'Manish verma', authRole: 'Trip advisor', btnText: 'buy now', btnNav: 'Membership' },
        { imagePath: 'assets/images/membership_banner.jpg', imageTitle: 'SPA REVIEWS 3', imageDesc: 'I am regular at AromaThai. After coming here for years, I finally went in for a silver membership card last yr (which expired pretty soon) & then I had to get a gold membership card last month. I must say these membership cards are great value for money!', authTitle: 'Rhea N', authRole: 'Trip advisor', btnText: 'buy now', btnNav: 'Membership' },
        { imagePath: 'assets/images/membership_banner.jpg', imageTitle: 'SPA REVIEWS 4', imageDesc: 'I have tried the Thai yoga massage (clothed), Thai signature foot massage and the Day After massage for my migraines: My Verdict: Excellent for all the three really relaxing, experienced therapists, great ambiance and they made me feel very comfortable and respected.', authTitle: 'Mamta bajaj', authRole: '', btnText: 'book now', btnNav: 'Booking' }
      ],
      currBannerArr: [
        { imagePath: 'assets/images/home_banner.jpg', imageTitle: 'SPA REVIEWS 1', imageDesc: 'I love AromaThai spa. I have tried a few local brands in the area but nothing beats the experience at AromaThai.The place is done up aesthetically and they hire experienced and professional massage therapists.', btnText: 'book now', btnNav: 'Booking', authTitle: 'Kamal Jain', authRole: '' }
      ],
      isSlideChange: false
    }
    this.showSlides = this.showSlides.bind(this)
    this.currentSlide = this.currentSlide.bind(this)
    this.handleOverlay = this.handleOverlay.bind(this)
    // this.hideOverlay2 = this.hideOverlay2.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.clearInterval = this.clearInterval.bind(this)
    this.startInterval = this.startInterval.bind(this)
  }

  clearInterval () { clearInterval(this.state.intervalId) }

  startInterval () { this.setState({ intervalId: this.state.intervalId = setInterval(this.showSlides, 13000) }) }

  handleCarouselCirlce (obj) {
    this.clearInterval()
    this.currentSlide(obj)
    this.startInterval()
  }

  showSlides () {
    let { slideIndex } = this.state
    if (slideIndex === 1) this.currentSlide(2)
    else if (slideIndex === 2) this.currentSlide(3)
    else if (slideIndex === 3) this.currentSlide(4)
    else if (slideIndex === 4) this.currentSlide(1)
  }

  currentSlide (obj) {
    var currBannerArray
    this.setState({ isSlideChange: false })
    currBannerArray = this.state.bannerArr.slice(obj - 1, obj)
    this.setState({ currBannerArr: currBannerArray })
    this.setState({ isSlideChange: true })
    // to empty all circles
    this.setState({ circleOne: false, circleTwo: false, circleThree: false, circleFour: false })
    if (obj === 1) this.setState({ slideIndex: obj, circleOne: true })
    else if (obj === 2) this.setState({ slideIndex: obj, circleTwo: true })
    else if (obj === 3) this.setState({ slideIndex: obj, circleThree: true })
    else if (obj === 4) this.setState({ slideIndex: obj, circleFour: true })
  }

  handleOverlay (comp) { this.setState({ componentName: comp }) }

  componentDidMount () {
    this.setState({ isSlideChange: true })
    this.startInterval()
    // console.log('prop from horm', this.props.abc)
  }

  handleMouseEnter () { this.clearInterval() }

  handleMouseLeave () {
    if (!this.state.btnClicked) {
      this.startInterval()
    }
  }

  componentWillReceiveProps (newProps) {
    // console.log('Component WILL RECIEVE PROPS!')
    if (newProps.enableCarousel === 'true') {
      this.startInterval()
      this.setState({ btnClicked: false })
    }
  }

  handleBtnclick (overlaNmae) {
    this.handleMouseEnter()
    this.setState({ btnClicked: true })
    this.props.handleOverlay(overlaNmae)
  }

  render () {
    const { currBannerArr, isSlideChange, circleOne, circleTwo, circleThree, circleFour } = this.state

    return (
      <div id='review-carousel'>
        <div className='carousel-block'>
          {/* Banner carousel block */}
          <div className='carousel-flex'>
            <div className='carousel-img'>
              <div className='ic-spa-review' />
              <div className='ic-divider' />
              <Fade right distance='30px'>
                <div className='our-valued-customers'>Our valued customers say</div>
              </Fade>
              {currBannerArr.map((list, index) => {
                return (
                  <div className='carousel-box fade' key={index}>
                    <Fade when={isSlideChange} right distance='30px'>
                      <div className='desc-block'>
                        {/* <div className='ic-title'>{list.imageTitle}</div> */}
                        <div className='desc-content'>{list.imageDesc}</div>
                        <div className='auth-title'>{list.authTitle}</div>
                        <div className='auth-role'>{list.authRole}</div>
                      </div>
                    </Fade>
                  </div>
                )
              })}
            </div>
            <div className='circle-row'>
              <div className='circle-outer'><div className={circleOne ? 'circle active' : 'circle'} data-testid='circle1' onClick={() => this.handleCarouselCirlce(1)} /></div>
              <div className='circle-outer'><div className={circleTwo ? 'circle active' : 'circle'} data-testid='circle2' onClick={() => this.handleCarouselCirlce(2)} /></div>
              <div className='circle-outer'><div className={circleThree ? 'circle active' : 'circle'} data-testid='circle3' onClick={() => this.handleCarouselCirlce(3)} /></div>
              <div className='circle-outer'><div className={circleFour ? 'circle active' : 'circle'} data-testid='circle4' onClick={() => this.handleCarouselCirlce(4)} /></div>
            </div>
          </div>
          {/* Banner circles block */}
        </div>
      </div>
    )
  }
}

export default ReviewCarousel
