import React, { Component } from 'react'
import { Link } from 'react-router-dom'
 import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { fetchFeed } from '../../API/ApiRequest.js'
import { article } from '../../global.js'
import { FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon } from 'react-share'
import renderHTML from 'react-render-html'
import OverlayLayer from '../OverlayComponents/Overlay/Overlay'
import './ArticleDetails.css'
// const
const title = 'CoreFitPlusBlog'

// component
class ArticleDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      articleList: article(),
      title: '',
      content: '',
      shareUrl: ''
    }
    this.handleOverlay = this.handleOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    
  }
  

  handleOverlay (comp) {
    this.setState({ componentName: comp })
  }

  hideOverlay () {
    this.setState({ componentName: '' })
  }

  componentDidMount () {
    // console.log('props', this.props.match.params)
    // let feedId = this.props.match.params.feedid
    // let postId = this.props.match.params.postid
    // this.setState({ shareUrl: 'https://corefitplus.com/blog/' + feedId + '/' + postId })
    // fetchFeed('feeds/' + feedId + '/posts/default/' + postId + '?alt=json', 'ART').then(response => {
    //   this.setState({ title: response.entry.title.$t, content: response.entry.content.$t })
    // })
    let articleId = this.props.match.params.articleid
    this.setState({ shareUrl: 'https://corefitplus.com/blog/article/' + articleId })
    var article = this.state.articleList.find(art => art.id === articleId);
    this.setState({
      id: article.id,
      title: article.title,
      img: article.img,
      publishedOn: article.publishedOn,
      publishedBy: article.publishedBy,
      content: article.content
    })
  }

  render () {
    const componentOpen = this.state.componentName === 'FreeTrial'
      ? <OverlayLayer typeOfComponent='FreeTrial' hideOverlay={this.hideOverlay} size='lg' />
      : ''
    return (
      <div id='articledetails'>
         <Header handleOverlay={this.handleOverlay} />

        <div className='article-block'>
          <h2 className='blog-title'>{this.state.title} </h2>
          <img src={this.state.img} alt='' />
          <div className='article-date'>Published <b>{this.state.publishedOn}</b></div>
          <div className='article-author'>By <b>{this.state.publishedBy}</b></div>
          <div className='blog-desc'>{renderHTML(this.state.content)}</div>
        </div>
        <div className='divider' />
        <div className='social-links'>
          <FacebookShareButton url={this.state.shareUrl} quote={title} className='button'>
            <FacebookIcon size={30} round />
          </FacebookShareButton>
          <TwitterShareButton url={this.state.shareUrl} quote={title} className='button'>
            <TwitterIcon size={30} round />
          </TwitterShareButton>
          <LinkedinShareButton url={this.state.shareUrl} quote={title} className='button'>
            <LinkedinIcon size={30} round />
          </LinkedinShareButton>
        </div>
        <Footer />
      </div>
    )
  }
}

export default ArticleDetails
