import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
// component
import BannerBtn from '../BannerButton/BannerButton'
// css
import './BannerImage.css'
import './BannerImageResponsive.css'

class BannerImage extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: window.location.pathname,
      planList: [{
        planName: 'privilege',
        planPrice: '100k',
        menuList: ['Relaxation Massages', 'Spa Voucher', 'Free Yoga Classes', 'Services Worth 160K', 'Validity 20 month*']
      }, {
        planName: 'Platinum',
        planPrice: '50k',
        menuList: ['Relaxation Massages', 'Spa Voucher', 'Free Yoga Classes', 'Services Worth 75K', 'Validity 18 month*']
      }, {
        planName: 'Diamond',
        planPrice: '35k',
        menuList: ['Relaxation Massages', 'Spa Voucher', 'Free Yoga Classes', 'Services Worth 50K', 'Validity 12 month*']
      }, {
        planName: 'gold',
        planPrice: '25k',
        menuList: ['Relaxation Massages', 'Spa Voucher', 'Free Yoga Classes', 'Services Worth 35K', 'Validity 9 month*']
      }, {
        planName: 'silver',
        planPrice: '10k',
        menuList: ['Relaxation Massages', 'Spa Voucher', 'Free Yoga Classes', 'Services Worth 13K', 'Validity 6 month*']
      }, {
        planName: 'crystal',
        planPrice: '5k',
        menuList: ['Relaxation Massages', 'Spa Voucher', 'Free Yoga Classes', 'Services Worth 6K', 'Validity 3 month*']
      }],
      bannerItem: {
        imageUrl: '/assets/images/abtus-banner.jpg',
        title: 'about us',
        description: 'The need to relieve oneself from daily stress and strain is fast becoming a norm in society now.  It is the burden of the endless pace of the day that brings fatigue with its deadlines, chaos and pressures. We spotted this need based ooportunity for relaxation way back in 2008 as we started the brand Aromathai.  A spa that promised an experience that would leave you rejuvenated and relaxed with ancient Thai techniques, trained staff and inviting premises that cocooned you from the world outside.'
      }
    }
  }

  externalLinks (link) {
    var win = window.open(link, '_blank')
    win.focus() // opens the link in new tab
  }

  componentDidMount () {
    // console.log('this.state.currentPage ', this.state.currentPage)
  }
  render () {
    return (
      <div id='banner-blk' data-testid='bannerimagecomponent' style={{ backgroundImage: `url(` + this.props.banner.imageUrl + `)` }}>

        <div className='banner-content'>
          <Fade bottom distance='30px'>
            <div className='content-title'>{this.props.banner.title}</div>
          </Fade>
          <Fade bottom distance='30px' delay={200}>
            {/* {this.state.currentPage === '/aboutus' ? <div className='content-desc'>AromaThai Opened its Doors in 2008.</div> : ''} */}
            <div className='content-desc'>{this.props.banner.description}</div>
          </Fade>
          <Fade bottom distance='30px' delay={400}>
            {this.props.social
              ? (
                <div className='social-media'>
                  <div className='media-logo ic-insta' onClick={() => this.externalLinks('https://www.instagram.com/aromathaispa/')} />
                  <div className='media-logo ic-facebook' onClick={() => this.externalLinks('https://www.facebook.com/AromathaiDaySpa/')} />
                </div>
              ) : ''
            }
          </Fade>
          <Fade bottom distance='30px' delay={500}>
            { this.props.bannerBtn ? <BannerBtn btnClick={() => this.props.handleOverlay(this.props.banner.overlayComp)} btnText={this.props.btnText} /> : ''}
          </Fade>
        </div>

      </div>
    )
  }
}

export default BannerImage
