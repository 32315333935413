import React, { Component } from "react";
// import Zoom from 'react-reveal/Zoom'
// css
import "./CitySelection.css";
class CitySelection extends Component {
  render() {
    return (
      <div id="cities">
        <div className="our-loc-flex-row-1">
          {/* <Zoom> */}
          <div
            className="our-loc-flex-box"
            data-testid="locationmumbai"
            onClick={() => this.props.handleOverlay("OurLocation", "Mumbai")}
          >
            <div className="ic-loc-1 loc-expand-line" />
            <div className="ic-loc-title">Mumbai</div>
          </div>
          {/* </Zoom> */}
          {/* <Zoom> */}
          <div
            className="our-loc-flex-box"
            data-testid="locationdelhincr"
            onClick={() => this.props.handleOverlay("OurLocation", "Delhi-Ncr")}
          >
            <div className="ic-loc-2 loc-expand-line" />
            <div className="ic-loc-title">Delhi-Ncr</div>
          </div>
          {/* </Zoom> */}
          {/* <Zoom> */}
          <div
            className="our-loc-flex-box"
            data-testid="locationdehradun"
            onClick={() => this.props.handleOverlay("OurLocation", "Dehradun")}
          >
            <div className="ic-loc-5 loc-expand-line" />
            <div className="ic-loc-title">Dehradun</div>
          </div>
          {/* </Zoom> */}
          {/* <Zoom> */}
          <div
            className="our-loc-flex-box"
            data-testid="locationkolkata"
            onClick={() => this.props.handleOverlay("OurLocation", "Kolkata")}
          >
            <div className="ic-loc-3 loc-expand-line" />
            <div className="ic-loc-title">Kolkata</div>
          </div>
          {/* </Zoom> */}
        </div>
        <div className="our-loc-flex-row-2">
          {/* <Zoom> */}
          <div
            className="our-loc-flex-box"
            data-testid="locationpune"
            onClick={() => this.props.handleOverlay("OurLocation", "Pune")}
          >
            <div className="ic-loc-4 loc-expand-line" />
            <div className="ic-loc-title">Pune</div>
          </div>
          {/* </Zoom> */}
          {/* <Zoom> */}
          {/* <div className='our-loc-flex-box' data-testid="locationkochi" onClick={() => this.props.handleOverlay('OurLocation', 'Kochi')}>
            <div className='ic-loc-6 loc-expand-line' />
            <div className='ic-loc-title'>Kochi</div>
          </div> */}
          {/* </Zoom> */}
        </div>
      </div>
    );
  }
}

export default CitySelection;
