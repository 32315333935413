import crypto from "crypto-browserify";
const MERCHANTID = process.env.REACT_APP_MERCHANTID;
const CC_ACCESSCODE = process.env.REACT_APP_CC_ACCESSCODE;
const CC_WORKINGKEY = process.env.REACT_APP_CC_WORKINGKEY;
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const ccavMerchantId = MERCHANTID;
export const ccavAccessCode = CC_ACCESSCODE;
export const ccavWorkingKey = CC_WORKINGKEY;
export const ccavRedirectURL =
  window.location.origin + "%2FccavResponseHandler";
export const ccavCancelURL = window.location.origin + "%2FccavErrorHandler";
export const baseURL = BACKEND_URL;

// to remove white space on input field
export function removeSpaces(string) {
  if (string === undefined) return string;
  else return string.split(" ").join("");
}

export const firebase = require("firebase");
// Required for side-effects
require("firebase/firestore");
firebase.initializeApp({
  apiKey: "AIzaSyAmFW1Nbw37bm5UxaSsteINtOvDp1CQ1_I",
  authDomain: "aromathai-frontend.firebaseapp.com",
  databaseURL: "https://aromathai-frontend.firebaseio.com",
  projectId: "aromathai-frontend",
  storageBucket: "aromathai-frontend.appspot.com",
  messagingSenderId: "848543896581",
  appId: "1:848543896581:web:d451ca02b37537f17f786c",
  measurementId: "G-FL89MB4Z8T",
});

// email validation happening here
// window.onbeforeunload = function () {
//   window.localStorage.clear()
// }
// State list of india.
export function stateDetails() {
  const stateDetails = [
    { code: "AN", name: "Andaman and Nicobar Islands" },
    { code: "AP", name: "Andhra Pradesh" },
    { code: "AR", name: "Arunachal Pradesh" },
    { code: "AS", name: "Assam" },
    { code: "BR", name: "Bihar" },
    { code: "CG", name: "Chandigarh" },
    { code: "CH", name: "Chhattisgarh" },
    { code: "DH", name: "Dadra and Nagar Haveli" },
    { code: "DD", name: "Daman and Diu" },
    { code: "DL", name: "Delhi" },
    { code: "GA", name: "Goa" },
    { code: "GJ", name: "Gujarat" },
    { code: "HR", name: "Haryana" },
    { code: "HP", name: "Himachal Pradesh" },
    { code: "JK", name: "Jammu and Kashmir" },
    { code: "JH", name: "Jharkhand" },
    { code: "KA", name: "Karnataka" },
    { code: "KL", name: "Kerala" },
    { code: "LD", name: "Lakshadweep" },
    { code: "MP", name: "Madhya Pradesh" },
    { code: "MH", name: "Maharashtra" },
    { code: "MN", name: "Manipur" },
    { code: "ML", name: "Meghalaya" },
    { code: "MZ", name: "Mizoram" },
    { code: "NL", name: "Nagaland" },
    { code: "OR", name: "Odisha" },
    { code: "PY", name: "Puducherry" },
    { code: "PB", name: "Punjab" },
    { code: "RJ", name: "Rajasthan" },
    { code: "SK", name: "Sikkim" },
    { code: "TN", name: "Tamil Nadu" },
    { code: "TS", name: "Telangana" },
    { code: "TR", name: "Tripura" },
    { code: "UK", name: "Uttarakhand" },
    { code: "UP", name: "Uttar Pradesh" },
    { code: "WB", name: "West Bengal" },
  ];
  return stateDetails;
}
// commenting the logic to avoid overlay
export function getDefaultCity() {
  return "Mumbai";
  // var name = 'cityName='
  // var ca = document.cookie.split(';')
  // for (var i = 0; i < ca.length; i++) {
  //   var c = ca[i]
  //   while (c.charAt(0) === ' ') {
  //     c = c.substring(1)
  //   }
  //   if (c.indexOf(name) === 0) {
  //     return c.substring(name.length, c.length)
  //   }
  // }
  // return null
}
export function validateEmail(obj) {
  // Use commented regex if below uncommented not working.
  // if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(obj)) return true
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(obj)) return true;
  else return false;
}
// export function phoneKeypress (event) {
//   var charCode = event.keyCode
//   // Non-numeric character range
//   if (charCode > 31 && (charCode < 48 || charCode > 57)) { return false }
// }

// Encrypt
export function ccavencrypt(plainText, workingKey) {
  var m = crypto.createHash("md5");
  m.update(workingKey);

  var key = m.digest("binary");
  var keyBuffer = new Buffer(key, "binary");
  var iv = "\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f";

  var cipher = crypto.createCipheriv("aes-128-cbc", keyBuffer, iv);
  var encoded = cipher.update(plainText, "utf8", "hex");
  encoded += cipher.final("hex");
  return encoded;
}

// decrypt
export function ccavdecrypt(encText, workingKey) {
  var m = crypto.createHash("md5");
  m.update(workingKey);
  var key = m.digest("binary");
  var keyBuffer = new Buffer(key, "binary");
  var iv = "\x00\x01\x02\x03\x04\x05\x06\x07\x08\x09\x0a\x0b\x0c\x0d\x0e\x0f";
  var decipher = crypto.createDecipheriv("aes-128-cbc", keyBuffer, iv);
  var decoded = decipher.update(encText, "hex", "utf8");
  decoded += decipher.final("utf8");
  return decoded;
}

// AromaThai running cities and its branches details.
export function cityDetails() {
  const locationDetails = [
    {
      cityId: 1,
      city: "Mumbai",
      locationList: [
        {
          locationId: 1,
          location: "Inorbit Mall",
          address:
            "Unit No. F-57, 1st Floor, Inorbit Mall, Mindspace, Link Road, Malad [W], Mumbai-400064",
          email: "Inorbitmalad@aromathai.net",
          phoneNumber: "022-49724846/022- 68324804",
          mobileNumber: "9773207700",
        },
        /* {
          locationId: 2,
          location: "Breach Candy",
          address:
            "Shop No. 9, Tirupati Apts, Bhulabhai Desai Rd, Opp.Mahalaxmi Temple, Mahalaxmi, Mumbai-400026",
          email: "breachcandy@aromathai.net",
          phoneNumber: "022- 66106088/022- 23536601",
          mobileNumber: "9773202626",
        }, */
        /* {
          locationId: 3,
          location: 'Pali Hill',
          address: 'Shop No. 3, Chetak Co-op. Hsg. Soc.41, Pali Hill, Nargis Dutt Road, Bandra [West], Mumbai-400050',
          email: 'palihill@aromathai.net',
          phoneNumber: '2605 6871 / 260 6872',
          mobileNumber: '9773207744'
        },*/ {
          locationId: 4,
          location: "R City",
          address:
            "F-37, R-City Mall, 1st Floor, L.B.S.Marg, Ghatkoper [West], Mumbai-400086",
          email: "rcity@aromathai.net",
          phoneNumber: "66713018/19",
          mobileNumber: "9769670760",
        },
        {
          locationId: 5,
          location: "Palladium",
          address:
            "Unit No. T-19,20 3rd Floor, High Street Phoenix, S.B.Marg, Lower Parel, Mumbai-400013",
          email: "palladium@aromathai.net",
          phoneNumber: "66159365/66",
          mobileNumber: "9773208877",
        },
        {
          locationId: 6,
          location: "Thane",
          address: "AROMATHAI SPA PVT. LTD.Viviana Mall, GF-74, THANE [W]",
          email: "viviana@aromathai.net",
          phoneNumber: "022-61701545/48",
          mobileNumber: "8828434422",
        },
        {
          locationId: 7,
          location: "Kurla (W) Phoenix Marketcity Mall",
          address:
            "Phoenix Marketcity Mall, F-95, 1st Floor, Old Mukund Steel Company Kurla Kamani, Kurla-West, Mumbai-400070",
          email: "mckurla@aromathai.net",
          phoneNumber: "022-61561018/19",
          mobileNumber: "9619906905",
        },
      ],
      serviceList: [
        {
          serviceId: 1,
          serviceName: "Body Massages",
          serviceTypesList: [
            {
              serviceTypeId: 1,
              serviceTypeName: "Aromatherapy Massage",
              serviceTypeImg: "/assets/images/aromatherapy-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                " A massage that is the perfect way to relax a tired body as aromatic, essential oils and Thai massage techniques liberate energies in your body towards relaxation and rejuvenation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3758" },
                { serviceTime: "90 mins", servicePrice: "4808" },
              ],
            },
            // {
            //   serviceTypeId: 3,
            //   serviceTypeName: 'Aromatherapy Massage',
            //   serviceTypeImg: '/assets/images/aromatherapy-mass.jpg',
            //   serviceTherapist: 'Physiotherapist',
            //   serviceTypeDesc: 'A massage that is the perfect way to relax a tired body as aromatic, essential oils and Thai massage techniques liberate energies in your body towards relaxation and rejuvenation.',
            //   serviceHours: [
            //     { serviceTime: '60 mins', servicePrice: '3758' },
            //     { serviceTime: '90 mins', servicePrice: '4807' }
            //   ],
            //   isDisable: true
            // },
            {
              serviceTypeId: 3,
              serviceTypeName: "Deep tissue massage",
              serviceTypeImg: "/assets/images/deep-tissue.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that promises instant relief with its intensity and strong strokes to relieve underlying muscle tension and pain and provide deep relaxation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "4006" },
                { serviceTime: "90 mins", servicePrice: "4985" },
              ],
            },
            {
              serviceTypeId: 4,
              serviceTypeName: "Detox and tone Massage",
              serviceTherapist: "Physiotherapist",
              serviceTypeImg: "/assets/images/detox-tone.jpg",
              serviceTypeDesc:
                "A massage that promises you a tangibly toned body with its anti cellulite and detox oils that fight to remove cellular lumps and toxins from your body and increase blood and oxygen flow for physical wellness and cellular balance.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5752" }],
            },
            {
              serviceTypeId: 5,
              serviceTypeName: "Sportfit massage",
              serviceTypeImg: "/assets/images/sport-fit.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An invigorating massage for those who engage in strenuous activity. A combination of a Thai dry massage that involves stretching to soothe tired muscles and a special combination of oils that seep into muscles that are aggravated and overused. A strong, hard massage that will spring you back to life.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5493" }],
            },
            {
              serviceTypeId: 6,
              serviceTypeName: "Thai dry back massage",
              serviceTypeImg: "/assets/images/thai-dry-back.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This Thai massage specifically focuses on your upper and lower back. Therapists use intense, but well controlled pressure of their hands and arms to give you the ultimate relief from all your daily aches and sores.",
              serviceHours: [{ serviceTime: "40 mins", servicePrice: "2307" }],
            },
            {
              serviceTypeId: 7,
              serviceTypeName: "Thai Herbal Compress Option",
              serviceTypeImg: "/assets/images/thai-herbal-body.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This value added service can be combined with any one of the above three 60 minute body massages. Steamed herbal pouches are pressed against the body at various energy points to greatly enhance benefits of the massages and provide a feeling of weightlessness.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "900" }],
            },
            {
              serviceTypeId: 8,
              serviceTypeName: "Traditional Thai massage",
              serviceTypeImg: "/assets/images/trad-thai.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that's created with yoga techniques from Thailand wherein the therapist will assist you in a series of yoga stretches to improve circulation and flexibility as you enjoy relaxing joint pain relief, muscle movement and a positive flow of energy in this dry massage that can be done in your comfortable clothing.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2684" },
                { serviceTime: "90 mins", servicePrice: "3605" },
              ],
            },
          ],
        },
        {
          serviceId: 2,
          serviceName: "Body Scrubs",
          serviceTypesList: [
            {
              serviceTypeId: 9,
              serviceTypeName: "Lemon and walnut scrub",
              serviceTypeImg: "/assets/images/walnut-scrub.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense scrub that works well on dry skin types as it exfoliates and cleanses with its astringent properties of sea salt, lemon and walnut oil. It soothes and coaxes dry skin into a lustre with its oil content and stimulates new energy with our special massage. After this cleansing ritual, we highly recommend an enriching masque or oil treatment to pamper your skin a little further.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2550" }],
            },
            {
              serviceTypeId: 10,
              serviceTypeName: "Rice bran and oatmeal scrub",
              serviceTypeImg: "/assets/images/rice-bran.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that specializes in exfoliating dead cells and giving you youthful, brighter nourished skin with our specially made powder that has been freshly made and ground to leave you with soft,smooth skin that's been gently nourished.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2550" }],
            },
          ],
        },
        {
          serviceId: 3,
          serviceName: "Body Masques",
          serviceTypesList: [
            {
              serviceTypeId: 1,
              serviceTypeName: "Champagne body masque",
              serviceTypeImg: "/assets/images/champagne.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "The ultimate luxury product for your skin as the sinfully luscious combination of strawberries, peaches and grapes coupled with the finest champagne oil(sourced from Champagne, France) envelopes your skin with its powerful anti oxidant and exfoliating properties as it nourishes each pore with its natural extracts. Great for ageing or dehydrated skin, this masque helps give you smoother, softer and luminous skin with the perfect balance.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4350- Rs. 4650 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5600 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6950- Rs. 7550 / 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "2350" }],
            },
            // {
            //   serviceTypeId: 1,
            //   serviceTypeName: 'Champagne body masque',
            //   serviceTypeImg: '/assets/images/champagne.jpg',
            //   serviceTherapist: 'Physiotherapist',
            //   serviceTypeDesc: 'The ultimate luxury product for your skin as the sinfully luscious combination of strawberries, peaches and grapes coupled with the finest champagne oil(sourced from Champagne, France) envelopes your skin with its powerful anti oxidant and exfoliating properties as it nourishes each pore with its natural extracts. Great for ageing or dehydrated skin, this masque helps give you smoother, softer and luminous skin with the perfect balance.',
            //   serviceHours: [
            //     { serviceTime: '75 mins', servicePrice: '2250' }
            //   ]
            // },
            // {
            //   serviceTypeId: 2,
            //   serviceTypeName: 'GINGER, SEAWEED AND GREEN TEA MASQUE',
            //   serviceTypeImg: '/assets/images/ginger-tea.jpg',
            //   serviceTherapist: 'Physiotherapist',
            //   serviceTypeDesc: 'A masque that detoxifies your skin while providing lost moisture from sunlight and pollutants. Your skin will benefit from the ginger and seaweed combination as it works as a conditioner for your skin while the green tea will help your skin absorb rich nutrients and anti oxidant benefits with its own properties. A wonderfully refreshing treatment for your face with that is very beneficial and encourages softer, smoother skin.',
            //   serviceHours: [
            //     { serviceTime: '75 mins', servicePrice: '2250' }
            //   ]
            // },
            {
              serviceTypeId: 2,
              serviceTypeName: "GINGER, SEAWEED AND GREEN TEA MASQUE",
              serviceTypeImg: "/assets/images/ginger-tea.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A masque that detoxifies your skin while providing lost moisture from sunlight and pollutants. Your skin will benefit from the ginger and seaweed combination as it works as a conditioner for your skin while the green tea will help your skin absorb rich nutrients and anti oxidant benefits with its own properties. A wonderfully refreshing treatment for your face with that is very beneficial and encourages softer, smoother skin.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4350- Rs. 4650 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5600 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6950- Rs. 7550 / 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "2350" }],
            },
          ],
        },
        {
          serviceId: 4,
          serviceName: "Face Refreshers",
          serviceTypesList: [
            {
              serviceTypeId: 11,
              serviceTypeName: "Collagen Gold",
              serviceTypeImg: "/assets/images/collegen-gold.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With combination of pure collagen, Q1 snow lotus herb, organic aloe and 24K gold sheets, stimulates skin elasticity and glow.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2454" }],
            },
            {
              serviceTypeId: 12,
              serviceTypeName: "Whitening And Brightening ",
              serviceTypeImg: "/assets/images/whiteningAndBrightening.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense face refresher that brightens, hydrates and gives a glimmer to your skin especially if it is sun damaged or uneven. This treatment uses fruit extracts rich in vitamin E and vitamin C which instantly whitens and leaves the face glowing with health.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2500" }],
            },
            {
              serviceTypeId: 13,
              serviceTypeName: "Deep Cleansing ",
              serviceTypeImg: "/assets/images/deepCleansing.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Helps gives the skin an instant glow by detoxifying, purifying, and hydrating it. Its peel off mask is especially recommended for mixed and oily skin and helps in cleansing clogged pores and acne. For pure, supple and glowing skin this is the face fresher for you.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3500" }],
            },
          ],
        },
        {
          serviceId: 5,
          serviceName: "Foot therapies",
          serviceTypesList: [
            {
              serviceTypeId: 14,
              serviceTypeName: "Head to sole signature massage",
              serviceTypeImg: "/assets/images/head-to-sole.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Anandathai is an extended therapy that combines our signature foot massage with an upper body massage to include arms, hands and head to give you a completely relaxing experience.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "3012" }],
            },
            {
              serviceTypeId: 15,
              serviceTypeName: "Sawadee express",
              serviceTypeImg: "/assets/images/sawadee.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Ideal for those with less time on their hands, this compact version of our signature foot massage will leave you feeling instantly refreshed.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1442" }],
            },
            {
              serviceTypeId: 16,
              serviceTypeName: "Signature foot massage",
              serviceTypeImg: "/assets/images/sig-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Sawadee uses reflexology as the crux of this massage to provide an authentic Thai massage that will deeply relax your tired feet as our special oils  soak into your muscles and ligaments to increase blood flow and reduce muscle tension. A skillful upper body massage completes this therapy to provide a completely relaxing experience.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "1928" }],
            },
            {
              serviceTypeId: 17,
              serviceTypeName: "Thai herbal compress foot massage",
              serviceTypeImg: "/assets/images/thai-herbal-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                'Arokaya is an invigorating foot massage that uses heated Thai herbal compresses to help eliminate muscle aches, boost circulation and remove toxins. "Kanphu balm" (clove oil balm) and aroma oils help to reach deep under the skin and an upper body massage concludes this relaxing experience.',
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2363" },
                { serviceTime: "90 mins", servicePrice: "2906" },
              ],
            },
          ],
        },
        {
          serviceId: 6,
          serviceName: "Head Massages",
          serviceTypesList: [
            // {
            //   serviceTypeId: 18,
            //   serviceTypeName: 'Head massage with anti hair fall',
            //   serviceTypeImg: '/assets/images/anti-hairfall.jpg',
            //   serviceTherapist: 'Physiotherapist',
            //   serviceTypeDesc: 'This therapy is an Aromathai exclusive as it has been created as the ideal solution for all your hair woes. Let our customized hair oil soak into your scalp and encourage blood flow and boost circulation. A wonderfully efficient treatment that promises instant results.',
            //   serviceHours: [
            //     { serviceTime: '45 mins', servicePrice: '1760' }
            //   ]
            // },
            {
              serviceTypeId: 19,
              serviceTypeName: "Head, neck and shoulder massage",
              serviceTypeImg: "/assets/images/head-neck.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This is the ideal massage to provide invigoration to tired muscles with complete focus on the head, neck and shoulders. It provides new energy and well being with an increased supply of oxygen to the brain with special massage techniques.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1171" }],
            },
            {
              serviceTypeId: 20,
              serviceTypeName: "Hot oil head massage",
              serviceTypeImg: "/assets/images/hot-oil-head.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intensely relaxing hot oil massage that lulls you into a stupor with our special oil infused with sesame, peppermint, cedar wood and lavender that helps to remove aches and dull pains.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1466" }],
            },
            {
              serviceTypeId: 21,
              serviceTypeName: "Stress release massage",
              serviceTypeImg: "/assets/images/stress-release.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that is especially for those who spend long hours on computers and behind desks and combat stress on a daily basis. A relaxing therapy that helps to relieve migraine headaches, stiff necks and back pain with expert kneading and manipulation while a soothing foot soak completes this rejuvenating therapy.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1348" }],
            },
            {
              serviceTypeId: 22,
              serviceTypeName: "The after-party massage",
              serviceTypeImg: "/assets/images/day-after-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful head massage that uses magic oil to relieve hangovers, headaches, migraines and even insomnia with its healing properties.",
              serviceHours: [
                { serviceTime: "45 mins", servicePrice: "1655" },
                { serviceTime: "90 mins", servicePrice: "2847" },
              ],
            },
          ],
        },
        {
          serviceId: 7,
          serviceName: "Manicure & Pedicure",
          serviceTypesList: [
            {
              serviceTypeId: 23,
              serviceTypeName: "SPA",
              serviceTypeImg: "/assets/images/spa-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Enjoy our fine services and exclusive products.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "861" },
                { serviceTime: "Pedicure", servicePrice: "979" },
                { serviceTime: "Manicure & Pedicure", servicePrice: "1840" },
              ],
            },
            {
              serviceTypeId: 24,
              serviceTypeName: "ROYAL",
              serviceTypeImg: "/assets/images/royal-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With the added pleasure of a 30 minute relaxing aromatic foot massage.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "2253" },
                { serviceTime: "Pedicure", servicePrice: "2371" },
                // { serviceTime: 'Manicure & Pedicure', servicePrice: 0 }
              ],
            },
          ],
        },
      ],
    },
    {
      cityId: 2,
      city: "Delhi-NCR",
      locationList: [
        {
          locationId: 8,
          location: "Hauz khaz",
          address: "M1, Hauz khaz, Aurobindo Marg, New Delhi-110016",
          email: "hauzkhas@aromathai.net",
          phoneNumber: "011-47297776/47037779",
        },
        {
          locationId: 9,
          location: "Ardee Mall (Gurugram)",
          address: "Shop No.19, sec.43, Gurugram ",
          email: "ardeeggn@aromathai.net",
          phoneNumber: "0124-2806563/4",
        },
        {
          locationId: 11,
          location: "Select Citywalk Mall (Saket)",
          address:
            "Shop No. 11, Lower Ground Floor, Select CityWalk, Saket, New Delhi",
          email: "selectcitywalk@aromathai.net",
          phoneNumber: "011-40634770/71",
        },
        ,
        /* {
          locationId: 12,
          location: "Dlf Mall of India (Noida Sector 18)",
          address: "Shop No.E-359, 2nd Floor, Noida",
          email: "DLFNoida@aromathai.net",
          phoneNumber: "0120-6229528/9",
        }  */ /*{
          locationId: 13,
          location: 'DLF Avenue (Saket)',
          tempLocation: 'DLF Avenue (Saket)',
          address: 'Unit no. (S) 301-302, 2nd floor DLF Avenue, Saket, New Delhi',
          email: '-',
          phoneNumber: '-',
          disabled: true
        }*/ {
          locationId: 13,
          location: "DLF Avenue (Saket)",
          address: "301-302, 2nd Floor, DLF Avenue Saket, New Delhi-110017",
          email: "dlfavenue@aromathai.net",
          phoneNumber: "011-42175907",
        },
        {
          locationId: 15,
          location: "Aromathai Wellness (Vasant Vihar)",
          address: "D-9, Vasant Vihar, 1st Floor, New Delhi 110057",
          email: "vv@aromathai.net",
          phoneNumber: "011-46118026 / 011-46118057",
        },
        {
          locationId: 14,
          location: "Starling Edge (Noida Sector 104)",
          address:
            "Third Floor, Unit No. 301, Starling Edge, Village Hazipur, sector 104, Noida, Uttar Pradesh-201301",
          email: "starling@aromathai.net",
          phoneNumber: "N/A",
          mobileNumber: "9667744360 / 8595264406",
        },
      ],
      serviceList: [
        {
          serviceId: 2,
          serviceName: "Body Massages",
          serviceTypesList: [
            {
              serviceTypeId: 3,
              serviceTypeName: "Aromatherapy Massage",
              serviceTypeImg: "/assets/images/aromatherapy-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that is the perfect way to relax a tired body as aromatic, essential oils and Thai massage techniques liberate energies in your body towards relaxation and rejuvenation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3600" },
                { serviceTime: "90 mins", servicePrice: "4300" },
              ],
              isDisable: true,
            },
            {
              serviceTypeId: 4,
              serviceTypeName: "Deep tissue massage",
              serviceTypeImg: "/assets/images/deep-tissue.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that promises instant relief with its intensity and strong strokes to relieve underlying muscle tension and pain and provide deep relaxation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3800" },
                { serviceTime: "90 mins", servicePrice: "4800" },
              ],
              isDisable: true,
            },
            {
              serviceTypeId: 5,
              serviceTypeName: "Detox and tone Massage",
              serviceTherapist: "Physiotherapist",
              serviceTypeImg: "/assets/images/detox-tone.jpg",
              serviceTypeDesc:
                "A massage that promises you a tangibly toned body with its anti cellulite and detox oils that fight to remove cellular lumps and toxins from your body and increase blood and oxygen flow for physical wellness and cellular balance.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5500" }],
              isDisable: true,
            },
            {
              serviceTypeId: 6,
              serviceTypeName: "Double benefit massage",
              serviceTypeImg: "/assets/images/double-benifit.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This massage involves two therapists working on your back at the same time to provide relief from chronic pains and constant pains.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5950" }],
              isDisable: true,
            },
            {
              serviceTypeId: 7,
              serviceTypeName: "Sportfit massage",
              serviceTypeImg: "/assets/images/sport-fit.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An invigorating massage for those who engage in strenuous activity. A combination of a Thai dry massage that involves stretching to soothe tired muscles and a special combination of oils that seep into muscles that are aggravated and overused. A strong, hard massage that will spring you back to life.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "4850" }],
              isDisable: true,
            },
            {
              serviceTypeId: 8,
              serviceTypeName: "Thai dry back massage",
              serviceTypeImg: "/assets/images/thai-dry-back.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This Thai massage specifically focuses on your upper and lower back. Therapists use intense, but well controlled pressure of their hands and arms to give you the ultimate relief from all your daily aches and sores.",
              serviceHours: [{ serviceTime: "40 mins", servicePrice: "2250" }],
              isDisable: true,
            },
            {
              serviceTypeId: 9,
              serviceTypeName: "Thai Herbal Compress Option",
              serviceTypeImg: "/assets/images/thai-herbal-body.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This value added service can be combined with any one of the above three 60 minute body massages.Steamed herbal pouches are pressed against the body at various energy points to greatly enhance benefits of the massages and provide a feeling of weightlessness.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1300" }],
              isDisable: true,
            },
            {
              serviceTypeId: 10,
              serviceTypeName: "Traditional Thai massage",
              serviceTypeImg: "/assets/images/trad-thai.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that's created with yoga techniques from Thailand wherein the therapist will assist you in a series of yoga stretches to improve circulation and flexibility as you enjoy relaxing joint pain relief, muscle movement and a positive flow of energy in this dry massage that can be done in your comfortable clothing.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3000" },
                { serviceTime: "90 mins", servicePrice: "4000" },
              ],
              isDisable: true,
            },
          ],
        },
        {
          serviceId: 3,
          serviceName: "Body Scrubs",
          serviceTypesList: [
            {
              serviceTypeId: 11,
              serviceTypeName: "Lemon and walnut scrub",
              serviceTypeImg: "/assets/images/walnut-scrub.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense scrub that works well on dry skin types as it exfoliates and cleanses with its astringent properties of sea salt, lemon and walnut oil. It soothes and coaxes dry skin into a lustre with its oil content and stimulates new energy with our special massage. After this cleansing ritual, we highly recommend an enriching masque or oil treatment to pamper your skin a little further.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "3000" }],
              isDisable: true,
            },
            {
              serviceTypeId: 12,
              serviceTypeName: "Rice bran and oatmeal scrub",
              serviceTypeImg: "/assets/images/rice-bran.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that specializes in exfoliating dead cells and giving you youthful, brighter nourished skin with our specially made powder that has been freshly made and ground to leave you with soft,smooth skin that's been gently nourished.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "3000" }],
              isDisable: true,
            },
          ],
        },
        {
          serviceId: 1,
          serviceName: "Body Masques",
          serviceTypesList: [
            {
              serviceTypeId: 1,
              serviceTypeName: "Champagne body masque",
              serviceTypeImg: "/assets/images/champagne.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "The ultimate luxury product for your skin as the sinfully luscious combination of strawberries, peaches and grapes coupled with the finest champagne oil(sourced from Champagne, France) envelopes your skin with its powerful anti oxidant and exfoliating properties as it nourishes each pore with its natural extracts. Great for ageing or dehydrated skin, this masque helps give you smoother, softer and luminous skin with the perfect balance.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4370- Rs. 4790 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5620 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6660 - Rs. 7650/ 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
              isDisable: true,
            },
            {
              serviceTypeId: 2,
              serviceTypeName: "GINGER, SEAWEED AND GREEN TEA MASQUE",
              serviceTypeImg: "/assets/images/ginger-tea.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A masque that detoxifies your skin while providing lost moisture from sunlight and pollutants. Your skin will benefit from the ginger and seaweed combination as it works as a conditioner for your skin while the green tea will help your skin absorb rich nutrients and anti oxidant benefits with its own properties. A wonderfully refreshing treatment for your face with that is very beneficial and encourages softer, smoother skin.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4370- Rs. 4790 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5620 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6660 - Rs. 7650/ 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
              isDisable: true,
            },
          ],
        },
        {
          serviceId: 4,
          serviceName: "Face Refreshers",
          serviceTypesList: [
            {
              serviceTypeId: 13,
              serviceTypeName: "Anti-pollution",
              serviceTypeImg: "/assets/images/anti-poll.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An effective treatment that promises you  glowing skin as we use our special, handpicked  ingredients to cleanse and detoxify worn out skin that's been exposed to harsh elements. Remove the layers of harm from your skin and let it breathe with freshness! ",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2500" }],
            },
            {
              serviceTypeId: 14,
              serviceTypeName: "Collagen Gold",
              serviceTypeImg: "/assets/images/collegen-gold.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "with combination of pure collagen, Q1 snow lotus herb, organic aloe and 24K gold sheets, stimulates skin elasticity and glow.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3200" }],
            },
            {
              serviceTypeId: 15,
              serviceTypeName: "Whitening And Brightening ",
              serviceTypeImg: "/assets/images/whiteningAndBrightening.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense face refresher that brightens, hydrates and gives a glimmer to your skin especially if it is sun damaged or uneven. This treatment uses fruit extracts rich in vitamin E and vitamin C which instantly whitens and leaves the face glowing with health.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2600" }],
            },
            {
              serviceTypeId: 16,
              serviceTypeName: "Deep Cleansing ",
              serviceTypeImg: "/assets/images/deepCleansing.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Helps gives the skin an instant glow by detoxifying, purifying, and hydrating it. Its peel off mask is especially recommended for mixed and oily skin and helps in cleansing clogged pores and acne. For pure, supple and glowing skin this is the face fresher for you.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3600" }],
            },
          ],
        },
        {
          serviceId: 5,
          serviceName: "Foot therapies",
          serviceTypesList: [
            {
              serviceTypeId: 17,
              serviceTypeName: "Head to sole signature massage",
              serviceTypeImg: "/assets/images/head-to-sole.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Anandathai is an extended therapy that combines our signature foot massage with an upper body massage to include arms, hands and head to give you a completely relaxing experience.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "2950" }],
            },
            {
              serviceTypeId: 18,
              serviceTypeName: "Sawadee express",
              serviceTypeImg: "/assets/images/sawadee.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Ideal for those with less time on their hands, this compact version of our signature foot massage will leave you feeling instantly refreshed.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1350" }],
            },
            {
              serviceTypeId: 19,
              serviceTypeName: "Signature foot massage",
              serviceTypeImg: "/assets/images/sig-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Sawadee uses reflexology as the crux of this massage to provide an authentic Thai massage that will deeply relax your tired feet as our special oils  soak into your muscles and ligaments to increase blood flow and reduce muscle tension. A skillful upper body massage completes this therapy to provide a completely relaxing experience.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "1850" }],
            },
            {
              serviceTypeId: 20,
              serviceTypeName: "Thai herbal compress foot massage",
              serviceTypeImg: "/assets/images/thai-herbal-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                'Arokaya is an invigorating foot massage that uses heated Thai herbal compresses to help eliminate muscle aches, boost circulation and remove toxins. "Kanphu balm" (clove oil balm) and aroma oils help to reach deep under the skin and an upper body massage concludes this relaxing experience.',
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2350" },
                { serviceTime: "90 mins", servicePrice: "2950" },
              ],
            },
            {
              serviceTypeId: 21,
              serviceTypeName: "The Thai Dry",
              serviceTypeImg: "/assets/images/thai-dry.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that presses all the right pressure points without oil to give you quick relief.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1450" }],
            },
          ],
        },
        {
          serviceId: 6,
          serviceName: "Head Massages",
          serviceTypesList: [
            {
              serviceTypeId: 22,
              serviceTypeName: "Day-after massage",
              serviceTypeImg: "/assets/images/day-after-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful head massage that uses magic oil to relieve hangovers, headaches, migraines and even insomnia with its healing properties. A soothing foot soak completes the therapy.",
              serviceHours: [
                { serviceTime: "45 mins", servicePrice: "1600" },
                { serviceTime: "90 mins", servicePrice: "2950" },
              ],
            },
            {
              serviceTypeId: 23,
              serviceTypeName: "Head, neck and shoulder massage",
              serviceTypeImg: "/assets/images/head-neck.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This is the ideal massage to provide invigoration to tired muscles with complete focus on the head, neck and shoulders. It provides new energy and well being with an increased supply of oxygen to the brain with special massage techniques.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1350" }],
            },
            {
              serviceTypeId: 24,
              serviceTypeName: "Hot oil head massage",
              serviceTypeImg: "/assets/images/hot-oil-head.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intensely relaxing hot oil massage that lulls you into a stupor with our special oil infused with sesame, peppermint, cedar wood and lavender that helps to remove aches and dull pains.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1450" }],
            },
            {
              serviceTypeId: 25,
              serviceTypeName: "Stress release massage",
              serviceTypeImg: "/assets/images/stress-release.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that is especially for those who spend long hours on computers and behind desks and combat stress on a daily basis. A relaxing therapy that helps to relieve migraine headaches, stiff necks and back pain with expert kneading and manipulation while a soothing foot soak completes this rejuvenating therapy.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1350" }],
            },
          ],
        },
        // {
        //   serviceId: 7,
        //   serviceName: 'Manicure & Pedicure',
        //   serviceTypesList: [
        //     {
        //       serviceTypeId: 26,
        //       serviceTypeName: 'SPA',
        //       serviceTypeImg: '/assets/images/spa-manicure.jpg',
        //       serviceTherapist: 'Physiotherapist',
        //       serviceTypeDesc: 'Enjoy our fine services and exclusive products.',
        //       serviceHours: [
        //         { serviceTime: 'Manicure', servicePrice: '950' },
        //         { serviceTime: 'Pedicure', servicePrice: '1050' },
        //         { serviceTime: 'Manicure & Pedicure', servicePrice: '1800' }
        //       ]
        //     },
        //     {
        //       serviceTypeId: 27,
        //       serviceTypeName: 'ROYAL',
        //       serviceTypeImg: '/assets/images/royal-manicure.jpg',
        //       serviceTherapist: 'Physiotherapist',
        //       serviceTypeDesc: 'With the added pleasure of a 30 minute relaxing aromatic foot massage.',
        //       serviceHours: [
        //         { serviceTime: 'Manicure', servicePrice: '1950' },
        //         { serviceTime: 'Pedicure', servicePrice: '2050' },
        //         { serviceTime: 'Manicure & Pedicure', servicePrice: '2600' }
        //       ]
        //     }
        //   ]
        // }
      ],
    },
    {
      cityId: 3,
      city: "Kolkata",
      locationList: [
        {
          locationId: 15,
          location: "Ballygunge",
          address:
            "64-B, Ballygunge Circular Road, Opp.Tivoli Court, Kolkata-700019",
          email: "ballygunge@aromathai.net",
          phoneNumber: "033-40614394 / 033-40633298",
          mobileNumber: "8583941723",
        },
      ],
      serviceList: [
        {
          serviceId: 2,
          serviceName: "Body Massages",
          serviceTypesList: [
            {
              serviceTypeId: 3,
              serviceTypeName: "Aromatherapy Massage",
              serviceTypeImg: "/assets/images/aromatherapy-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that is the perfect way to relax a tired body as aromatic, essential oils and Thai massage techniques liberate energies in your body towards relaxation and rejuvenation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3000" },
                { serviceTime: "90 mins", servicePrice: "3800" },
              ],
            },
            {
              serviceTypeId: 4,
              serviceTypeName: "Deep tissue massage",
              serviceTypeImg: "/assets/images/deep-tissue.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that promises instant relief with its intensity and strong strokes to relieve underlying muscle tension and pain and provide deep relaxation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3300" },
                { serviceTime: "90 mins", servicePrice: "4500" },
              ],
            },
            {
              serviceTypeId: 5,
              serviceTypeName: "Detox and tone Massage",
              serviceTherapist: "Physiotherapist",
              serviceTypeImg: "/assets/images/detox-tone.jpg",
              serviceTypeDesc:
                "A massage that promises you a tangibly toned body with its anti cellulite and detox oils that fight to remove cellular lumps and toxins from your body and increase blood and oxygen flow for physical wellness and cellular balance.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "4600" }],
            },
            {
              serviceTypeId: 6,
              serviceTypeName: "Sportfit massage",
              serviceTypeImg: "/assets/images/sport-fit.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An invigorating massage for those who engage in strenuous activity. A combination of a Thai dry massage that involves stretching to soothe tired muscles and a special combination of oils that seep into muscles that are aggravated and overused. A strong, hard massage that will spring you back to life.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "4600" }],
            },
            {
              serviceTypeId: 7,
              serviceTypeName: "Traditional Thai massage",
              serviceTypeImg: "/assets/images/trad-thai.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that's created with yoga techniques from Thailand wherein the therapist will assist you in a series of yoga stretches to improve circulation and flexibility as you enjoy relaxing joint pain relief, muscle movement and a positive flow of energy in this dry massage that can be done in your comfortable clothing.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2600" },
                { serviceTime: "90 mins", servicePrice: "3500" },
              ],
            },
          ],
        },
        {
          serviceId: 3,
          serviceName: "Body Scrubs",
          serviceTypesList: [
            {
              serviceTypeId: 8,
              serviceTypeName: "Lemon and walnut scrub",
              serviceTypeImg: "/assets/images/walnut-scrub.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense scrub that works well on dry skin types as it exfoliates and cleanses with its astringent properties of sea salt, lemon and walnut oil. It soothes and coaxes dry skin into a lustre with its oil content and stimulates new energy with our special massage. After this cleansing ritual, we highly recommend an enriching masque or oil treatment to pamper your skin a little further.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2350" }],
            },
            {
              serviceTypeId: 9,
              serviceTypeName: "Rice bran and oatmeal scrub",
              serviceTypeImg: "/assets/images/rice-bran.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that specializes in exfoliating dead cells and giving you youthful, brighter nourished skin with our specially made powder that has been freshly made and ground to leave you with soft,smooth skin that's been gently nourished.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2350" }],
            },
          ],
        },
        {
          serviceId: 1,
          serviceName: "Body Masques",
          serviceTypesList: [
            {
              serviceTypeId: 1,
              serviceTypeName: "Champagne body masque",
              serviceTypeImg: "/assets/images/champagne.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "The ultimate luxury product for your skin as the sinfully luscious combination of strawberries, peaches and grapes coupled with the finest champagne oil(sourced from Champagne, France) envelopes your skin with its powerful anti oxidant and exfoliating properties as it nourishes each pore with its natural extracts. Great for ageing or dehydrated skin, this masque helps give you smoother, softer and luminous skin with the perfect balance.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4350- Rs. 4650 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5600 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6950- Rs. 7550 / 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
            {
              serviceTypeId: 2,
              serviceTypeName: "GINGER, SEAWEED AND GREEN TEA MASQUE",
              serviceTypeImg: "/assets/images/ginger-tea.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A masque that detoxifies your skin while providing lost moisture from sunlight and pollutants. Your skin will benefit from the ginger and seaweed combination as it works as a conditioner for your skin while the green tea will help your skin absorb rich nutrients and anti oxidant benefits with its own properties. A wonderfully refreshing treatment for your face with that is very beneficial and encourages softer, smoother skin.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4350- Rs. 4650 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5600 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6950- Rs. 7550 / 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
          ],
        },
        {
          serviceId: 4,
          serviceName: "Face Refreshers",
          serviceTypesList: [
            {
              serviceTypeId: 10,
              serviceTypeName: "Collagen Gold",
              serviceTypeImg: "/assets/images/collegen-gold.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With combination of pure collagen, Q1 snow lotus herb, organic aloe and 24K gold sheets, stimulates skin elasticity and glow.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3300" }],
            },
            {
              serviceTypeId: 11,
              serviceTypeName: "deep cleansing",
              serviceTypeImg: "/assets/images/deepCleansing.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Our special ingredient Advance Time Oxygen Complex boosts the oxygen absorption into your skin pores, for a softer healthier look.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3800" }],
            },
            {
              serviceTypeId: 12,
              serviceTypeName: "Whitening and brightening",
              serviceTypeImg: "/assets/images/whiteningAndBrightening.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful pick-me-up for your skin as enriched blends of vitamin c, HA and turmeric extracts work their magic and strengthen your skin to fight UV exposure and promote cell renewal.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2750" }],
            },
          ],
        },
        {
          serviceId: 5,
          serviceName: "Foot therapies",
          serviceTypesList: [
            {
              serviceTypeId: 13,
              serviceTypeName: "Head to sole signature massage",
              serviceTypeImg: "/assets/images/head-to-sole.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Anandathai is an extended therapy that combines our signature foot massage with an upper body massage to include arms, hands and head to give you a completely relaxing experience.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "3000" }],
            },

            {
              serviceTypeId: 14,
              serviceTypeName: "Sawadee express",
              serviceTypeImg: "/assets/images/sawadee.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Ideal for those with less time on their hands, this compact version of our signature foot massage will leave you feeling instantly refreshed.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1200" }],
            },
            {
              serviceTypeId: 15,
              serviceTypeName: "Signature foot massage",
              serviceTypeImg: "/assets/images/sig-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Sawadee uses reflexology as the crux of this massage to provide an authentic Thai massage that will deeply relax your tired feet as our special oils soak into your muscles and ligaments to increase blood flow and reduce muscle tension. A skillful upper body massage completes this therapy to provide a completely relaxing experience.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "1850" }],
            },
            {
              serviceTypeId: 16,
              serviceTypeName: "The Thai Dry",
              serviceTypeImg: "/assets/images/thai-dry.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that presses all the right pressure points without oil to give you quick relief.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1250" }],
            },
            {
              serviceTypeId: 17,
              serviceTypeName: "Thai herbal compress foot massage",
              serviceTypeImg: "/assets/images/thai-herbal-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                'Arokaya is an invigorating foot massage that uses heated Thai herbal compresses to help eliminate muscle aches, boost circulation and remove toxins. "Kanphu balm" (clove oil balm) and aroma oils help to reach deep under the skin and an upper body massage concludes this relaxing experience.',
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "1850" },
                { serviceTime: "90 mins", servicePrice: "2500" },
              ],
            },
          ],
        },
        {
          serviceId: 6,
          serviceName: "Head Massages",
          serviceTypesList: [
            {
              serviceTypeId: 18,
              serviceTypeName: "Head massage with anti hair fall",
              serviceTypeImg: "/assets/images/anti-hairfall.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This therapy is an Aromathai exclusive as it has been created as the ideal solution for all your hair woes. Let our customized hair oil soak into your scalp and encourage blood flow and boost circulation. A wonderfully efficient treatment that promises instant results.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1690" }],
            },
            {
              serviceTypeId: 19,
              serviceTypeName: "Head, neck and shoulder massage",
              serviceTypeImg: "/assets/images/head-neck.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This is the ideal massage to provide invigoration to tired muscles with complete focus on the head, neck and shoulders. It provides new energy and well being with an increased supply of oxygen to the brain with special massage techniques.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1200" }],
            },
            {
              serviceTypeId: 20,
              serviceTypeName: "Hot oil head massage",
              serviceTypeImg: "/assets/images/hot-oil-head.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intensely relaxing hot oil massage that lulls you into a stupor with our special oil infused with sesame, peppermint, cedar wood and lavender that helps to remove aches and dull pains.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1300" }],
            },
            {
              serviceTypeId: 21,
              serviceTypeName: "Stress release massage",
              serviceTypeImg: "/assets/images/stress-release.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that is especially for those who spend long hours on computers and behind desks and combat stress on a daily basis. A relaxing therapy that helps to relieve migraine headaches, stiff necks and back pain with expert kneading and manipulation while a soothing foot soak completes this rejuvenating therapy.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1100" }],
            },
            {
              serviceTypeId: 22,
              serviceTypeName: "The after-party massage",
              serviceTypeImg: "/assets/images/day-after-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful head massage that uses magic oil to relieve hangovers, headaches, migraines and even insomnia with its healing properties.A soothing foot soak completes the therapy.",
              serviceHours: [
                { serviceTime: "45 mins", servicePrice: "1800" },
                { serviceTime: "90 mins", servicePrice: "3000" },
              ],
            },
          ],
        },
        {
          serviceId: 7,
          serviceName: "Manicure & Pedicure",
          serviceTypesList: [
            {
              serviceTypeId: 23,
              serviceTypeName: "SPA",
              serviceTypeImg: "/assets/images/spa-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Enjoy our fine services and exclusive products.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "1150" },
                { serviceTime: "Pedicure", servicePrice: "1550" },
                { serviceTime: "Manicure & Pedicure", servicePrice: "2600" },
              ],
            },
            {
              serviceTypeId: 24,
              serviceTypeName: "ROYAL",
              serviceTypeImg: "/assets/images/royal-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With the added pleasure of a 30 minute relaxing aromatic foot massage.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "2200" },
                { serviceTime: "Pedicure", servicePrice: "2700" },
                { serviceTime: "Manicure & Pedicure", servicePrice: "3850" },
              ],
            },
          ],
        },
      ],
    },
    {
      cityId: 4,
      city: "Pune",
      locationList: [
        {
          locationId: 16,
          location: "Phoenix Market city",
          address:
            "Phoenix Market city, F-67, 1st Floor, Survey No. 207, Nagar Road, Viman Nagar, Pune-411014",
          email: "mcpune@aromathai.net",
          phoneNumber: "020-66890425 / 020-66890426",
          mobileNumber: "9167047444",
        },
      ],
      serviceList: [
        {
          serviceId: 2,
          serviceName: "Body Massages",
          serviceTypesList: [
            {
              serviceTypeId: 3,
              serviceTypeName: "Aromatherapy Massage",
              serviceTypeImg: "/assets/images/aromatherapy-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that is the perfect way to relax a tired body as aromatic, essential oils and Thai massage techniques liberate energies in your body towards relaxation and rejuvenation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3758" },
                { serviceTime: "90 mins", servicePrice: "4808" },
              ],
            },
            {
              serviceTypeId: 4,
              serviceTypeName: "Detox and tone Massage",
              serviceTherapist: "Physiotherapist",
              serviceTypeImg: "/assets/images/detox-tone.jpg",
              serviceTypeDesc:
                "A massage that promises you a tangibly toned body with its anti cellulite and detox oils that fight to remove cellular lumps and toxins from your body and increase blood and oxygen flow for physical wellness and cellular balance.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5752" }],
            },
            {
              serviceTypeId: 5,
              serviceTypeName: "Deep tissue massage",
              serviceTypeImg: "/assets/images/deep-tissue.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that promises instant relief with its intensity and strong strokes to relieve underlying muscle tension and pain and provide deep relaxation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "4006" },
                { serviceTime: "90 mins", servicePrice: "4985" },
              ],
            },
            {
              serviceTypeId: 6,
              serviceTypeName: "Sportfit massage",
              serviceTypeImg: "/assets/images/sport-fit.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An invigorating massage for those who engage in strenuous activity. A combination of a Thai dry massage that involves stretching to soothe tired muscles and a special combination of oils that seep into muscles that are aggravated and overused. A strong, hard massage that will spring you back to life.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5493" }],
            },
            {
              serviceTypeId: 7,
              serviceTypeName: "Thai dry back massage",
              serviceTypeImg: "/assets/images/thai-dry-back.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This Thai massage specifically focuses on your upper and lower back. Therapists use intense, but well controlled pressure of their hands and arms to give you the ultimate relief from all your daily aches and sores.",
              serviceHours: [{ serviceTime: "40 mins", servicePrice: "2307" }],
            },
            {
              serviceTypeId: 8,
              serviceTypeName: "Traditional Thai massage",
              serviceTypeImg: "/assets/images/trad-thai.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that's created with yoga techniques from Thailand wherein the therapist will assist you in a series of yoga stretches to improve circulation and flexibility as you enjoy relaxing joint pain relief, muscle movement and a positive flow of energy in this dry massage that can be done in your comfortable clothing.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2684" },
                { serviceTime: "90 mins", servicePrice: "3605" },
              ],
            },
            {
              serviceTypeId: 9,
              serviceTypeName: "Thai Herbal Compress Option",
              serviceTypeImg: "/assets/images/thai-herbal-body.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This value added service can be combined with any one of the above three 60 minute body massages. Steamed herbal pouches are pressed against the body at various energy points to greatly enhance benefits of the massages and provide a feeling of weightlessness.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "900" }],
            },
          ],
        },
        {
          serviceId: 3,
          serviceName: "Body Scrubs",
          serviceTypesList: [
            {
              serviceTypeId: 10,
              serviceTypeName: "Lemon and walnut scrub",
              serviceTypeImg: "/assets/images/walnut-scrub.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense scrub that works well on dry skin types as it exfoliates and cleanses with its astringent properties of sea salt, lemon and walnut oil. It soothes and coaxes dry skin into a lustre with its oil content and stimulates new energy with our special massage. After this cleansing ritual, we highly recommend an enriching masque or oil treatment to pamper your skin a little further.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2550" }],
            },
            {
              serviceTypeId: 11,
              serviceTypeName: "Rice bran and oatmeal scrub",
              serviceTypeImg: "/assets/images/rice-bran.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that specializes in exfoliating dead cells and giving you youthful, brighter nourished skin with our specially made powder that has been freshly made and ground to leave you with soft,smooth skin that's been gently nourished.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2550" }],
            },
          ],
        },
        {
          serviceId: 1,
          serviceName: "Body Masques",
          serviceTypesList: [
            {
              serviceTypeId: 1,
              serviceTypeName: "Champagne body masque",
              serviceTypeImg: "/assets/images/champagne.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "The ultimate luxury product for your skin as the sinfully luscious combination of strawberries, peaches and grapes coupled with the finest champagne oil(sourced from Champagne, France) envelopes your skin with its powerful anti oxidant and exfoliating properties as it nourishes each pore with its natural extracts. Great for ageing or dehydrated skin, this masque helps give you smoother, softer and luminous skin with the perfect balance.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4350- Rs. 4650 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5600 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6950- Rs. 7550 / 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
            {
              serviceTypeId: 2,
              serviceTypeName: "GINGER, SEAWEED AND GREEN TEA MASQUE",
              serviceTypeImg: "/assets/images/ginger-tea.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A masque that detoxifies your skin while providing lost moisture from sunlight and pollutants. Your skin will benefit from the ginger and seaweed combination as it works as a conditioner for your skin while the green tea will help your skin absorb rich nutrients and anti oxidant benefits with its own properties. A wonderfully refreshing treatment for your face with that is very beneficial and encourages softer, smoother skin.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              Addonplans: [
                "Choice of Scrub + Choice of Masque Rs. 4350- Rs. 4650 / 75 mins",
                "Choice of Scrub + Choice of Body Massage Rs. 5300 - Rs. 5600 / 120 mins",
                "Choice of Scrub + Choice of Masque + Choice of Body Massage Rs. 6950- Rs. 7550 / 180 mins",
              ],
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
          ],
        },
        {
          serviceId: 4,
          serviceName: "Face Refreshers",
          serviceTypesList: [
            {
              serviceTypeId: 12,
              serviceTypeName: "Collagen Gold",
              serviceTypeImg: "/assets/images/collegen-gold.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With combination of pure collagen, Q1 snow lotus herb, organic aloe and 24K gold sheets, stimulates skin elasticity and glow.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2454" }],
            },
            // {
            //   serviceTypeId: 13,
            //   serviceTypeName: 'Oxygen Pure',
            //   serviceTypeImg: '/assets/images/o2.jpg',
            //   serviceTherapist: 'Physiotherapist',
            //   serviceTypeDesc: 'Our special ingredient Advance Time Oxygen Complex boosts the oxygen absorption into your skin pores, for a softer healthier look.',
            //   serviceHours: [
            //     { serviceTime: '60 mins', servicePrice: '2089' }
            //   ]
            // },
            {
              serviceTypeId: 14,
              serviceTypeName: "Radiance C",
              serviceTypeImg: "/assets/images/radiancec.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful pick-me-up for your skin as enriched blends of vitamin c, HA and turmeric extracts work their magic and strengthen your skin to fight UV exposure and promote cell renewal.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2089" }],
            },
          ],
        },
        {
          serviceId: 5,
          serviceName: "Foot therapies",
          serviceTypesList: [
            {
              serviceTypeId: 15,
              serviceTypeName: "Head to sole signature massage",
              serviceTypeImg: "/assets/images/head-to-sole.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Anandathai is an extended therapy that combines our signature foot massage with an upper body massage to include arms, hands and head to give you a completely relaxing experience.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "3012" }],
            },
            {
              serviceTypeId: 16,
              serviceTypeName: "Sawadee express",
              serviceTypeImg: "/assets/images/sawadee.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Ideal for those with less time on their hands, this compact version of our signature foot massage will leave you feeling instantly refreshed.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1442" }],
            },
            {
              serviceTypeId: 17,
              serviceTypeName: "Signature foot massage",
              serviceTypeImg: "/assets/images/sig-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Sawadee uses reflexology as the crux of this massage to provide an authentic Thai massage that will deeply relax your tired feet as our special oils soak into your muscles and ligaments to increase blood flow and reduce muscle tension. A skillful upper body massage completes this therapy to provide a completely relaxing experience.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "1928" }],
            },
            {
              serviceTypeId: 18,
              serviceTypeName: "Thai herbal compress foot massage",
              serviceTypeImg: "/assets/images/thai-herbal-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                'Arokaya is an invigorating foot massage that uses heated Thai herbal compresses to help eliminate muscle aches, boost circulation and remove toxins. "Kanphu balm" (clove oil balm) and aroma oils help to reach deep under the skin and an upper body massage concludes this relaxing experience.',
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2363" },
                { serviceTime: "90 mins", servicePrice: "2906" },
              ],
            },
          ],
        },
        {
          serviceId: 6,
          serviceName: "Head Massages",
          serviceTypesList: [
            // {
            //   serviceTypeId: 19,
            //   serviceTypeName: 'Head massage with anti hair fall',
            //   serviceTypeImg: '/assets/images/anti-hairfall.jpg',
            //   serviceTherapist: 'Physiotherapist',
            //   serviceTypeDesc: 'This therapy is an Aromathai exclusive as it has been created as the ideal solution for all your hair woes. Let our customized hair oil soak into your scalp and encourage blood flow and boost circulation. A wonderfully efficient treatment that promises instant results.',
            //   serviceHours: [
            //     { serviceTime: '45 mins', servicePrice: '1760' }
            //   ]
            // },
            {
              serviceTypeId: 20,
              serviceTypeName: "Head, neck and shoulder massage",
              serviceTypeImg: "/assets/images/head-neck.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This is the ideal massage to provide invigoration to tired muscles with complete focus on the head, neck and shoulders. It provides new energy and well being with an increased supply of oxygen to the brain with special massage techniques.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1171" }],
            },

            {
              serviceTypeId: 21,
              serviceTypeName: "Hot oil head massage",
              serviceTypeImg: "/assets/images/hot-oil-head.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intensely relaxing hot oil massage that lulls you into a stupor with our special oil infused with sesame, peppermint, cedar wood and lavender that helps to remove aches and dull pains.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1466" }],
            },
            {
              serviceTypeId: 22,
              serviceTypeName: "Stress release massage",
              serviceTypeImg: "/assets/images/stress-release.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that is especially for those who spend long hours on computers and behind desks and combat stress on a daily basis. A relaxing therapy that helps to relieve migraine headaches, stiff necks and back pain with expert kneading and manipulation while a soothing foot soak completes this rejuvenating therapy.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1348" }],
            },
            {
              serviceTypeId: 23,
              serviceTypeName: "The after-party massage",
              serviceTypeImg: "/assets/images/day-after-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful head massage that uses magic oil to relieve hangovers, headaches, migraines and even insomnia with its healing properties. A soothing foot soak completes the therapy.",
              serviceHours: [
                { serviceTime: "45 mins", servicePrice: "1655" },
                { serviceTime: "90 mins", servicePrice: "2847" },
              ],
            },
          ],
        },
        {
          serviceId: 7,
          serviceName: "Manicure & Pedicure",
          serviceTypesList: [
            {
              serviceTypeId: 24,
              serviceTypeName: "SPA",
              serviceTypeImg: "/assets/images/spa-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Enjoy our fine services and exclusive products.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "861" },
                { serviceTime: "Pedicure", servicePrice: "979" },
                { serviceTime: "Manicure & Pedicure", servicePrice: "1840" },
              ],
            },
            {
              serviceTypeId: 25,
              serviceTypeName: "ROYAL",
              serviceTypeImg: "/assets/images/royal-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With the added pleasure of a 30 minute relaxing aromatic foot massage.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "2253" },
                { serviceTime: "Pedicure", servicePrice: "2371" },
                // { serviceTime: 'Manicure & Pedicure', servicePrice: '2340' }
              ],
            },
          ],
        },
      ],
    },
    /* {
      cityId: 6,
      city: "Kochi",
      locationList: [
        {
          locationId: 16,
          location: "Aromathai Kochi",
          address:
            "Unit No: S-48, IInd Floor, Lulu International Shopping Mall, Edapally, Kochi-682024, Kerala, India",
          email: "kochi@aromathai.net",
          phoneNumber: "0484-4021111",
        },
      ],
      serviceList: [
        {
          serviceId: 1,
          serviceName: "Body Massages",
          serviceTypesList: [
            {
              serviceTypeId: 1,
              serviceTypeName: "Aromatherapy Massage",
              serviceTypeImg: "/assets/images/aromatherapy-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                " A massage that is the perfect way to relax a tired body as aromatic, essential oils and Thai massage techniques liberate energies in your body towards relaxation and rejuvenation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3500" },
                { serviceTime: "90 mins", servicePrice: "4500" },
              ],
            },
            {
              serviceTypeId: 2,
              serviceTypeName: "Deep tissue massage",
              serviceTypeImg: "/assets/images/deep-tissue.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that promises instant relief with its intensity and strong strokes to relieve underlying muscle tension and pain and provide deep relaxation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3700" },
                { serviceTime: "90 mins", servicePrice: "5000" },
              ],
            },
            {
              serviceTypeId: 3,
              serviceTypeName: "Detox and tone Massage",
              serviceTherapist: "Physiotherapist",
              serviceTypeImg: "/assets/images/detox-tone.jpg",
              serviceTypeDesc:
                "A massage that promises you a tangibly toned body with its anti cellulite and detox oils that fight to remove cellular lumps and toxins from your body and increase blood and oxygen flow for physical wellness and cellular balance.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5500" }],
            },
            {
              serviceTypeId: 4,
              serviceTypeName: "Thai dry back massage",
              serviceTypeImg: "/assets/images/thai-dry-back.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This Thai massage specifically focuses on your upper and lower back. Therapists use intense, but well controlled pressure of their hands and arms to give you the ultimate relief from all your daily aches and sores.",
              serviceHours: [{ serviceTime: "40 mins", servicePrice: "2300" }],
            },
            {
              serviceTypeId: 5,
              serviceTypeName: "Thai Herbal Compress Option",
              serviceTypeImg: "/assets/images/thai-herbal-body.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This value added service can be combined with any one of the above three 60 minute body massages. Steamed herbal pouches are pressed against the body at various energy points to greatly enhance benefits of the massages and provide a feeling of weightlessness.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1000" }],
            },
            {
              serviceTypeId: 6,
              serviceTypeName: "Traditional Thai massage",
              serviceTypeImg: "/assets/images/trad-thai.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that's created with yoga techniques from Thailand wherein the therapist will assist you in a series of yoga stretches to improve circulation and flexibility as you enjoy relaxing joint pain relief, muscle movement and a positive flow of energy in this dry massage that can be done in your comfortable clothing.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3350" },
                { serviceTime: "90 mins", servicePrice: "4250" },
              ],
            },
          ],
        },
        {
          serviceId: 2,
          serviceName: "Body Scrubs",
          serviceTypesList: [
            {
              serviceTypeId: 7,
              serviceTypeName: "Lemon and walnut scrub",
              serviceTypeImg: "/assets/images/walnut-scrub.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense scrub that works well on dry skin types as it exfoliates and cleanses with its astringent properties of sea salt, lemon and walnut oil. It soothes and coaxes dry skin into a lustre with its oil content and stimulates new energy with our special massage. After this cleansing ritual, we highly recommend an enriching masque or oil treatment to pamper your skin a little further.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2750" }],
            },
            {
              serviceTypeId: 8,
              serviceTypeName: "Rice bran and oatmeal scrub",
              serviceTypeImg: "/assets/images/rice-bran.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that specializes in exfoliating dead cells and giving you youthful, brighter nourished skin with our specially made powder that has been freshly made and ground to leave you with soft,smooth skin that's been gently nourished.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2750" }],
            },
          ],
        },
        {
          serviceId: 3,
          serviceName: "Body Masques",
          serviceTypesList: [
            {
              serviceTypeId: 9,
              serviceTypeName: "Champagne body masque",
              serviceTypeImg: "/assets/images/champagne.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "The ultimate luxury product for your skin as the sinfully luscious combination of strawberries, peaches and grapes coupled with the finest champagne oil(sourced from Champagne, France) envelopes your skin with its powerful anti oxidant and exfoliating properties as it nourishes each pore with its natural extracts. Great for ageing or dehydrated skin, this masque helps give you smoother, softer and luminous skin with the perfect balance.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
            {
              serviceTypeId: 10,
              serviceTypeName: "GINGER, SEAWEED AND GREEN TEA MASQUE",
              serviceTypeImg: "/assets/images/ginger-tea.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A masque that detoxifies your skin while providing lost moisture from sunlight and pollutants. Your skin will benefit from the ginger and seaweed combination as it works as a conditioner for your skin while the green tea will help your skin absorb rich nutrients and anti oxidant benefits with its own properties. A wonderfully refreshing treatment for your face with that is very beneficial and encourages softer, smoother skin.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
          ],
        },
        {
          serviceId: 4,
          serviceName: "Face Refreshers",
          serviceTypesList: [
            {
              serviceTypeId: 11,
              serviceTypeName: "Collagen Gold",
              serviceTypeImg: "/assets/images/collegen-gold.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With combination of pure collagen, Q1 snow lotus herb, organic aloe and 24K gold sheets, stimulates skin elasticity and glow",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2700" }],
            },
            {
              serviceTypeId: 12,
              serviceTypeName: "Deep Cleansing",
              serviceTypeImg: "/assets/images/deepCleansing.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Our special ingredient Advance Time Oxygen Complex boosts the oxygen absorption into your skin pores, for a softer healthier look.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3700" }],
            },
            {
              serviceTypeId: 13,
              serviceTypeName: "Whitening and brightening",
              serviceTypeImg: "/assets/images/whiteningAndBrightening.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful pick-me-up for your skin as enriched blends of vitamin c, HA and turmeric extracts work their magic and strengthen your skin to fight UV exposure and promote cell renewal.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2750" }],
            },
          ],
        },
        {
          serviceId: 5,
          serviceName: "Foot therapies",
          serviceTypesList: [
            {
              serviceTypeId: 14,
              serviceTypeName: "Head to sole signature massage",
              serviceTypeImg: "/assets/images/head-to-sole.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Anandathai is an extended therapy that combines our signature foot massage with an upper body massage to include arms, hands and head to give you a completely relaxing experience.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "3000" }],
            },
            {
              serviceTypeId: 15,
              serviceTypeName: "Thai herbal compress foot massage",
              serviceTypeImg: "/assets/images/thai-herbal-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                'Arokaya is an invigorating foot massage that uses heated Thai herbal compresses to help eliminate muscle aches, boost circulation and remove toxins. "Kanphu balm" (clove oil balm) and aroma oils help to reach deep under the skin and an upper body massage concludes this relaxing experience.',
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2500" },
                { serviceTime: "90 mins", servicePrice: "3200" },
              ],
            },
            {
              serviceTypeId: 16,
              serviceTypeName: "Sawadee express",
              serviceTypeImg: "/assets/images/sawadee.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Ideal for those with less time on their hands, this compact version of our signature foot massage will leave you feeling instantly refreshed.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1500" }],
            },
            {
              serviceTypeId: 17,
              serviceTypeName: "Signature foot massage",
              serviceTypeImg: "/assets/images/sig-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Sawadee uses reflexology as the crux of this massage to provide an authentic Thai massage that will deeply relax your tired feet as our special oils soak into your muscles and ligaments to increase blood flow and reduce muscle tension. A skillful upper body massage completes this therapy to provide a completely relaxing experience.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2200" }],
            },
          ],
        },
        {
          serviceId: 6,
          serviceName: "Head Massages",
          serviceTypesList: [
            {
              serviceTypeId: 18,
              serviceTypeName: "Head, neck and shoulder massage",
              serviceTypeImg: "/assets/images/head-neck.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This is the ideal massage to provide invigoration to tired muscles with complete focus on the head, neck and shoulders. It provides new energy and well being with an increased supply of oxygen to the brain with special massage techniques.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1600" }],
            },
            {
              serviceTypeId: 19,
              serviceTypeName: "Hot oil head massage",
              serviceTypeImg: "/assets/images/hot-oil-head.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intensely relaxing hot oil massage that lulls you into a stupor with our special oil infused with sesame, peppermint, cedar wood and lavender that helps to remove aches and dull pains.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "2050" }],
            },
            {
              serviceTypeId: 20,
              serviceTypeName: "The after - party massage",
              serviceTypeImg: "/assets/images/day-after-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful head massage that uses magic oil to relieve hangovers, headaches, migraines and even insomnia with its healing properties. A soothing foot soak completes the therapy.",
              serviceHours: [
                { serviceTime: "45 mins", servicePrice: "2000" },
                { serviceTime: "90 mins", servicePrice: "3200" },
              ],
            },
          ],
        },
        {
          serviceId: 7,
          serviceName: "Manicure & Pedicure",
          serviceTypesList: [
            {
              serviceTypeId: 21,
              serviceTypeName: "SPA",
              serviceTypeImg: "/assets/images/spa-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Enjoy our fine services and exclusive products.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "950" },
                { serviceTime: "Pedicure", servicePrice: "1050" },
                { serviceTime: "Manicure & Pedicure", servicePrice: "1800" },
              ],
            },
            {
              serviceTypeId: 22,
              serviceTypeName: "ROYAL",
              serviceTypeImg: "/assets/images/royal-manicure.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With the added pleasure of a 30 minute relaxing aromatic foot massage.",
              serviceHours: [
                { serviceTime: "Manicure", servicePrice: "2200" },
                { serviceTime: "Pedicure", servicePrice: "2200" },
                { serviceTime: "Manicure & Pedicure", servicePrice: "2800" },
              ],
            },
          ],
        },
      ],
    }, */
    {
      cityId: 7,
      city: "Dehradun",
      locationList: [
        {
          locationId: 17,
          location: "Aromathai spa",
          address:
            "Pacific Mall, Shop no -sf-01d, Rajpur road, Dehradhun, uttarakhand-248006",
          email: "dehradun@aromathai.net",
          phoneNumber: "0135-2735558/9",
        },
      ],
      serviceList: [
        {
          serviceId: 1,
          serviceName: "Body Massages",
          serviceTypesList: [
            {
              serviceTypeId: 1,
              serviceTypeName: "Aromatherapy Massage",
              serviceTypeImg: "/assets/images/aromatherapy-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                " A massage that is the perfect way to relax a tired body as aromatic, essential oils and Thai massage techniques liberate energies in your body towards relaxation and rejuvenation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2700" },
                { serviceTime: "90 mins", servicePrice: "3300" },
              ],
            },
            {
              serviceTypeId: 2,
              serviceTypeName: "Deep tissue massage",
              serviceTypeImg: "/assets/images/deep-tissue.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that promises instant relief with its intensity and strong strokes to relieve underlying muscle tension and pain and provide deep relaxation.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "3100" },
                { serviceTime: "90 mins", servicePrice: "3700" },
              ],
            },
            {
              serviceTypeId: 3,
              serviceTypeName: "Detox and tone Massage",
              serviceTherapist: "Physiotherapist",
              serviceTypeImg: "/assets/images/detox-tone.jpg",
              serviceTypeDesc:
                "A massage that promises you a tangibly toned body with its anti cellulite and detox oils that fight to remove cellular lumps and toxins from your body and increase blood and oxygen flow for physical wellness and cellular balance.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "3950" }],
            },
            {
              serviceTypeId: 4,
              serviceTypeName: "Thai dry back massage",
              serviceTypeImg: "/assets/images/thai-dry-back.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This Thai massage specifically focuses on your upper and lower back. Therapists use intense, but well controlled pressure of their hands and arms to give you the ultimate relief from all your daily aches and sores.",
              serviceHours: [{ serviceTime: "40 mins", servicePrice: "2150" }],
            },
            {
              serviceTypeId: 5,
              serviceTypeName: "Thai Herbal Compress Option",
              serviceTypeImg: "/assets/images/thai-herbal-body.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This value added service can be combined with any one of the above three 60 minute body massages. Steamed herbal pouches are pressed against the body at various energy points to greatly enhance benefits of the massages and provide a feeling of weightlessness.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "900" }],
            },
            {
              serviceTypeId: 6,
              serviceTypeName: "Traditional Thai massage",
              serviceTypeImg: "/assets/images/trad-thai.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that's created with yoga techniques from Thailand wherein the therapist will assist you in a series of yoga stretches to improve circulation and flexibility as you enjoy relaxing joint pain relief, muscle movement and a positive flow of energy in this dry massage that can be done in your comfortable clothing.",
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2550" },
                { serviceTime: "90 mins", servicePrice: "3300" },
              ],
            },
            {
              serviceTypeId: 7,
              serviceTypeName: "Double Benefit Massage",
              serviceTypeImg: "/assets/images/double-benifit.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This massage involves two therapists working on your back at the same time to provide relief from chronic pains and constant pains.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "5400" }],
            },
          ],
        },
        {
          serviceId: 2,
          serviceName: "Body Scrubs",
          serviceTypesList: [
            {
              serviceTypeId: 7,
              serviceTypeName: "Lemon and walnut scrub",
              serviceTypeImg: "/assets/images/walnut-scrub.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intense scrub that works well on dry skin types as it exfoliates and cleanses with its astringent properties of sea salt, lemon and walnut oil. It soothes and coaxes dry skin into a lustre with its oil content and stimulates new energy with our special massage. After this cleansing ritual, we highly recommend an enriching masque or oil treatment to pamper your skin a little further.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2600" }],
            },
            {
              serviceTypeId: 8,
              serviceTypeName: "Rice bran and oatmeal scrub",
              serviceTypeImg: "/assets/images/rice-bran.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that specializes in exfoliating dead cells and giving you youthful, brighter nourished skin with our specially made powder that has been freshly made and ground to leave you with soft,smooth skin that's been gently nourished.",
              serviceHours: [{ serviceTime: "45 mins", servicePrice: "2600" }],
            },
          ],
        },
        {
          serviceId: 3,
          serviceName: "Body Masques",
          serviceTypesList: [
            {
              serviceTypeId: 9,
              serviceTypeName: "Champagne body masque",
              serviceTypeImg: "/assets/images/champagne.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "The ultimate luxury product for your skin as the sinfully luscious combination of strawberries, peaches and grapes coupled with the finest champagne oil(sourced from Champagne, France) envelopes your skin with its powerful anti oxidant and exfoliating properties as it nourishes each pore with its natural extracts. Great for ageing or dehydrated skin, this masque helps give you smoother, softer and luminous skin with the perfect balance.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
            {
              serviceTypeId: 10,
              serviceTypeName: "GINGER, SEAWEED AND GREEN TEA MASQUE",
              serviceTypeImg: "/assets/images/ginger-tea.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A masque that detoxifies your skin while providing lost moisture from sunlight and pollutants. Your skin will benefit from the ginger and seaweed combination as it works as a conditioner for your skin while the green tea will help your skin absorb rich nutrients and anti oxidant benefits with its own properties. A wonderfully refreshing treatment for your face with that is very beneficial and encourages softer, smoother skin.",
              AddondetailsDesc:
                "This treatment is best combined with a body scrub/massage",
              serviceHours: [{ serviceTime: "75 mins", servicePrice: "0" }],
            },
          ],
        },
        {
          serviceId: 4,
          serviceName: "Face Refreshers",
          serviceTypesList: [
            {
              serviceTypeId: 11,
              serviceTypeName: "Collagen Gold",
              serviceTypeImg: "/assets/images/collegen-gold.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "With combination of pure collagen, Q1 snow lotus herb, organic aloe and 24K gold sheets, stimulates skin elasticity and glow",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3000" }],
            },
            {
              serviceTypeId: 12,
              serviceTypeName: "Deep Cleansing",
              serviceTypeImg: "/assets/images/deepCleansing.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Our special ingredient Advance Time Oxygen Complex boosts the oxygen absorption into your skin pores, for a softer healthier look.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "3500" }],
            },
            {
              serviceTypeId: 13,
              serviceTypeName: "Whitening and Brightening",
              serviceTypeImg: "/assets/images/whiteningAndBrightening.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful pick-me-up for your skin as enriched blends of vitamin c, HA and turmeric extracts work their magic and strengthen your skin to fight UV exposure and promote cell renewal.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "2500" }],
            },
          ],
        },
        {
          serviceId: 5,
          serviceName: "Foot therapies",
          serviceTypesList: [
            {
              serviceTypeId: 14,
              serviceTypeName: "Head to sole signature massage",
              serviceTypeImg: "/assets/images/head-to-sole.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Anandathai is an extended therapy that combines our signature foot massage with an upper body massage to include arms, hands and head to give you a completely relaxing experience.",
              serviceHours: [{ serviceTime: "90 mins", servicePrice: "1950" }],
            },
            {
              serviceTypeId: 15,
              serviceTypeName: "Thai herbal compress foot massage",
              serviceTypeImg: "/assets/images/thai-herbal-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                'Arokaya is an invigorating foot massage that uses heated Thai herbal compresses to help eliminate muscle aches, boost circulation and remove toxins. "Kanphu balm" (clove oil balm) and aroma oils help to reach deep under the skin and an upper body massage concludes this relaxing experience.',
              serviceHours: [
                { serviceTime: "60 mins", servicePrice: "2150" },
                { serviceTime: "90 mins", servicePrice: "2600" },
              ],
            },
            {
              serviceTypeId: 16,
              serviceTypeName: "Sawadee express",
              serviceTypeImg: "/assets/images/sawadee.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Ideal for those with less time on their hands, this compact version of our signature foot massage will leave you feeling instantly refreshed.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1150" }],
            },
            {
              serviceTypeId: 17,
              serviceTypeName: "Signature foot massage",
              serviceTypeImg: "/assets/images/sig-foot.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "Sawadee uses reflexology as the crux of this massage to provide an authentic Thai massage that will deeply relax your tired feet as our special oils soak into your muscles and ligaments to increase blood flow and reduce muscle tension. A skillful upper body massage completes this therapy to provide a completely relaxing experience.",
              serviceHours: [{ serviceTime: "60 mins", servicePrice: "1450" }],
            },
            {
              serviceTypeId: 18,
              serviceTypeName: "The hai Dry",
              serviceTypeImg: "/assets/images/sawadee.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A massage that presses all the right pressure point without oil to give you quick relief.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1100" }],
            },
          ],
        },
        {
          serviceId: 6,
          serviceName: "Head Massages",
          serviceTypesList: [
            {
              serviceTypeId: 18,
              serviceTypeName: "Head, neck and shoulder massage",
              serviceTypeImg: "/assets/images/head-neck.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "This is the ideal massage to provide invigoration to tired muscles with complete focus on the head, neck and shoulders. It provides new energy and well being with an increased supply of oxygen to the brain with special massage techniques.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1050" }],
            },
            {
              serviceTypeId: 19,
              serviceTypeName: "Hot oil head massage",
              serviceTypeImg: "/assets/images/hot-oil-head.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "An intensely relaxing hot oil massage that lulls you into a stupor with our special oil infused with sesame, peppermint, cedar wood and lavender that helps to remove aches and dull pains.",
              serviceHours: [{ serviceTime: "30 mins", servicePrice: "1150" }],
            },
            {
              serviceTypeId: 20,
              serviceTypeName: "The after - party massage",
              serviceTypeImg: "/assets/images/day-after-mass.jpg",
              serviceTherapist: "Physiotherapist",
              serviceTypeDesc:
                "A wonderful head massage that uses magic oil to relieve hangovers, headaches, migraines and even insomnia with its healing properties. A soothing foot soak completes the therapy.",
              serviceHours: [
                { serviceTime: "45 mins", servicePrice: "1600" },
                { serviceTime: "90 mins", servicePrice: "2550" },
              ],
            },
          ],
        },
        // {
        //   serviceId: 7,
        //   serviceName: "Manicure & Pedicure",
        //   serviceTypesList: [
        //     {
        //       serviceTypeId: 21,
        //       serviceTypeName: "SPA",
        //       serviceTypeImg: "/assets/images/spa-manicure.jpg",
        //       serviceTherapist: "Physiotherapist",
        //       serviceTypeDesc:
        //         "Enjoy our fine services and exclusive products.",
        //       serviceHours: [
        //         { serviceTime: "Manicure", servicePrice: "950" },
        //         { serviceTime: "Pedicure", servicePrice: "1050" },
        //         { serviceTime: "Manicure & Pedicure", servicePrice: "1800" },
        //       ],
        //     },
        //     {
        //       serviceTypeId: 22,
        //       serviceTypeName: "ROYAL",
        //       serviceTypeImg: "/assets/images/royal-manicure.jpg",
        //       serviceTherapist: "Physiotherapist",
        //       serviceTypeDesc:
        //         "With the added pleasure of a 30 minute relaxing aromatic foot massage.",
        //       serviceHours: [
        //         { serviceTime: "Manicure", servicePrice: "1950" },
        //         { serviceTime: "Pedicure", servicePrice: "2050" },
        //         { serviceTime: "Manicure & Pedicure", servicePrice: "2600" },
        //       ],
        //     },
        //   ],
        // },
      ],
    },
  ];
  return locationDetails;
}

export function article() {
  const articleList = [
    {
      id: "1",
      title:
        "HOW PILATES CAN HELP IN CHRONIC FATIGUE AND TACKLING MUSCLE IMBALANCES",
      img: "../../assets/images/article-1.jpg",
      publishedOn: "7:09 am IST on November 9, 2021",
      publishedBy: "Shobana Mahansaria",
      content: `<h3>A Journey of Shobana Mahansaria, Women Entrepreneur & Director of Corefit Plus</h3>
      <p>When Shobana Mahansaria was diagnosed with ADEM (Acute Disseminated Encephalomyelitis), doctors told her chances of survival were slim. But against all odds, she rebuilt her body back from the basics. She learnt quickly that it’s her mind that controls the body.</p>
      <p>Shobana says<p>
      <div class='quote'>"As scary as it was, I always tried to stay strong and positive, it can affect me wherever it wants. I can’t stop it, but it can’t take my attitude and it can’t take my faith. I was determined to stay strong and fight until I couldn’t do it anymore."</div>
      <p>Pilates has played a very important role in my recovery, which is why she recommends it to anyone who is struggling with rehabilitation of any kind. She had no idea her journey would result in a new organization, Corefit Plus,-- the venture she started whose mission is to aware and help people how Pilates can strengthen your core and motivate you for a healthy lifestyle.</p>
      <p>What happens when you’re suddenly told one day that you will never be able to walk again? In some cases, it can completely crush one’s self-esteem and motivation to live, but not in my case. Having an illness such as ADEM (Acute Disseminated Encephalomyelitis) can be life-changing for anyone, as it was for me in many ways. But, to be able to rebuild my body back from the basics, to walking with a stick at the age of 35, to constantly be told that I should feel happy with my condition, just because I was better off than many, was a constant struggle for me which was not only physical but mental as well. But I learnt quickly that it’s my mind that controls my body. I have always felt that no matter how we feel on the inside, it is very important to get dressed and show up for recovery. Pilates has played a very important role in my recovery, which is why I would recommend it to anyone who is struggling with the rehabilitation of any kind.</p>
      <p>Pilates is a systemised form of exercise, which focuses on core strengthening, which includes our abdominal and our back muscles. Pilates is now widely practised as an injury rehabilitation method on a global scale to restore imbalances in injured muscles. By evaluating a person’s state, strength, targets, and range of movement, a pilates personal training approach can be designed to facilitate injury rehabilitation of your muscles. It is a great tool to assist or even enhance a physiotherapy program when someone is recovering from an injury. By strengthening the deepest muscles of the core, optimising alignment, and creating correct movement patterns, we can also help to prevent the reaggravation of those injuries and the development of new ones. So, it is safe to say that it is a viable and effective method of movement re-education and an answer to physical limitation.</p>
      <p>Following are a few ways in which Pilates helped me in my journey towards rehabilitation:</p>
      <p>1. Pilates is good for chronic fatigue – As it is a low impact exercise designed to strengthen key muscle groups without causing full-body fatigue, I could do Pilates because my trainers could customise my exercise routine to a level that my body could tolerate, without too much of exertion. For me, Pilates was a great way to ease into fitness and build strength. Since I’m someone who has always tried to be active, given my physical limitations, it wasn’t very hard to start Pilates.</p>
      <p>2. Pilates is all about Mind and Body Connection – Pilates helped me become attuned to my body and taught me how to really control my movement through proper breathing, correct pelvic and spinal alignment. This is how it automatically helped in the reduction of my pain because while performing these exercises breathing played a very important role. Proper breathing not only allows you to execute every movement efficiently, but it also helps in the reduction and management of stress at the same time. When my oxygen levels are good, I feel better and breathing also simultaneously helped in the recovery of my muscles.</p>
      <p>3. How Pilates help in improving balance – Many injuries are caused by muscular imbalances within our bodies. Most of us move incorrectly in some way or the other, which puts too much pressure on some muscles and weakens the others, causing an imbalance. Pilates taught me to coordinate the muscles of my arms and legs with my core, which helped me improve my body balance. If one starts building their core muscles through a tailored and customised program, as I did, it can do wonders for one’s body. Doctors had told me I wouldn’t be able to walk, but slowly and gradually when I started strengthening my core muscles, it not only improved my posture but I could walk again.</p>
      <p>1. Pilates helps in the release of hormones such as endorphins, which makes one feel more energised. Endorphins are a “feel-good” compound that triggers the mind into a positive place. For me, Pilates is a form of exercise that reduced the feeling of depression and helped with my overall fatigue as well.</p>
      <p>2. Pilates is not only limited to a setting, it can be done anywhere, right from the comfort of your home to doing it in an aerobic zone (as long as we have the right trainer guiding us throughout), which is very effective in increasing the levels of oxygen in our bloodstream and as a result, it helps us feel more energised.</p>
      <p>Pilates is used by physiotherapists all over the world, to increase postural awareness and to alter faulty patterns of movement by addressing muscle imbalances. Through Pilates I have noticed greater control in my spinal stability, pelvic stability and mobility, enabling me to improve my posture, inner strength and balance leading to an overall healthy movement, minimising stress to my joints during everyday activities.</p>
      <p class="has-text-color" style="color:#056fe1"><em>The writer is the Director of Dolphin POD and Corefit Plus.</em></p>
      <p class="has-text-color" style="color:#f50606"><strong>Pilates is a systemised form of exercise, which focuses on core strengthening, which includes our abdominal and our back muscles. Pilates is now widely practised as an injury rehabilitation method on a global scale to restore imbalances in injured muscles. By evaluating a person’s state, strength, targets, and range of movement, a pilates personal training approach can be designed to facilitate injury rehabilitation of your muscles. It is a great tool to assist or even enhance a physiotherapy program when someone is recovering from an injury.</strong></p>`,
    },
  ];
  return articleList;
}
export function disclaimers() {
  const disclaimer = [
    {
      header: "Disclaimer",
      content: [
        `The selected time slot does not ensure confirmation of your appointment.`,
        `Kindly call the branch directly to confirm the available time slot.`,
      ],
    },
  ];
  return disclaimer;
}
