import React, { Component } from 'react'
import OverlayHead from '../OverlayHead/Overlayheader'
import AccordionSection from './AccordionSection'
import '../OverlayHead/Overlayheader.css'
import './OurLocation.css'
import '../Booking/Booking.css'
import { cityDetails } from '../../../global'
class OurLocation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      overlayHead: {
        title: 'OUR LOCATIONS',
        overlayDesc: '',
        imgClass: 'our-location'
      },
      cityDetails: cityDetails()
    }
  }

  render () {
    const { overlayHead, cityDetails } = this.state
    return (
      <div id='ourlocation-container'>
        <div className='wrapper'>
          <OverlayHead overlaycontent={overlayHead} />
          <div className='main'>
            {cityDetails.map((value, index) => {
              return <AccordionSection key={index} title={value.city + ' - ' + value.locationList.length + (value.locationList.length > 1 ? ' branches' : ' branch') + ' '} location={value.locationList} selectedLocation={this.props.selectedLocation.toLowerCase() === value.city.toLowerCase()} />
            })
            }
          </div>
        </div>
      </div>
    )
  }
}
export default OurLocation
