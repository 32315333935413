import React, { Component } from 'react'
import './CityAddress.css'

class CityAddress extends Component {
  render () {
    return (
      <div className='address-container'>
        {
          this.props.location.map((value, index) => {
            return (
              <div className='city-address'>
                <div className='address-block' key={index}>                  
                  {/*value.locationId === 13 || value.locationId === 14 ? (
                    <div>
                      <h6>{value.tempLocation}</h6>
                      <address >{value.address}</address>
                    </div>
                  ) : (*/}
                    <div>
                      <h6>{value.location}</h6>
                      <address>{value.address}</address>
                      <label className='contact-details lower-case'><span>Email: </span>{value.email}</label>
                      <label className='contact-details'><span>Phone: </span>{value.phoneNumber}</label>
                    </div>
                 {/* )} */}
                  {(value.mobileNumber) ? <label className='contact-details'><span>mobile: </span>{value.mobileNumber}</label> : ''}
                </div>
              </div>
            )
          })
        }
      </div>
    )
  }
}
export default CityAddress
