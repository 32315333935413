import React, { Component } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'

class OnlineGiftAmount extends Component {
  render () {
    return (
      <div className='row'>
        <div className='form-container'>
          <div className='left-container'>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Your Name*</label>
                <input type='text' name='name' data-testid = "name" onChange={this.props.handleInputChange} value={this.props.currentState.formfields.name} className={'form-field ' + this.props.currentState.formErrors.name} placeholder='Enter your Name' maxLength={50} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Mobile No*</label>
                <input type='text' name='mobileNo' onChange={this.props.handleInputChange} value={this.props.currentState.formfields.mobileNo} className={'form-field ' + this.props.currentState.formErrors.mobileNo} placeholder='Enter your Mobile No' maxLength={10} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Email Id*</label>
                <input type='text'name='email' onChange={this.props.handleInputChange} value={this.props.currentState.formfields.email} className={'form-field ' + this.props.currentState.formErrors.email} placeholder='Enter your Email Id' maxLength={50} />
              </div>
            </div>
          </div>
          <div className='right-container'>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>City*</label>
                <DropDownList
                  data={this.props.currentState.cityDetails}
                  dataItemKey='cityId'
                  textField='city'
                  name='city'
                  className={this.props.currentState.formErrors.city}
                  defaultValue={{ cityId: this.props.currentState.defaultCityId, city: this.props.currentState.defaultCity !== '' ? this.props.currentState.defaultCity : '-Select City-' }}
                  onChange={(e) => this.props.handleDropDownChange(e, 'city')}
                />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Location*</label>
                <DropDownList
                  data={this.props.currentState.locationDetails}
                  dataItemKey='locationId'
                  textField='location'
                  name='location'
                  itemRender={this.props.itemRender}
                  className={this.props.currentState.formErrors.location}
                  value={{ locationId: this.props.currentState.formfields.locationId, location: (this.props.currentState.formfields.location !== '') ? this.props.currentState.formfields.location : 'Select Location' }}
                  defaultValue={{ locationId: null, location: 'Select Location' }}
                  onChange={(e) => this.props.handleDropDownChange(e, 'location')}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='form-container recipientblock' >
          <div className='left-container'>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Recipient Name*</label>
                <input type='text' name='recipientName' value={this.props.currentState.formfields.recipientName} onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.recipientName} placeholder='Enter Recipient Name' maxLength={50} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Mobile No*</label>
                <input type='text'name='recipientMobile' value={this.props.currentState.formfields.recipientMobile} onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.recipientMobile} placeholder='Enter Recipient Mobile No' maxLength={10} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Email Id*</label>
                <input type='text' name='recipientEmail' value={this.props.currentState.formfields.recipientEmail} onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.recipientEmail} placeholder='Enter Recipient Email Id' maxLength={50} />
              </div>
            </div>
          </div>
          <div className='right-container'>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Price*</label>
                <span className='input-rupee-left'><input type='text' name='price' attr-name='amountprice' className={'form-field ' + this.props.currentState.formErrors.price + ' amount-price'} onChange={this.props.handleInputChange} value ={this.props.currentState.formfields.price} placeholder='500(Min-price)' maxLength={4} /></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default OnlineGiftAmount
