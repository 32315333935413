import React, { Component } from 'react'
import './Overlayheader.css'

class OverlayHead extends Component {
  render () {
    return (
      <div className='overlay-heading'>
        <div className='header-img-section'>
          <span className={'header-img ' + this.props.overlaycontent.imgClass} />
        </div>
        <div className='ic-divider' />
        <h4>{this.props.overlaycontent.title}</h4>
        <div><p>{this.props.overlaycontent.overlayDesc}</p></div>
      </div>
    )
  }
}
export default OverlayHead
