import React, { Component } from 'react'
import './Addon.css'
// import './AddonResponsive.css'

class Terms extends Component {
  render () {
    return (
      <div id='Addon' className='terms'>
        <div className='tc'>BODY TREATMENT PACKAGE OPTIONS</div>
        <div className='contact'>Please speak with our managers to get details on pricing & duration of the combinations below:</div>
        <div className='menu-blk'>
          <ul>
            {this.props.Addonplans.map((item, index) => {
              return <li key={index}>{item}</li>
            })
            }
          </ul>
        </div>
      </div>
    )
  }
}
export default Terms
