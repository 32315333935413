import React, { Component } from 'react'
// import Fade from 'react-reveal/Fade'

class PlanCard extends Component {
  constructor (props) {
    super(props)
    this.state = {
      // planList: [{
      //   planName: 'privilege',
      //   planPrice: '100k',
      //   menuList: ['Relaxation Massages', 'Spa Voucher', 'Free Yoga Classes', 'Services Worth 160K', 'Validity 18 month*']
      // }]
    }
  }

  render () {
    return (
      <div className='plan-outer'>
        {/* <Fade bottom delay={this.props.transition} duration={1300}> */}
        <div className='plan-inner'>
          <div className='plan-card-base' onClick={() => this.props.handleOverlay('Membership', this.props.plan)} >
            <div className='title-blk'>
              <p>{this.props.plan.planName}</p>
            </div>
            <div className='price-blk'>
              <p><span className='price-sym'>₹</span><span className='price'>{this.props.plan.planPrice}</span></p>
            </div>
            <div className='menu-blk'>
              <ul>
                {this.props.plan.menuList.map((item, index) => {
                  return <li key={index}>{item}</li>
                })
                }
              </ul>
            </div>
          </div>
        </div>
        {/* </Fade> */}
      </div>
    )
  }
}

export default PlanCard
