import React, { Component } from 'react'
import './BlogLanding.css'
import Header from '../Header/Header'
import Footer from '../Footer/Footer'
import { Redirect } from 'react-router-dom'
import { article } from '../../global.js'
class BlogLanding extends Component {
  constructor (props) {
    super(props)
    this.state = {
        blogList: article(),
       redirect: false,
       redirectTarget: ''
      
    }
    this.parseStringFromHtml = this.parseStringFromHtml.bind(this)
    this.goToArticle = this.goToArticle.bind(this)
    this.handleOverlay = this.handleOverlay.bind(this)
    this.handleCorefitOverlay = this.handleCorefitOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
  }

  handleCorefitOverlay () { this.setState({ componentName: 'Corefit' }) }
  handleOverlay (comp) { this.setState({ componentName: comp }) }
  hideOverlay () { this.setState({ componentName: '' }) }



  parseStringFromHtml (html) {
    var temporalDivElement = document.createElement('div')
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = html
    // Retrieve the text property of the element (cross-browser support)
    let str = temporalDivElement.textContent || temporalDivElement.innerText || ''
    // console.log('string', str)
    return str.substring(0, 320) + '...'
  }

  goToArticle (linkList) {
      var targetUrl = '/blog/article/' + linkList
      this.setState({ redirect: true, redirectTarget: targetUrl })
  }


  
    render () {
        if(this.state.redirect){
          return <Redirect to={this.state.redirectTarget} />
        }

    return (
        <div id='blogpage'>
        <Header handleOverlay={this.handleOverlay} />
        <div className='blog-list blog-desc'>
          {this.state.blogList.map((data, index) => {
            return (
              <div key={index} className='block'>
                <div className='service-block'>
                  <div className='content-block'>
                    <div className='service-header'>
                      <div className='service-name'>{data.title}</div>
                      <div className='service-date'>Published <b>{data.publishedOn}</b></div>
                      <div className='service-author'>By <b>{data.publishedBy}</b></div>
                    </div>
                    <div className='desc-block'>
                      <div className='service-desc'><p>{this.parseStringFromHtml(data.content)}</p></div>
                      <div className='book-btn' onClick={() => this.goToArticle(data.id)}>read</div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
          {this.state.blogList && this.state.blogList.length === 0 && <center><p>No Article published</p></center>}
        </div>
        <Footer />
      </div>
    )
  }
}

export default BlogLanding
