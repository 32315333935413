import React, { Component } from 'react'
// import '../OverlayHead/Overlayheader.css'
import './OurServices.css'
import Fade from 'react-reveal/Fade'
// import scrollToComponent from 'react-scroll-to-component'
import scrollToElement from 'scroll-to-element'

class AccordionSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      class: 'section',
      sectionHeight: ''
    }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick (e) {
    const targetElement = e.target.parentElement
    const targetElementHeight = (targetElement.classList.contains('sectionhead')) ? e.target.parentElement.nextSibling.querySelector('#serv-list').clientHeight : e.target.parentElement.querySelector('#serv-list').clientHeight
    if (this.state.open) {
      this.setState({
        open: false,
        class: 'section',
        sectionHeight: '0px'
      })
    } else {
      this.setState({
        open: true,
        class: 'section open',
        sectionHeight: targetElementHeight
        // sectionHeight: e.target.nextSibling.querySelector('#serv-list').clientHeight
      })
      // console.log('height', document.getElementById('serv-list').clientHeight)
    }
  }

  componentDidMount () {
    // console.log('accordion called')
    // console.log((this.props.default))
    if (this.props.expanding === undefined) {
      if (this.props.contents.serviceName.toLowerCase() === 'body massages') {
        this.setState({ open: true, class: 'section open' })
        // let selectedParent = document.querySelector('.open')
        setTimeout(() => {
          const selectedParent = document.querySelector('.open')
          this.setState({
            sectionHeight: selectedParent.querySelector('#serv-list').clientHeight
          })
        }, 300)
        // this.setState({ sectionHeight: selectedParent.querySelector('#serv-list').clientHeight })
        // scrollToComponent(this.refs.bodyMassages)
      }
    } else {
      const propsValue = parseInt(this.props.expanding)
      if ((propsValue === this.props.contents.serviceId)) {
        this.setState({ open: true, class: 'section open' })
        setTimeout(() => {
          const selectedParent = document.querySelector('.open')
          this.setState({
            sectionHeight: selectedParent.querySelector('#serv-list').clientHeight
          })
        }, 200)
        if ((propsValue === 7)) {
          setTimeout(() => { scrollToElement('#serv' + propsValue, { offset: -200 }) }, 600)
        } else if (propsValue === 6) { setTimeout(() => { scrollToElement('#serv' + propsValue, { offset: 100 }) }, 500) } else {
          setTimeout(() => { scrollToElement('#serv' + propsValue - 1) }, 500)
        }
      }
      // scrollToComponent(this.refs.bodymassages)
    }
  }

  render () {
    var divStyle = {
      height: this.state.sectionHeight
    }
    return (
      <div id={'serv' + this.props.contents.serviceId} className={this.state.class}>
        <div className='sectionhead' onClick={this.handleClick}>
          <div className='service-type'>{this.props.contents.serviceName}</div>
          <div className='serv-divider' />
          <div className='expand-icon' />
        </div>
        {/* {this.props.expanding.toLowerCase() === this.props.contents.serviceName.toLowerCase() ? this.setState({
          open: true,
          class: 'section open',
          sectionHeight: document.getElementById('serv-list').clientHeight
        }) : ''} */}
        {/* {this.setState({})} */}
        <div id='sectionbody' style={divStyle} className='sectionbody'>
          <div id='serv-list' className='serv-list'>
            {this.props.contents.serviceTypesList.map((list, index) => {
              return (
                <Fade bottom distance='30px' duration={1000}>
                  <div className='service-list' key={index}>
                    <div className='serv-img-block'><img className='serv-img' src={list.serviceTypeImg} alt='service-img' />
                    </div>
                    <div className='serv-content'>
                      <div className='serv-title'>{list.serviceTypeName}</div>
                      {/* <div className='serv-physio'>{list.serviceTherapist}</div> */}
                      <div className='serv-desc'>{list.serviceTypeDesc}</div>
                      {/* {
                      this.props.contents.serviceId === 1 ?
                      <div className='addon-block'>
                        <div className='addon-desc'>{list.AddondetailsDesc}</div>
                        <div className='Addondet' onClick={() => this.props.handleOverlay('Addon',list.Addonplans)}></div></div>
                        : */}
                      <div>
                        <div className='serv-cost'>
                          {list.serviceHours.map((key, index) => {
                            return (<div className='cost-time-space'>
                              {list.Addonplans
                                ? (
                                  <div>
                                    <div className='masques-time'>
                                      <div>{key.serviceTime} {(list.serviceHours).length - 1 !== index ? '' : ''} </div>

                                      <div class='tooltip'><img src='/assets/images/ic_info.svg' alt='Info icon' />
                                        <span class='tooltiptext'>Contact Spa outlet for details on combination options and pricing</span>
                                      </div>
                                    </div>
                                    <div className='addon-block'>
                                      {/* working with different lines */}
                                      <div className='addon-desc'>{list.AddondetailsDesc}</div>
                                      {/* <div className='Addondet' onClick={() => this.props.handleOverlay('Addon',list.Addonplans)}></div> */}
                                    </div>
                                  </div>
                                ) : (
                                  <span>
                                    <span className='rupee-sym'>₹</span>
                                    <span className='cost-rupee'>{key.servicePrice}</span>
                                    <span className='cost-slash'>/</span>
                                  </span>
                                )
                              }
                              {!list.Addonplans && <span>{key.serviceTime} {(list.serviceHours).length - 1 !== index ? '' : ''} </span>}
                            </div>
                            )
                          })}
                          {/* <span className='cost-rupee'>5000</span><span className='cost-slash'>/</span><span>70mins, </span><span className='rupee-sym'>₹</span><span className='cost-rupee'>5000</span><span className='cost-slash'>/</span><span className='ser-duration'>920mins</span> */}
                        </div>
                        {!list.Addonplans && 
                          <div className={`serv-btn-outer`}>
                              <div className='serv-btn-inner' onClick={() => this.props.handleOverlay('Booking', list.serviceTypeId, list.serviceTypeName, this.props.contents.serviceId, this.props.contents.serviceName)}>book now</div>
                            {/* {list.isDisable
                              ? <div className={`serv-btn-inner ${list.isDisable ? 'disable' : ''}`}>book now</div>
                              : <div className='serv-btn-inner' onClick={() => this.props.handleOverlay('Booking', list.serviceTypeId, list.serviceTypeName, this.props.contents.serviceId, this.props.contents.serviceName)}>book now</div>
                            } */}
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </Fade>
              )
            })}
          </div>
        </div>
      </div>

    )
  }
}
export default AccordionSection
