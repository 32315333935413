import React, { Component } from "react";
import { Link } from "react-router-dom";
import OverlayLayer from "../OverlayComponents/Overlay/Overlay";
import "./Footer.css";
import "./FooterResponsive.css";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: "",
    };
    this.hideOverlay = this.hideOverlay.bind(this);
    this.handleOverlay = this.handleOverlay.bind(this);
    this.pagescroll = this.pagescroll.bind(this);
  }
  externalLinks(link) {
    const win = window.open(link, "_blank");
    if (win) win.focus(); // opens the link in new tab
  }

  handleOverlay() {
    this.setState({ componentName: "Internship" });
  }

  hideOverlay() {
    this.setState({ componentName: "" });
  }

  pagescroll() {
    window.scrollTo(0, 0);
  }

  render() {
    const componentOpen =
      this.state.componentName === "Internship" ? (
        <OverlayLayer
          typeOfComponent="Internship"
          hideOverlay={this.hideOverlay}
          size="lg"
        />
      ) : (
        ""
      );

    return (
      <div id="footer" data-testid="footercomponent">
        {componentOpen}
        {/* <div className='footer-row' onClick={() => this.handleOverlay()}>
          <div className='footer-left'>
            <Link to='/'>
              <img className='aroma-logo' src='../../assets/images/ic_aromo_logo.svg' alt='AromaThai_logo' />
            </Link></div>
          <div className='footer-right'>
            <ul className='menu-row-block'>
              <li><Link to='/'>Home</Link></li>
              <li><Link to='/aboutus'>About Us</Link></li>
              <li><Link to='/ourservices'>Our Services</Link></li>
              <li><Link to='/giftvoucher'>Gift Voucher</Link></li>
              <li><Link to='/giftvoucher'>Memberships</Link></li>
            </ul>
          </div>
        </div> */}
        {/* Copyrights */}
        <div className="copy-right-row">
          <div className="footer-left">Copyrights 2025-26 @aromathai</div>
          <div>
            <ul className="terms-policy">
              <li className="terms-services">
                <Link
                  to="/termsandservice"
                  data-testid="testterms"
                  onClick={() => this.pagescroll()}
                >
                  Terms &amp; Services
                </Link>
              </li>
              <li className="terms-services">
                <Link
                  to="/privacypolicy"
                  data-testid="testprivacy"
                  onClick={() => this.pagescroll()}
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-right">
            <ul className="social-media-block">
              <li
                className="instagram-logo"
                data-testid="testinsta"
                onClick={() =>
                  this.externalLinks("https://www.instagram.com/aromathaispa/")
                }
              />
              <li
                className="fb-logo"
                data-testid="testfb"
                onClick={() =>
                  this.externalLinks(
                    "https://www.facebook.com/AromathaiDaySpa/"
                  )
                }
              />
            </ul>
          </div>
          <div
            className="up-arow-icon"
            data-testid="testscroll"
            onClick={() => this.pagescroll()}
          />
        </div>
      </div>
    );
  }
}

export default Footer;
