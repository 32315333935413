import React, { Component } from 'react'
// component
import Header from '../Header/Header'
import BannerImage from '../Reusable/BannerImage/BannerImage'
import QuickMenu from '../Reusable/QuickMenu/QuickMenu'
import OverlayLayer from '../OverlayComponents/Overlay/Overlay'
import Footer from '../../components/Footer/Footer'
import { getDefaultCity } from '../../global.js'
// css
import './AboutUs.css'

class AboutUs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bannerItem: {
        imageUrl: '/assets/images/about_banner.jpg',
        title: 'about us',
        description: <p>AromaThai Opened its Doors in 2008.<br/> With over a decade of fine services, AromaThai is the first <br/> Thai Chain of Spas to bring original Thai Massages & classic <br/> Remedies to India. Come and relieve yourself from the <br/> daily stresses and aches in our cocoon.</p>
      }
    }
    this.handleOverlay = this.handleOverlay.bind(this)
    this.handleCorefitOverlay = this.handleCorefitOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
  }

  handleCorefitOverlay () { this.setState({ componentName: 'Corefit' }) }

  handleOverlay (comp) { this.setState({ componentName: comp }) }

  hideOverlay () { this.setState({ componentName: '' }) }

  componentDidMount () {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome
    // if (window.localStorage.getItem('cityName') === null) {
    //   this.handleOverlay('Places')
    // }
    if (getDefaultCity() === null) {
      this.handleOverlay('Places')
    }
  }

  render () {
    let { bannerItem } = this.state
    const componentOpen = this.state.componentName === 'Membership'
      ? <OverlayLayer typeOfComponent='Membership' hideOverlay={this.hideOverlay} size='lg' />
      : this.state.componentName === 'Places'
        ? <OverlayLayer typeOfComponent='Places' hideOverlay={this.hideOverlay} size='lg' />
        : this.state.componentName === 'Corefit'
          ? <OverlayLayer typeOfComponent='Corefit' hideOverlay={this.hideOverlay} />
          : ''

    return (
      <div>
        {componentOpen}
        <Header handleCorefitOverlay={this.handleCorefitOverlay} />
        <div id='aboutus'>
          <BannerImage banner={bannerItem} social />
          <QuickMenu handleOverlay={() => this.handleOverlay('Membership')} />
        </div>
        <Footer />
      </div>
    )
  }
}

export default AboutUs
