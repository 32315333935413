
import React, { Component } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'

class OnlineGiftService extends Component {
  constructor (props) {
    super(props)
    this.state = {
      numberofPerson: [
        {
          'id': 1,
          'value': 1
        },
        {
          'id': 2,
          'value': 2
        },
        {
          'id': 3,
          'value': 3
        },
        {
          'id': 4,
          'value': 4
        }
      ]
    }
  }

  handleServiceNameList (data, cityName, locationId) {
    var newData = data
    var removeList = [1, 2 ,3]
    if(cityName === 'Delhi-NCR' && locationId === 10) {
      newData = data.filter((item) => removeList.indexOf(item.serviceId) === -1);
    }
    return newData
  }



  componentDidMount () {
    // this.setState({ defaultCity: window.localStorage.getItem('cityName') })
  }
  render () {
    // console.log('service====================', this.props.currentState)
    return (
      <div className='row'>
        <div className='form-container'>
          <div className='left-container'>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Your Name*</label>
                <input type='text' name='name' data-testid = "name" onChange={this.props.handleInputChange} value={this.props.currentState.formfields.name} className={'form-field ' + this.props.currentState.formErrors.name} placeholder='Enter your Name' maxLength={50} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Mobile No*</label>
                <input type='text' name='mobileNo' onChange={this.props.handleInputChange} value={this.props.currentState.formfields.mobileNo} className={'form-field ' + this.props.currentState.formErrors.mobileNo} placeholder='Enter your Mobile No' maxLength={10} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Email Id*</label>
                <input type='text' name='email' onChange={this.props.handleInputChange} value={this.props.currentState.formfields.email} className={'form-field ' + this.props.currentState.formErrors.email} placeholder='Enter your Email Id' maxLength={50} />
              </div>
            </div>
          </div>
          <div className='right-container'>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>City*</label>
                <DropDownList
                  data={this.props.currentState.cityDetails}
                  dataItemKey='cityId'
                  textField='city'
                  name='city'
                  // className={this.props.currentState.formErrors.city}
                  defaultValue={{ cityId: this.props.currentState.defaultCityId, city: this.props.currentState.defaultCity !== '' ? this.props.currentState.defaultCity : '-Select City-' }}
                  onChange={(e) => this.props.handleDropDownChange(e, 'city')}
                />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Location*</label>
                <DropDownList
                  data={this.props.currentState.locationDetails}
                  dataItemKey='locationId'
                  textField='location'
                  itemRender={this.props.itemRender}
                  name='location'
                  className={this.props.currentState.formErrors.location}
                  value={{ locationId: this.props.currentState.formfields.locationId, location: (this.props.currentState.formfields.location !== '') ? this.props.currentState.formfields.location : 'Select Location' }}
                  defaultValue={{ locationId: null, location: 'Select Location' }}
                  onChange={(e) => this.props.handleDropDownChange(e, 'location')}
                />
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className='form-container recipientblock' >
          <div className='left-container'>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Recipient Name*</label>
                <input type='text' name='recipientName' value={this.props.currentState.formfields.recipientName} onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.recipientName} placeholder='Enter Recipient Name' maxLength={50} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Mobile No*</label>
                <input type='text' name='recipientMobile' value={this.props.currentState.formfields.recipientMobile} onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.recipientMobile} placeholder='Enter Recipient Mobile No' maxLength={10} />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Email Id*</label>
                <input type='text' name='recipientEmail' value={this.props.currentState.formfields.recipientEmail} onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.recipientEmail} placeholder='Enter Recipient Email Id' maxLength={50} />
              </div>
            </div>
          </div>
          <div className='right-container'>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Service*</label>
                <DropDownList
                  // disabled={this.props.currentState.formfields.city == ''}
                  // data={this.props.currentState.services}
                  data={this.handleServiceNameList(this.props.currentState.services, this.props.currentState.formfields.city, this.props.currentState.formfields.locationId)}
                  dataItemKey='serviceId'
                  textField='serviceName'
                  name='serviceName'
                  className={'service_details ' + this.props.currentState.formErrors.serviceName}
                  value={{ serviceId: 0, serviceName: (this.props.currentState.formfieldService.serviceName !== '') ? this.props.currentState.formfieldService.serviceName : 'Select Service' }}
                  defaultValue={{ serviceId: 0, serviceName: (this.props.currentState.formfieldService.serviceName !== '') ? this.props.currentState.formfieldService.serviceName : 'Select Service' }}
                  // defaultValue={{ serviceId: null, serviceName: '-Select Service-' }}
                  onChange={(e) => this.props.handleDropDownChange(e, 'serviceName')}
                />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Service Type*</label>
                <DropDownList
                  disabled={this.props.currentState.formfieldService.serviceName === ''}
                  data={this.props.currentState.serviceTypes}
                  dataItemKey='serviceTypeId'
                  textField='serviceTypeName'
                  name='serviceTypeName'
                  className={'service_details ' + this.props.currentState.formErrors.serviceTypeName}
                  // defaultValue={{ serviceTypeId: null, serviceTypeName: '-Select Service Type-' }}
                  value={{ serviceId: 0, serviceTypeName: (this.props.currentState.formfieldService.serviceTypeName !== '') ? this.props.currentState.formfieldService.serviceTypeName : 'Select Service Type' }}
                  defaultValue={{ serviceId: 0, serviceTypeName: (this.props.currentState.formfieldService.serviceTypeName !== '') ? this.props.currentState.formfieldService.serviceTypeName : 'Select Service Type' }}
                  onChange={(e) => this.props.handleDropDownChange(e, 'serviceType')}
                />
              </div>
            </div>

            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Service Time*</label>
                <DropDownList
                  disabled={this.props.currentState.formfieldService.serviceTypeName === ''}
                  data={this.props.currentState.serviceTimes}
                  dataItemKey='serviceTime'
                  textField='serviceTime'
                  name='serviceTime'
                  className={'service_details ' + this.props.currentState.formErrors.serviceTime}
                  value={{ Id: 0, serviceTime: (this.props.currentState.formfieldService.serviceTime !== '') ? this.props.currentState.formfieldService.serviceTime : 'Select Service Time' }}
                  defaultValue={{ Id: 0, serviceTime: (this.props.currentState.formfieldService.serviceTime !== '') ? this.props.currentState.formfieldService.serviceTime : 'Select Service Time' }}
                  // defaultValue={{ id: null, serviceTime: '-Select Service Time-' }}
                  onChange={(e) => this.props.handleDropDownChange(e, 'serviceTime')}
                />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Price</label>
                <div className=' form-field form-field-price'>₹ {this.props.currentState.formfields.price === '' ? '0' : this.props.currentState.formfields.price }</div>
                {/* <input type='text' name='price' className={'form-field ' + this.props.currentState.formErrors.price} readOnly value={this.props.currentState.formfields.price} placeholder='₹ 0' maxLength={9} /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default OnlineGiftService