import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Header from '../Header/Header'
import BannerCarousel from './BannerCarousel/BannerCarousel'
import CitySelection from '../Reusable/CitySelection/CitySelection'
import ReviewCarousel from './ReviewCarousel/ReviewCarousel'
import OverlayLayer from '../OverlayComponents/Overlay/Overlay'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'
// import QuickMenu from '../Reusable/QuickMenu/QuickMenu'
import Fade from 'react-reveal/Fade'
import { fetchData } from '../../API/ApiRequest.js'
// import Zoom from 'react-reveal/Zoom'
import { removeSpaces, validateEmail, getDefaultCity, cityDetails, firebase } from '../../global.js'
import Footer from '../../components/Footer/Footer'
import ReactGA from 'react-ga';

// css
import './Home.css'
import './HomeResponsive.css'
import Franchise from '../OverlayComponents/Franchise/Franchise'
import ReCAPTCHA from 'react-google-recaptcha'
const db = firebase.firestore()
class Home extends Component {
  constructor (props) {
    super(props)
    this.state = {
      componentName: '',
      selectedLocation: null,
      isRedirect: false,
      carouselStatus: 'false',
      mailMessage: '',
      mailStatus: 'pre-suc-response',
      disablebtn: 'outer-btn disablebtn',
      errMsg: '',
      locationDetailsArr: cityDetails(),
      locationList: [],
      defaultCityId: '',
      formFields: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        comment: '',
        bookingCity: getDefaultCity(),
        bookingLocation: '',
        bookingLocationId: ''
      },
      formErrors: {
        firstName: '',
        lastName: '',
        phone: '',
        email: '',
        comment: '',
        bookingCity: '',
        bookingLocation: ''
      },
      token:null
    }
    this.itemRender = (li, itemProps) => {
      let itemChildren
      // console.log('sdkvb<d',itemProps.dataItem['disabled'])
      // console.log('sdkvb<d',itemProps)
      if (itemProps.dataItem.disabled) {
        itemChildren = <span style={{ color: '#ccc', cursor: 'auto' }}>{li.props.children}</span>
      } else {
        itemChildren = <span>{li.props.children}</span>
      }

      return React.cloneElement(li, li.props, itemChildren)
    }

    this.captchaRef = React.createRef();

    this.handleOverlay = this.handleOverlay.bind(this)
    this.handleCorefitOverlay = this.handleCorefitOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    this.goToOurServices = this.goToOurServices.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDropDownChange = this.handleDropDownChange.bind(this)
    this.onChange = this.onChange.bind(this);
    // this.getCookie = this.getCookie.bind(this)
  }

  handleCorefitOverlay () { this.setState({ componentName: 'Corefit' }) }

  componentDidMount () {
    
      ReactGA.initialize('UA-192544306-3')
      ReactGA.pageview(window.location.pathname + window.location.search);

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome
    const { locationDetailsArr } = this.state
    const { bookingCity } = this.state.formFields
    // if (window.localStorage.getItem('cityName') === null) {
    //   this.handleOverlay('Places')
    // }
    // console.log('ds', getDefaultCity())
    window.localStorage.setItem('pathName', '/')
    if (getDefaultCity() === null) {
      this.handleOverlay('Places')
      this.setState({ defaultCity: getDefaultCity() })
    }
    if (bookingCity !== '') { // validating cookie if expired
      const data = locationDetailsArr.filter((values) => values.city.toLowerCase() === bookingCity.toLowerCase())
      // to get location list based on city selection
      this.setState({ defaultCityId: data[0].cityId, locationList: data[0].locationList })
    }
    // var name = 'cityName=';
    // var ca = document.cookie.split(';')
    // console.log('cookie', c)
    // for (var i = 0; i < ca.length; i++) {
    //   var c = ca[i]
    //   while (c.charAt(0) == ' ') {
    //     c = c.substring(1)
    //   }
    //   if (c.indexOf(name) == 0) {
    //     console.log('sd',c)
    //     return c.substring(name.length, c.length)
    //   }
    // }
    // return '';

    // console.log('cookie', c)
    // var ca = document.cookie.split(';');
  }

  handleOverlay (comp, other) {
    console.log('test', comp, other)
    const compName = comp.split(',')
    console.log('comp', compName[0])
    this.setState({ componentName: compName[0], memToggle: compName[1], selectedLocation: other, carouselStatus: 'false' })
  }

  hideOverlay () {
    this.setState({ componentName: '', selectedLocation: null })
    this.setState({ carouselStatus: 'true' })
    // hideOverlay2()
  }

  goToOurServices (obj) {
    this.setState({ isRedirect: true })
    this.props.history.push(obj)
    window.localStorage.setItem('pathName', 'AU')
  }

  handleEmptyFormCheck () {
    let valid = true
    const formFields = this.state.formFields
    const errorFields = this.state.formErrors
    Object.keys(formFields).forEach(key => {
      const value = formFields[key]
      if (value === null || value.length <= 0) {
        if (Object.keys(this.state.formErrors).indexOf(key) >= 0) {
          errorFields[key] = 'error'
          valid = false
          this.setState({ errMsg: '*Please enter mandatory field' })
        }
      }
    })
    this.setState({ formErrors: errorFields })

    return valid
  }

  handleInputChange (e) {
    const { name, value } = e.target
    const formErrors = this.state.formErrors
    if (name === 'firstName') formErrors.firstName = ''
    else if (name === 'lastName') formErrors.lastName = ''
    else if (name === 'phone') formErrors.phone = ''
    else if (name === 'email') formErrors.email = ''
    else if (name === 'comment') formErrors.comment = ''

    this.setState({ formErrors, errMsg: '' })
    const formfieldsState = this.state.formFields
    if (name === 'phone') {
      if (!isNaN(value)) { formfieldsState[e.target.name] = e.target.value }
    } else {
      formfieldsState[e.target.name] = e.target.value
    }
    this.setState({ formfieldsState })
  }
  // handleInputChange (event) {
  //   const { name, value } = event.target
  //   this.setState({ [name]: value })
  //   if (event.target.name === 'firstName') this.refs.firstName.style.border = '1px solid #E3E3E3'
  //   if (event.target.name === 'lastName') this.refs.lastName.style.border = '1px solid #E3E3E3'
  //   if (event.target.name === 'email') this.refs.email.style.border = '1px solid #E3E3E3'
  //   if (event.target.name === 'comment') this.refs.comment.style.border = '1px solid #E3E3E3'
  //   this.setState({ errMsg: '' })
  // }

  handleDropDownChange (event, objType) {
    const { locationDetailsArr } = this.state
    const formError = this.state.formErrors
    formError[event.target.name] = ''
    this.setState({ formErrors: formError })
    if (objType === 'bookingCity') {
      const data = locationDetailsArr.filter((values) => values.city === event.target.value.city)
      this.setState({ locationList: data[0].locationList })

      // Remove the drop down value when change the city drop down

      const formFields = this.state.formFields
      formFields.bookingCity = event.target.value.city
      formFields.bookingLocation = ''
      this.setState({ formFields: formFields })
    } else if (objType === 'bookingLocation') {
      const data = this.state.locationList.filter((values) => values.location === event.target.value.location)
      const formFields = this.state.formFields
      
      /*if (data[0].locationId === 13 || data[0].locationId === 14) {
        formFields.bookingLocation = ''
        formFields.bookingLocationId = ''
      } else {
        formFields.bookingLocation = data[0].location
        formFields.bookingLocationId = data[0].locationId
      }*/

      formFields.bookingLocation = data[0].location
      formFields.bookingLocationId = data[0].locationId

      this.setState({ formFields: formFields, branchEmail: data[0].email })
    }
  }

  onChange(value) {
    this.setState({token:value, disablebtn: 'outer-btn'});
  }

  handleSubmit () {
    const { firstName, lastName, email, phone, bookingCity, bookingLocation, comment } = this.state.formFields
    const formErrors = this.state.formErrors
    this.setState({ disablebtn: 'outer-btn disablebtn' })
    if (this.handleEmptyFormCheck()) {
      // fields validation
      if (!(/^[a-zA-Z ]+$/.test(firstName))) {
        formErrors.name = 'error'
        this.setState({ errMsg: 'Please enter valid first name' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }
      if (!(/^[a-zA-Z ]+$/.test(lastName))) {
        formErrors.name = 'error'
        this.setState({ errMsg: 'Please enter valid last name' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }
      if (!validateEmail(email)) {
        formErrors.email = 'error'
        this.setState({ errMsg: 'Invalid email! Please enter valid email' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }
      if (!(/^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/.test(phone))) {
        formErrors.phone = 'error'
        this.setState({ errMsg: 'Invalid mobile number! Please enter valid mobile number' })
        this.setState({ disablebtn: 'outer-btn disablebtn' })
        this.captchaRef.current.reset();
        return
      }

      this.setState({ errMsg: '' })
      const contactDetails = {
        Name: firstName + ' ' + lastName,
        'Mobile No.': phone,
        'Email Id': email,
        City: bookingCity,
        Location: bookingLocation,
        Comment: comment,
        // 'BranchEmail': this.state.branchEmail
        BranchEmail: email
      }
      this.setState({ mailMessage: 'Please wait...' })
      const reqRouteRecipient = '/ATContactUsAcknowledgementToHO'
      fetchData({contactDetails: contactDetails, token: this.state.token}, reqRouteRecipient).then(response => {
        if (response.message === 'SUCCESS') {
          db.collection('contacts').add({
            first_name: firstName,
            last_name: lastName,
            mobile_no: phone,
            email_id: email,
            city: bookingCity,
            location: bookingLocation,
            comment: comment,
            mailType: 'Contact us',
            objMailData: contactDetails
            // branch_email: email //not required
          }).then((docRef) => {
            console.log('contacts successfully added')
          }).catch((error) => {
            console.error('Error adding document: ', error)
          })
          this.setState({ mailStatus: 'suc-response' })
          this.setState({ disablebtn: 'outer-btn', mailMessage: 'Thank you for contact us! We will get back to you soon.' })
          setTimeout(function () { this.setState({ mailMessage: '' }) }.bind(this), 2500)
          const formFields = this.state.formFields
          formFields.email = formFields.phone = formFields.firstName = formFields.lastName = formFields.bookingLocation = formFields.comment = ''
          this.setState({ branchEmail: '', formFields: formFields })
        } else console.log('Contact us mail Failure')
      })
      this.captchaRef.current.reset();
    } else {
      this.setState({ disablebtn: 'outer-btn disablebtn' })
      this.captchaRef.current.reset();
    }
  }

  render () {
    // To open video overlay on launching home Page
    // if (window.isLaunched === 'false') {
    //   window.isLaunched = 'true'
    //   this.setState({ componentName: 'VideoPlayer' })
    // }
    const { firstName, lastName, email, phone, comment, bookingCity, bookingLocation, bookingLocationId } = this.state.formFields
    const { locationDetailsArr, locationList } = this.state
    const componentOpen = this.state.componentName === 'Booking'
      ? <OverlayLayer typeOfComponent='Booking' hideOverlay={this.hideOverlay} size='lg' />
      : this.state.componentName === 'Membership'
        ? <OverlayLayer typeOfComponent='Membership' hideOverlay={this.hideOverlay} memToggle={this.state.memToggle} size='lg' />
        : this.state.componentName === 'OurLocation'
          ? <OverlayLayer typeOfComponent='OurLocation' hideOverlay={this.hideOverlay} size='lg' selectedLocation={this.state.selectedLocation} />
          : this.state.componentName === 'Places'
            ? <OverlayLayer typeOfComponent='Places' hideOverlay={this.hideOverlay} size='lg' />
            : this.state.componentName === 'Corefit'
              ? <OverlayLayer typeOfComponent='Corefit' hideOverlay={this.hideOverlay} />
              : this.state.componentName === 'Franchise'
                ? <OverlayLayer typeOfComponent='Franchise' hideOverlay={this.hideOverlay} />
                : this.state.componentName === 'VideoPlayer'
                  ? <OverlayLayer typeOfComponent='VideoPlayer' hideOverlay={this.hideOverlay} />
                    : ''

    return (
      <div>
        {componentOpen}
        <Header handleCorefitOverlay={this.handleCorefitOverlay} />
        <div id='home'>
          {/* Carousel component */}
          <BannerCarousel handleOverlay={this.handleOverlay} enableCarousel={this.state.carouselStatus} />
          {/* Elegence section */}
          <div className='ele-block'>
            <div className='flower-left' />
            <div className='flower-right' />
            <div className='ele-cup-block'>
              <div className='ic-cup' />
              {/* <Zoom delay={300} duration={2000}> */}
              <div className='ic-divider' />
              {/* </Zoom> */}
              <Fade bottom delay={400} distance='30px'>
                <div className='ic-title'>WHERE WELLNESS DWELLS</div>
              </Fade>
              <Fade bottom delay={600} distance='30px'>
                <div className='ic-desc'>Thai Massage Heritage by “Wat Pho"&nbsp;<span className='ban-divider-img' />&nbsp;Soothing &amp; Aromatic Environment&nbsp;<span className='ban-divider-img' />&nbsp;First Thai Chain Of Spas To Open In India</div>
              </Fade>
            </div>

          </div>
          {/* Services section */}
          <div className='service-block'>
            {/* <Fade bottom> */}
            <div className='service-col'>
              <div className='ic-ser-1' />
              <div className='service-title'>Body Treatments</div>
              <div className='service-desc'>Indulge in Body Massages, Scrubs and Masques. Enjoy health benefits, improved skin elasticity &amp; softness along with revived body luster.</div>
            </div>
            {/* </Fade> */}
            {/* <Fade bottom delay={200}> */}
            <div className='service-col'>
              <div className='ic-ser-2' />
              <div className='service-title'>Face/Head Massages</div>
              <div className='service-desc'>Stimulate circulation and rejuvenation of your facial skin. Surrender to our traditional head massages for mind clarity and stress release via herbal oils and skilled  therapists.</div>
            </div>
            {/* </Fade> */}
            {/* <Fade bottom delay={400}> */}
            <div className='service-col'>
              <div className='ic-ser-3' />
              <div className='service-title'>Foot Therapies</div>
              <div className='service-desc'>India’s unmatched Thai foot massages using unique sequences of therapeutic steps, magically instilling balance in your entire body and bounce to your feet.</div>
            </div>
            {/* </Fade> */}
            {/* <Fade bottom delay={600}> */}
            <div className='service-col'>
              <div className='ic-ser-4' />
              <div className='service-title'>Manicure &amp; Pedicure</div>
              <div className='service-desc'>Our services go beyond regular grooming of your nails. Give new life to your nails & skin and enjoy a relaxing foot massage.</div>
            </div>
            {/* </Fade> */}
          </div>
          {/* Enjoy the difference section */}
          <div className='diff-block'>
            <div className='ic-spray' />
            {/* <Zoom delay={300} duration={2000}> */}
            <div className='ic-divider' />
            {/* </Zoom> */}
            <Fade bottom delay={200} distance='30px'>
              <div className='ic-title'>Enjoy The Difference</div>
            </Fade>
            <Fade bottom delay={400} distance='30px'>
              <div className='ic-desc'>At AromaThai we take pride in our pioneering, originality and quality control. Our difference lead us to over a decade of successfully serving numerous clients everyday.</div>
            </Fade>
            <div className='ser-types-block'>
              <div className='ser-type-col'>
                <div className='ic-ser-5' />
                {/* <Fade bottom distance='30px'> */}
                <div className='service-title'>Thai Hospitality</div>
                <div className='diff-service-desc'>Hospitality is in the DNA of AromaThai. From the caring and gracious greetings to the mastery of our original massages &amp; treatments in a soothing elegant ambience, it is truly Thai.</div>
                {/* </Fade> */}
              </div>
              <div className='ser-type-col'>
                <div className='ic-ser-6' />
                {/* <Fade bottom delay={200} distance='30px'> */}
                <div className='service-title'>Therapists</div>
                <div className='diff-service-desc'>Our therapists are trained to perfect their massage techniques originated at Thailand’s “Wat Pho”. Their skills, know-how and service-mindedness is a treat for the body and soul.</div>
                {/* </Fade> */}
              </div>
              <div className='ser-type-col'>
                <div className='ic-ser-7' />
                {/* <Fade bottom delay={400} distance='30px'> */}
                <div className='service-title'>Health  Benefits</div>
                <div className='diff-service-desc'>We take pride in our therapeutic services that provide health benefits, ensuring your experience ends in the realm of wellness each and every time.</div>
                {/* </Fade> */}
              </div>
            </div>
          </div>
          {/* Serives image block */}
          <div className='ser-img-block'>
            <div className='ser-img-box' onClick={() => this.goToOurServices('ourservices/2')}>
              <div className='ser-container'>
                <img className='img-ser' src='/assets/images/img_body_service_small.jpg' alt='' />
                {/* <Fade bottom distance='30px'> */}
                <div className='ser-content-box'>
                  <div className='ser-flex-center'>
                    <div className='ic-ser-body' />
                    <div className='ic-ser-title'>Body Treatments</div>
                    <div className='ic-ser-divider treat-expand-line' />
                  </div>
                </div>
                {/* </Fade> */}
              </div>
            </div>
            <div className='ser-img-box' onClick={() => this.goToOurServices('ourservices/5')}>
              <div className='ser-container'>
                <img className='img-ser' src='/assets/images/img_foot_service_small.jpg' alt='' />
                {/* <Fade bottom delay={200} distance='30px'> */}
                <div className='ser-content-box'>
                  <div className='ser-flex-center'>
                    <div className='ic-ser-foot' />
                    <div className='ic-ser-title'>Foot Therapies</div>
                    <div className='ic-ser-divider treat-expand-line' />
                  </div>
                </div>
                {/* </Fade> */}
              </div>
            </div>
            <div className='ser-img-box' onClick={() => this.goToOurServices('ourservices/6')}>
              <div className='ser-container'>
                <img className='img-ser' src='/assets/images/img_head_service_small.jpg' alt='' />
                {/* <Fade bottom delay={400} distance='30px'> */}
                <div className='ser-content-box'>
                  <div className='ser-flex-center'>
                    <div className='ic-ser-head' />
                    <div className='ic-ser-title'>Head &amp; Face</div>
                    <div className='ic-ser-divider treat-expand-line' />
                  </div>
                </div>
                {/* </Fade> */}
              </div>
            </div>
            <div className='ser-img-box' onClick={() => this.goToOurServices('ourservices/7')}>
              <div className='ser-container'>
                <img className='img-ser' src='/assets/images/img_manicur_service_small.jpg' alt='' />
                {/* <Fade bottom delay={600} distance='30px'> */}
                <div className='ser-content-box'>
                  <div className='ser-flex-center'>
                    <div className='ic-ser-manicure' />
                    <div className='ic-ser-title'>Manicure &amp; Pedicure</div>
                    <div className='ic-ser-divider treat-expand-line' />
                  </div>
                </div>
                {/* </Fade> */}
              </div>
            </div>
          </div>
          {/* Our locations */}
          <div className='our-loc-blok'>
            <div className='ic-location' />
            {/* <Zoom delay={300} duration={2000}> */}
            <div className='ic-divider' />
            {/* </Zoom> */}
            <Fade bottom delay={200} distance='30px'>
              <div className='ic-title'>Our Locations</div>
            </Fade>
            <Fade bottom delay={400} distance='30px'>
              <div className='ic-desc'>AromaThai centers are conveniently located in North, East, West and South India with multiple branches in some regions.</div>
            </Fade>
            <div className='city-blk'>
              <CitySelection handleOverlay={this.handleOverlay} />
            </div>
          </div>
          {/* Contact form */}
          <div className='contact-block'>
            <div className='back-leaf-left' />
            <div className='back-leaf-right' />
            <div className='contact-flex-box'>
              <div className='ic-contact' />
              {/* <Zoom delay={300} duration={2000}> */}
              <div className='ic-divider' />
              {/* </Zoom> */}
              {/* <Fade bottom> */}
              <div className='ic-title'>Contact Form</div>
              {/* </Fade> */}

              <div className='con-fileds-block'>
                <div className='con-flex-row'>
                  <div className='first-name-box'>
                    <input type='text' ref='firstName' autoComplete='off' className={'first-name form-field ' + this.state.formErrors.firstName} name='firstName' placeholder='First Name*' value={removeSpaces(firstName)} onChange={this.handleInputChange} maxLength='30' />
                  </div>
                  <div className='last-name-box'>
                    <input type='text' ref='lastName' autoComplete='off' className={'last-name form-field ' + this.state.formErrors.lastName} name='lastName' placeholder='Last Name*' value={removeSpaces(lastName)} onChange={this.handleInputChange} maxLength='30' />
                  </div>
                </div>
                <div className='con-flex-row'>
                  <div className='email-box'>
                    <input type='text' ref='email' autoComplete='off' className={'email form-field ' + this.state.formErrors.email} name='email' placeholder='Email*' value={removeSpaces(email)} onChange={this.handleInputChange} maxLength='50' />
                  </div>
                  <div className='phone-box'>
                    <input type='text' ref='phone' autoComplete='off' className={'phone form-field ' + this.state.formErrors.phone} name='phone' placeholder='Phone*' value={phone} onChange={this.handleInputChange} maxLength='10' />
                  </div>
                </div>
                <div className='con-flex-row'>
                  <div className='input-container city'>
                    <div className='input-groups input-dropdown'>
                      <DropDownList
                        data={locationDetailsArr}
                        dataItemKey='cityId'
                        textField='city'
                        name='City'
                        id='cityId'
                        className={this.state.formErrors.bookingCity}
                        defaultValue={{ cityId: this.state.defaultCityId, city: (bookingCity !== '') ? bookingCity : '-Select City-' }}
                        onChange={(e) => this.handleDropDownChange(e, 'bookingCity')}
                      />
                    </div>
                  </div>
                  <div className='input-container'>
                    <div className='input-groups input-dropdown'>
                      <DropDownList
                      //  data={locationDetailsArr}
                        data={locationList}
                        // value={this.state.locationList}
                        dataItemKey='locationId'
                        textField='location'
                        name='bookingLocation'
                        itemRender={this.itemRender}
                        className={this.state.formErrors.bookingLocation}
                        value={{ locationId: bookingLocationId, location: (bookingLocation !== '') ? bookingLocation : 'Select Location*' }}
                        defaultValue={{ locationId: 0, location: (bookingLocation !== '') ? bookingLocation : 'Select Location*' }}
                        onChange={(e) => this.handleDropDownChange(e, 'bookingLocation')}
                      />
                    </div>
                  </div>
                </div>
                <div className='con-comment-box'>
                  <textarea type='text' ref='comment' autoComplete='off' className={'comment ' + this.state.formErrors.comment} rows='5' name='comment' placeholder='Type your comment*' value={comment} onChange={this.handleInputChange} maxLength='500' />
                </div>
                <div className='cls-err-msg'>{this.state.errMsg}&nbsp;</div>
                <ReCAPTCHA
                  sitekey="6LfAZK0lAAAAAO5kStWR98uVO5X3eFWedbiyYDUn"
                  onChange={this.onChange}
                  ref={this.captchaRef}
                />
                <div className={this.state.mailStatus}>{this.state.mailMessage}&nbsp;</div>
                <div className={this.state.disablebtn} onClick={this.handleSubmit}><div className='submit-btn-inner'>Submit</div></div>
                <div className='cls-franchise'>If franchising is right for you, <span onClick={() => this.handleOverlay('Franchise')}>Click here</span> to find out about the opportunity.</div>
              </div>
            </div>
          </div>
          {/* Review component */}
          <ReviewCarousel />
        </div>
        <Footer />
      </div>
    )
  }
}

export default withRouter(Home)
