import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Fade from 'react-reveal/Fade'
import QuickMenu from '../../Reusable/QuickMenu/QuickMenu'
import './BannerCarousel.css'
import './BannerCarouselResponsive.css'
class BannerCarousel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      intervalId: '',
      componentName: '',
      slideIndex: 1,
      circleOne: true,
      circleTwo: false,
      circleThree: false,
      btnClicked: false,
      bannerArr: [
        { imagePath: 'assets/images/home_banner.jpg', imageTitle: 'SPA & WELLNESS CENTER', imageDesc: 'Using authentic Thai mastery of massage and classic wellness therapies to better the health of your body and mind. Your experience will transcend rejuvenation.', btnText: 'book now', btnNav: 'Booking' },
        { imagePath: 'assets/images/gift_banner.jpg', imageTitle: 'Gift voucher', imageDesc: 'A gift of wellness is valued by everyone. It is the perfect way to express gratitude, love and care.The vouchers are specially customized as per your gifting requirement and come along with a personalized message.', btnText: 'buy now', btnNav: 'Membership,gift' },
        { imagePath: 'assets/images/membership_banner.jpg', imageTitle: 'membership', imageDesc: 'We invite you to escape the chaos of daily life and rejuvenate in our private retreat.', btnText: 'buy now', btnNav: 'Membership' }
        // { imagePath: 'assets/images/about_banner.jpg', imageTitle: 'core fit', imageDesc: 'The Original Thai spa. Using traditional thai techniques and remedies to help you to better health of body and mind. Lorem ipsum dolor sit amet, consectetur.', btnText: 'book now', btnNav: 'Booking' }
      ],
      currBannerArr: [
        { imagePath: 'assets/images/home_banner.jpg', imageTitle: 'SPA & WELLNESS CENTER', imageDesc: 'Using authentic Thai mastery of massage and classic wellness therapies to better the health of your body and mind. Your experience will transcend rejuvenation.', btnText: 'book now', btnNav: 'Booking' }
      ],
      isSlideChange: false
    }
    this.showSlides = this.showSlides.bind(this)
    this.currentSlide = this.currentSlide.bind(this)
    this.handleOverlay = this.handleOverlay.bind(this)
    // this.hideOverlay2 = this.hideOverlay2.bind(this)
    this.handleMouseEnter = this.handleMouseEnter.bind(this)
    this.handleMouseLeave = this.handleMouseLeave.bind(this)
    this.clearInterval = this.clearInterval.bind(this)
    this.startInterval = this.startInterval.bind(this)
  }

  clearInterval () {
    clearInterval(this.state.intervalId)
  }

  startInterval () {
    this.setState({ intervalId: this.state.intervalId = setInterval(this.showSlides, 10500) })
    // console.log(this.state.intervalId)
  }

  handleCarouselCirlce (obj) {
    this.refs['image-' + this.state.slideIndex].classList.remove('opaque')
    this.refs['image-' + obj].classList.add('opaque')
    this.clearInterval()
    this.currentSlide(obj)
    this.startInterval()
  }

  showSlides () {
    const { slideIndex } = this.state
    if (slideIndex === 1) this.currentSlide(2)
    else if (slideIndex === 2) this.currentSlide(3)
    else if (slideIndex === 3) this.currentSlide(1)
    // else if (slideIndex === 4) this.currentSlide(1)
  }

  currentSlide (obj) {
    var currBannerArray
    this.setState({ isSlideChange: false })
    currBannerArray = this.state.bannerArr.slice(obj - 1, obj)
    this.setState({ currBannerArr: currBannerArray })
    this.setState({ isSlideChange: true })
    // to empty all circles
    if (this.refs['image-' + this.state.slideIndex]) this.refs['image-' + this.state.slideIndex].classList.remove('opaque')
    if (this.refs['image-' + obj]) this.refs['image-' + obj].classList.add('opaque')

    this.setState({ circleOne: false, circleTwo: false, circleThree: false })
    if (obj === 1) this.setState({ slideIndex: obj, circleOne: true })
    else if (obj === 2) this.setState({ slideIndex: obj, circleTwo: true })
    else if (obj === 3) this.setState({ slideIndex: obj, circleThree: true })
  }

  handleOverlay (comp) { this.setState({ componentName: comp }) }

  componentDidMount () {
    this.setState({ isSlideChange: true })
    this.startInterval()
  }

  handleMouseEnter () { this.clearInterval() }

  handleMouseLeave () { this.startInterval() }

  handleBtnclick (overlaNmae) {
    this.handleMouseEnter()
    this.setState({ btnClicked: true })
    this.props.handleOverlay(overlaNmae)
  }

  render () {
    const { bannerArr, currBannerArr, isSlideChange, circleOne, circleTwo, circleThree } = this.state

    return (
      <div id='carousel'>
        <div className='carousel-block'>
          {/* Banner carousel block */}
          {currBannerArr.map((list, index) => {
            return (
              <div className='carousel-box fade' key={index}>
                <div className='carousel-flex'>
                  <div className='image-container'>
                    <img src={bannerArr[0].imagePath} className='opaque' alt='Banner_logo' ref='image-1' />
                    <img src={bannerArr[1].imagePath} alt='Banner_logo' ref='image-2' />
                    <img src={bannerArr[2].imagePath} alt='Banner_logo' ref='image-3' />
                  </div>
                  <div className='desc-block'>
                    <Fade when={isSlideChange} duration={1000} exit right distance='60px' delay={400}>
                      <div className='desc-title'>{list.imageTitle}</div>
                    </Fade>
                    <Fade when={isSlideChange} duration={1000} exit right distance='60px' delay={600}>
                      <div className='desc-content'>{list.imageDesc}</div>
                    </Fade>
                    <Fade when={isSlideChange} duration={1000} exit right distance='60px' delay={900}>
                      <div className='book-now-btn-outer' onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}><div className='book-now-btn-inner' onClick={() => this.props.handleOverlay(list.btnNav)}>{list.btnText}</div></div>
                    </Fade>
                  </div>
                </div>
              </div>
            )
          })}
          {/* Banner circles block */}
          <div className='circle-row'>
            <div className='circle-outer' data-testid='circle1' onClick={() => this.handleCarouselCirlce(1)}><div className={circleOne ? 'circle active' : 'circle'} onClick={() => this.handleCarouselCirlce(1)} /></div>
            <div className='circle-outer' data-testid='circle2' onClick={() => this.handleCarouselCirlce(2)}><div className={circleTwo ? 'circle active' : 'circle'} onClick={() => this.handleCarouselCirlce(2)} /></div>
            <div className='circle-outer' data-testid='circle3' onClick={() => this.handleCarouselCirlce(3)}><div className={circleThree ? 'circle active' : 'circle'} onClick={() => this.handleCarouselCirlce(3)} /></div>
          </div>
        </div>
        <QuickMenu handleOverlay={() => this.props.handleOverlay('Membership')} />
      </div>
    )
  }
}

export default withRouter(BannerCarousel)
