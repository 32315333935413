import React, { Component } from 'react'
// component
import Header from '../Header/Header'
import QuickMenu from '../Reusable/QuickMenu/QuickMenu'
import OverlayLayer from '../OverlayComponents/Overlay/Overlay'
import AccordionSection from './AccordionSection'
import './OurServices.css'
import './OurServicesResponsive.css'
import scrollToComponent from 'react-scroll-to-component'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'
import Footer from '../../components/Footer/Footer'
import { cityDetails, getDefaultCity } from '../../global.js'

// import Fade from 'react-reveal/Fade'
// import Flip from 'react-reveal/Flip'
// import Zoom from 'react-reveal/Zoom'
// import CityAddress fsrom "../OverlayComponents/CityAddress/CityAddress";

class OurServices extends Component {
  constructor (props) {
    super(props)
    this.state = {
      bannerItem: {
        imageUrl: '/assets/images/home_banner.jpg',
        title: 'our services',
        description: 'The need to relieve oneself from daily stress and strain is fast becoming a norm in society now.  It is the burden of the endless pace of the day that brings fatigue with its deadlines, chaos and pressures. We spotted this need based ooportunity for relaxation way back in 2008 as we started the brand Aromathai.  A spa that promised an experience that would leave you rejuvenated and relaxed with ancient Thai techniques, trained staff and inviting premises that cocooned you from the world outside.'
      },
      cityDetails: cityDetails(), // assigning city details from JSON
      location: '',
      commingSoon: '',
      // servLocation: [],
      // locationDetails: [],
      defaultCity: getDefaultCity(), // assigning default city from local storage
      defaultCityId: '',
      serviceOpen: this.props.match.params.serviceName, // receiveing props value from params
      slectedLocation: [],
      Addonplans: ''
      // isServiceOpen: 0,
      // slectedLocationType: []
    }
    this.handleOverlay = this.handleOverlay.bind(this)
    this.handleCorefitOverlay = this.handleCorefitOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
    this.filterValues = this.filterValues.bind(this)
    // this.setLocation = this.setLocation.bind(this)
    // this.handleClick = this.handleClick.bind(this)
  }

  handleCorefitOverlay () { this.setState({ componentName: 'Corefit' }) }

  handleOverlay (comp, serviceTypeid, serviceTypename, serviceId, serviceName) {
    switch (comp) {
      // case 'Places':this.setState({ componentName: comp })
      //   break
      // case 'Memberships':this.setState({ componentName: comp })
      //   break
      case 'Booking':this.setState({ componentName: comp })
        // list['city'] = this.state.defaultCity
        var sellist = []
        sellist['city'] = this.state.location
        sellist['serviceTypeId'] = serviceTypeid
        sellist['serviceTypeName'] = serviceTypename
        sellist['serviceId'] = serviceId
        sellist['serviceName'] = serviceName
        // list['location'] = this.state.servLocation
        // list['serviceName'] = serviceName
        console.log('list', sellist)
        this.setState({ services: sellist })
        break
      case 'Addon':this.setState({ componentName: comp })
        this.setState({ Addonplans: serviceTypeid })
        break
      default:this.setState({ componentName: comp })
        break
        // console.log('list',list)
    }
  }

  hideOverlay () {
    this.setState({ componentName: '' })
    if (this.state.defaultCity === null) {
      this.setState({ defaultCity: getDefaultCity() })
      const location = this.state.cityDetails.filter((values) => values.city === getDefaultCity())
      this.filterValues(location)
    }
  }

  handleDropDownChange (event, reference) {
    if (reference === 'city') {
      const data = this.state.cityDetails.filter((values) =>
        values.cityId === event.target.value.cityId)
      this.setState({ locationDetails: data[0].locationList })
      this.setState({ location: data[0].city })
      this.filterValues(data)
      // this.setState({ serviceOpen: 'body massage' })
    }
    // const data = this.state.city.filter((values) =>
    //   values.cityId === event.target.value.cityId)
    // this.filterValues(data)
    // this.setState({ defaultCity: event.target.value.city })
    // } else if (reference === 'location') {
    //   // console.log(event.target.value.isServiceOpen)
    //   this.setState({ servLocation: event.target.value,
    //     slectedLocation: event.target.value.serviceLists,
    //     isServiceOpen: event.target.value.isServiceOpen,
    //     slectedLocationType: event.target.value.serviceTypeList })
    //   // console.log('is sopn',this.state.isServiceOpen)
    // }
  }
  filterValues (data) {
    this.setState({ commingSoon: '' })
    this.setState({
      slectedLocation: '',
      location: data[0].city
      // isServiceOpen: data[0].locationDetailsList[0].isServiceOpen,
      // slectedLocationType: data[0].locationDetailsList[0].serviceTypeList,
      // servLocation: data[0].locationDetailsList[0]
    })
    setTimeout(() => {
      this.setState({
        slectedLocation: data[0].serviceList
      })
    }, 10)
    if (data[0].serviceList.length === 0) { this.setState({ commingSoon: 'Coming Soon...' }) }
  }
  // handleDropdownState(event){
  //   return this.state.formfields[event.target.name] = event.target.value[event.target.name]
  // }
  componentDidMount () {
    // console.log('propsname', this.props.match.params.serviceName)
    window.localStorage.setItem('pathName', 'OS')
    if (this.props.match.params.serviceName === undefined) {
      document.body.scrollTop = 0 // For Safari
      document.documentElement.scrollTop = 0 // For Chrome
    } else {
      // console.log('propsname3', this.props.match.params.serviceName)
      setTimeout(() => {
        scrollToComponent(this.refs.serviceslist, {
          offset: 1000,
          align: 'top',
          duration: 1000
        })
      }, 300)
    }
    if (getDefaultCity() === null) {
      this.handleOverlay('Places')
      this.setState({ defaultCity: getDefaultCity() })
    } else {
      const location = this.state.cityDetails.filter((values) => values.city === this.state.defaultCity)
      this.setState({ defaultCityId: location[0].cityId })
      this.filterValues(location)
      // console.log('is sopn', location[0].serviceList)
    }
  }

  render () {
    const componentOpen = this.state.componentName === 'Booking'
      ? <OverlayLayer typeOfComponent='Booking' serviceTypes={this.state.services} hideOverlay={this.hideOverlay} size='lg' />
      : this.state.componentName === 'Membership'
        ? <OverlayLayer typeOfComponent='Membership' hideOverlay={this.hideOverlay} size='lg' />
        : this.state.componentName === 'Places'
          ? <OverlayLayer typeOfComponent='Places' hideOverlay={this.hideOverlay} size='lg' />
          : this.state.componentName === 'Addon'
            ? <OverlayLayer typeOfComponent='Addon' Addonplans={this.state.Addonplans} hideOverlay={this.hideOverlay} size='lg' />
            : this.state.componentName === 'Corefit'
              ? <OverlayLayer typeOfComponent='Corefit' hideOverlay={this.hideOverlay} />
              : ''

    return (
      <div>
        {componentOpen}
        <Header handleCorefitOverlay={this.handleCorefitOverlay} />
        {/* <BannerImage banner={bannerItem} social /> */}
        <div id='ourservices'>
          <div className='banner-block'>
            {/* <div className='serv-col1'>
              <div className='row1'>
                <div className='service-content'>
                  <img className='banner-img' src='/assets/images/ic_banner-body scrubs.jpg' alt='service-banner' />
                  <div className='body-scrup'>BODY <br /> SCRUBS</div>
                </div>
              </div>
              <div className='row2'>
                <div className='col1'>
                  <div className='service-content'>
                    <img className='banner-img' src='/assets/images/ic_banner-foot therapies.jpg' alt='service-banner' />
                    <div className='foot-therap'>FOOT <br /> THERAPIES</div>
                  </div>
                </div>
                <div className='col2'>
                  <div className='service-content'>
                    <img className='banner-img' src='/assets/images/ic_banner-head & face massages.jpg' alt='service-banner' />
                    <div className='head-face'>HEAD & FACE <br /> MASSAGES</div>
                  </div>
                </div>

              </div>
            </div> */}
            {/* <div className='serv-col2'>
              <div className='col1'>
                <div className='service-content'>
                  <img className='banner-img' src='/assets/images/ic_banner-body massages.jpg' alt='service-banner' />
                  <div className='body-mass'>BODY <br /> MASSAGES</div>
                </div>
              </div>
              <div className='col2'>
                <div className='row1'>
                  <div className='service-content'>
                    <img className='banner-img' src='/assets/images/ic_banner-manicure & pedicure.jpg' alt='service-banner' />
                    <div className='mani-pedi'>MANICURE & PEDICURE</div>
                  </div>
                </div>
                <div className='row2'>
                  <div className='service-content'>
                    <img className='banner-img' src='/assets/images/ic_banner-body masques.jpg' alt='service-banner' />
                    <div className='body-masques'>BODY <br />MASQUES</div>
                  </div>
                </div>

              </div>
            </div> */}
            <img className='banner-img' src='/assets/images/img_ban_services.jpg' alt='service-banner' />
            <QuickMenu handleOverlay={() => this.handleOverlay('Membership')} />

          </div>

          {/* <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field'>Location*</label>
                <DropDownList
                  data={this.state.locationDetails}
                  dataItemKey='locationId'
                  textField='location'
                  name='location'
                  // value={this.state.servLocation}
                  defaultValue={{ locationId: null, location: '-Select Location-' }}
                  // onChange={(e) => this.handleDropDownChange(e, 'location')}
                />
              </div>
            </div> */}
          <div className='service-leaf'><div className='leaf-right' /></div>
          {/* </div> */}
          <div className='service-leaf'><div className='leaf-right' /></div>

          <div ref='serviceslist' className='services-list'>
            <div className='loc-select'>
              <div className='input-container'>
                <div className='input-groups input-dropdown'>
                  <label className='field'>City*</label>
                  <DropDownList
                    data={this.state.cityDetails}
                    dataItemKey='cityId'
                    textField='city'
                    name='city'
                    // value={{ cityId: 1, city:this.state.defaultCity}}
                    defaultValue={{ cityId: this.state.defaultCityId, city: this.state.defaultCity }}
                    onChange={(e) => this.handleDropDownChange(e, 'city')}
                  />
                </div>
              </div>
            </div>
            <div className='listofservice'>
              {/* {console.log('serv', this.state.slectedLocationType)} */}
              {/* {this.state.slectedLocation.map((list, index) => {
                return (
                  <AccordionSection handleOverlay={this.handleOverlay} expanding={this.state.serviceOpen} contents={list} />
                )
              })} */}

              { this.state.slectedLocation.length === 0
                ? <div className='coming-soon'>{this.state.commingSoon}</div>
                : this.state.slectedLocation.map((list, index) => {
                  return (
                    <AccordionSection handleOverlay={this.handleOverlay} expanding={this.state.serviceOpen} contents={list} />
                  )
                })
              }
            </div>
          </div>
          <div className='leaf-left' />
        </div>
        <Footer />
      </div>
    )
  }
}

export default OurServices
