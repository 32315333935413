import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import OverlayHead from '../OverlayHead/Overlayheader'
import ToggleButton from '../ToggleButton/ToggleButton'
import OnlineGiftService from './Service/Service'
import OnlineGiftAmount from './Amount/Amount'
import { firebase, validateEmail, cityDetails, ccavMerchantId, ccavRedirectURL, ccavCancelURL, getDefaultCity } from '../../../global'

import './OnlineGift.css'
// import { fetchData } from '../../../API/ApiRequest.js'

// Check default form state is empty
// const formValid = (formErrors) => {
//   let valid = true
//   console.log(formErrors)
//   Object.values(formErrors).forEach(val => {
//     val.length >= 0 && (valid = false)
//   })
//   return valid
// }

class OnlineGift extends Component {
  constructor (props) {
    super(props)
    this.state = {
      toggleIsChecked: false,
      name: '',
      mobileNo: '',
      email: '',
      city: '',
      location: '',
      recipientName: '',
      recipientMobile: '',
      giftCardName: '',
      recipientEmail: '',
      disablebtn: 'outer-btn',
      defaultCity: getDefaultCity() === null ? '' : getDefaultCity().toLowerCase(),
      defaultCityId: '',
      mailMessage: '',
      mailStatus: '',
      formfields: {
        name: '',
        mobileNo: '',
        email: '',
        city: getDefaultCity() === null ? '' : getDefaultCity().toLowerCase(),
        location: '',
        locationId: '',
        price: '',
        recipientName: '',
        recipientMobile: '',
        recipientEmail: ''
      },
      formfieldService: {
        serviceName: '',
        serviceTypeName: '',
        serviceTime: ''
      },
      giftCards: {
        bdWishes: '',
        HappyAn: '',
        BestWises: ''
      },
      formErrors: {
        name: '',
        mobileNo: '',
        email: '',
        city: '',
        location: '',
        price: '',
        recipientName: '',
        recipientMobile: '',
        recipientEmail: '',
        serviceName: '',
        serviceTypeName: '',
        serviceTime: ''
      },
      cityDetails: cityDetails(),
      locationDetails: [],
      servicesData: this.props.servicesData, // Service details from JSon
      services: [], // Service list based on location
      serviceTypes: [], // Service type list based on service selection
      serviceTimes: [], // Service time based on service Types
      serviceTypeIds: [], // service type id's based on location
      numberofPerson: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      overlayHead: {
        title: 'ONLINE GIFT',
        overlayDesc: <p>Choose to gift a service or an amount for services, it is that convenient. For special offers on purchase of more than 10 gift vouchers and corporate rewards & recognition association, please email us at <i>info@aromathai.net</i></p>,
        imgClass: 'online-gift'
      },
      branchEmail: ''
    }
    this.itemRender = (li, itemProps) => {
      let itemChildren
      // console.log('sdkvb<d',itemProps.dataItem['disabled'])
      // console.log('sdkvb<d',itemProps)
      if (itemProps.dataItem.disabled) {
        itemChildren = <span style={{ color: '#ccc', cursor: 'auto' }}>{li.props.children}</span>
      } else {
        itemChildren = <span>{li.props.children}</span>
      }

      return React.cloneElement(li, li.props, itemChildren)
    }
    this.toggleClick = this.toggleClick.bind(this)
    this.handleDropDownChange = this.handleDropDownChange.bind(this)
    this.handleOnChangeInput = this.handleOnChangeInput.bind(this)
    this.onFormSubmit = this.onFormSubmit.bind(this) // Form Validation default
    this.defaultValidationError = this.defaultValidationError.bind(this)// Check dropdown value selected or not
    this.cleanInputErrors = this.cleanInputErrors.bind(this) // Clean the input area using when we toggle
    this.cleanInputValues = this.cleanInputValues.bind(this) // Clean the input values
    this.giftCardSelection = this.giftCardSelection.bind(this)
  }

  // Onsubmit form
  onFormSubmit (e) {
    e.preventDefault()
    this.setState({ disablebtn: 'outer-btn disablebtn' })
    if (this.defaultValidationError()) {
      // var { name, mobileNo, email, recipientName, recipientMobile, recipientEmail, price } = this.state.formfields
      var { name, email, recipientName, recipientEmail, price } = this.state.formfields
      const formErrors = this.state.formErrors
      // this.defaultValidationError()

      if (!(/^([a-zA-Z ])+$/.test(name))) {
        formErrors.name = 'error'
        this.setState({ errMsg: '*Please enter valid name', disablebtn: 'outer-btn' })
        return
      }
      // if (!(/^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/.test(mobileNo))) {
      //   formErrors.mobileNo = 'error'
      //   this.setState({ errMsg: '*Please enter valid mobile number', disablebtn: 'outer-btn' })
      //   return
      // }
      if (!(validateEmail(email))) {
        formErrors.email = 'error'
        this.setState({ errMsg: '*Please enter valid email address', disablebtn: 'outer-btn' })
        return
      }

      if (!(/^[a-zA-Z ]+$/.test(recipientName))) {
        formErrors.recipientName = 'error'
        this.setState({ errMsg: '*Please enter valid recipient name', disablebtn: 'outer-btn' })
        return
      }
      // if (!(/^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/.test(recipientMobile))) {
      //   formErrors.recipientMobile = 'error'
      //   this.setState({ errMsg: '*Please enter valid receipient mobile number', disablebtn: 'outer-btn' })
      //   return
      // }
      if (!(validateEmail(recipientEmail))) {
        formErrors.recipientEmail = 'error'
        this.setState({ errMsg: '*Please enter valid recipient email', disablebtn: 'outer-btn' })
      }
      if ((this.state.toggleIsChecked) && (price < 500)) {
        formErrors.price = 'error'
        this.setState({ errMsg: '*Please enter valid price', disablebtn: 'outer-btn' })
      }

      this.setState({ formErrors })
    } else { this.setState({ disablebtn: 'outer-btn' }) }
    // Form Submition
    let valid = true
    Object.values(this.state.formErrors).forEach(val => {
      if (val.length > 0) {
        valid = false
        this.setState({ disablebtn: 'outer-btn' })
      }
    })

    if (valid) {
      const { name, mobileNo, email, city, location, price, recipientName, recipientMobile, recipientEmail } = this.state.formfields
      const { serviceName, serviceTypeName, serviceTime } = this.state.formfieldService
      this.setState({ errMsg: '' })
      const templateData = {
        templateData: {
          to: recipientEmail,
          subject: 'AromaThai Gift Voucher',
          descriptionUser: 'Thank you for purchasing a gift voucher for your loved one. An Email with the details has been sent to them. <br/><br/>  Don’t mind giving them a ring! ',
          descriptionRecipient: 'Congratulations! You have received a gift voucher from ' + name + ' who truly cares about you. Enjoy your gifted service and remember, a healthy body holds a healthy mind.'
        }
      }

      const currday = new Date()
      const orderId = currday.getTime().toString()

      const bookingDetails = {
        'Order Id': orderId,
        'Recipient Name': recipientName,
        'Recipient Mobile No.': recipientMobile,
        'Recipient Email Id': recipientEmail,
        Name: name,
        'Mobile No.': mobileNo,
        'Email Id': email,
        City: city,
        Location: location,
        BranchEmail: this.state.branchEmail,
        // 'BranchEmail': 'radhakrishnan.y@tringapps.com',
        GiftCardType: this.state.giftCardName === '' ? '-' : this.state.giftCardName,
        Price: '₹' + price
      }
      const templateObject = (this.state.toggleIsChecked) ? { ...bookingDetails, ...templateData } : { ...bookingDetails, ...this.state.formfieldService, ...templateData }
      // console.log('templateObject', templateObject)

      window.localStorage.setItem('orderDetail', JSON.stringify(templateObject))
      window.localStorage.setItem('orderDetailType', 'GIFT')
      var db = firebase.firestore()
      db.collection('payment').doc(orderId).set({
        order_id: orderId,
        sender_name: name,
        sender_mobile_no: mobileNo,
        sender_email_id: email,
        recipient_name: recipientName,
        recipient_mobile_no: recipientMobile,
        recipient_email_id: recipientEmail,
        city: city,
        location: location,
        branch_email: this.state.branchEmail,
        gift_card_type: this.state.giftCardName === '' ? '-' : this.state.giftCardName,
        price: '₹' + price,
        service_name: serviceName,
        service_type_name: serviceTypeName,
        service_time: serviceTime,
        mailType: 'GIFT',
        objMailData: templateObject
        // reference_no: responseData.reference_no ? responseData.reference_no : '',
        // order_id: objMailData['Order Id'] && objMailData['Order Id'],
        // order_no: responseData.order_no && responseData.order_no,
        // order_date_time: responseData.order_date_time && responseData.order_date_time,
        // price: objMailData['Price'] && objMailData['Price'],
        // recipient_name: objMailData['Recipient Name'] && objMailData['Recipient Name'],
        // recipient_mobile_no: objMailData['Recipient Mobile No.'] && objMailData['Recipient Mobile No.'],
        // recipient_email_id: objMailData['Recipient Email Id'] && objMailData['Recipient Email Id'],
        // name: objMailData['Name'] && objMailData['Name'],
        // mobile_no: objMailData['Mobile No.'] && objMailData['Mobile No.'],
        // email_id: objMailData['Email Id'] && objMailData['Email Id'],
        // city: objMailData['City'] && objMailData['City'],
        // location: objMailData['Location'] && objMailData['Location'],
        // branch_email: objMailData['BranchEmail'] && objMailData['BranchEmail'],
        // gift_card_type: objMailData['GiftCardType'] && objMailData['GiftCardType'],
        // service_name: objMailData['serviceName'] && objMailData['serviceName'],
        // service_type_name: objMailData['serviceTypeName'] && objMailData['serviceTypeName'],
        // service_time: objMailData['serviceTime'] && objMailData['serviceTime']
      })
        .then((docRef) => {
          const orderAmount = parseFloat(price)
          const orderKey = 'merchant_id=' + ccavMerchantId + '&order_id=' + orderId + '&currency=INR&amount=' + orderAmount + '&redirect_url=' + ccavRedirectURL + '&cancel_url=' + ccavCancelURL + '&language=EN'
          this.props.history.push('/ccavenue', { orderKey: orderKey })
        })
        .catch((error) => {
          console.error('Error adding document: ', error)
        })

      // to make querystring to get ccavenue payment page.
      // let orderAmount = parseFloat(price)
      // let orderKey = 'merchant_id=' + ccavMerchantId + '&order_id=' + orderId + '&currency=INR&amount=' + orderAmount + '&redirect_url=' + ccavRedirectURL + '&cancel_url=' + ccavCancelURL + '&language=EN'
      // this.props.history.push('/ccavenue', { orderKey: orderKey })
    }
  }

  // Check the error input
  defaultValidationError () {
    let valid = true
    const ErrorObj = this.state.formErrors
    Object.keys(this.state.formfields).forEach(key => {
      const value = this.state.formfields[key]
      if (value.length <= 0) {
        ErrorObj[key] = 'error'
        valid = false
        this.setState({ errMsg: '*Please enter mandatory fields' })
      }
    })
    // When toggled gift section
    if (!this.state.toggleIsChecked) {
      Object.keys(this.state.formfieldService).forEach(key => {
        const formfieldServiceValue = this.state.formfieldService[key]
        if (formfieldServiceValue.length <= 0) {
          ErrorObj[key] = 'error'
          valid = false
          this.setState({ errMsg: '*Please enter mandatory fields' })
        }
      })
    }
    this.setState({ ErrorObj })
    return valid
  }

  // Clean the input errors
  cleanInputErrors () {
    const ErrorObj = this.state.formErrors
    const FieldObj = this.state.formfields
    Object.keys(this.state.formfields).forEach(key => {
      const value = this.state.formfields[key]
      if (value.length <= 0) {
        ErrorObj[key] = ''
      }
    })
    Object.keys(this.state.formfieldService).forEach(key => {
      const formfieldServiceValue = this.state.formfieldService[key]
      if (formfieldServiceValue.length <= 0) {
        ErrorObj[key] = ''
      }
    })
    Object.keys(this.state.formfieldService).forEach(key => {
      const formfieldServiceValue = this.state.formfieldService[key]
      if (formfieldServiceValue.length <= 0) {
        ErrorObj[key] = ''
      }
    })
    Object.keys(this.state.formfields).forEach(key => {
      // console.log('formfieldsobject', key)
      // let value = this.state.formfields[key]
      if (key !== 'city') { FieldObj[key] = '' }
    })

    this.setState({ formfields: FieldObj })
    this.setState({ ErrorObj })
  }

  // Clean Values when toggle button
  cleanInputValues () {
    const formfields = this.state.formfields
    Object.keys(this.state.formfields).forEach(key => {
      // let value = this.state.formfields[key]
      formfields[key] = ''
    })
    this.setState({ formfields })
  }

  // Toggle Event Selection
  toggleClick (event) {
    this.setState({ toggleIsChecked: !this.state.toggleIsChecked })
    this.cleanInputErrors()
    this.setState({ errMsg: '' })
    this.cleanInputErrors()
  }

  // Input change event
  handleOnChangeInput (e) {
    // console.log('click', e)
    const { name, value } = e.target
    const formErrors = this.state.formErrors
    switch (name) {
      case 'name':
        formErrors.name = ''
        // this.setState({ name: value })
        break
      case 'mobileNo':
        formErrors.mobileNo = ''
        // this.setState({ mobileNo: value })
        break
      case 'email':
        // this.setState({ email: value })
        formErrors.email = ''
        break
      case 'recipientName':
        // this.setState({ recipientName: value })
        formErrors.recipientName = ''
        break
      case 'recipientMobile':
        // this.setState({ recipientMobile: value })
        formErrors.recipientMobile = ''
        break
      case 'recipientEmail':
        // this.setState({ recipientEmail: value })
        formErrors.recipientEmail = ''
        break
      case 'price' :
        if (this.state.toggleIsChecked) { }
        formErrors.price = ''
        break

      default:
        break
    }
    this.setState({ formErrors })
    this.setState({ errMsg: ' ' })
    const formfieldsState = this.state.formfields
    if ((name === 'mobileNo') || (name === 'recipientMobile') || (name === 'price')) {
      if (!isNaN(value)) { formfieldsState[e.target.name] = e.target.value }
    } else {
      formfieldsState[e.target.name] = e.target.value
    }
    // formfieldsState[e.target.name] = e.target.value
    this.setState({ formfieldsState })
  }

  // Drop down event
  handleDropDownChange (event, reference) {
    const updatedStates = this.handleDropdownState(event) // return the updated values from drop down
    const removedError = this.state.formErrors
    const formfields = this.state.formfields
    const formfieldService = this.state.formfieldService
    removedError[event.target.name] = ''
    this.setState({ updatedStates })
    if (reference === 'city') {
      const data = this.state.cityDetails.filter((values) =>
        values.cityId === event.target.value.cityId)
      // console.log(data)
      formfields.location = ''
      formfieldService.serviceName = ''
      formfieldService.serviceTypeName = ''
      formfieldService.serviceTime = ''
      this.setState({ locationDetails: data[0].locationList, formfields: formfields, formfieldService: formfieldService })
      this.setState({ services: data[0].serviceList })
    } else if (reference === 'location') {
      // const data = this.state.locationDetails.filter((values) => values.location === event.target.value.location)
      const data = this.state.locationDetails.filter((values) =>
        values.locationId === event.target.value.locationId
      )
      console.log("-------------", this.state)
      // if(event.target.value.location === 'DLF Promenade Mall (Vasant Kunj)') {
      //   var newData = this.state.services.filter((item) => {
      //     var resetBodyservice = ['Body Massages', 'Body Scrubs', 'Body Masques' ]
      //     if( resetBodyservice.indexOf(item.serviceName) >= 0)  {
      //       item.serviceName = ""
      //     }
      //   })
      // }
      const formfields = this.state.formfields
       formfields.location = data[0].location
       formfields.locationId = data[0].locationId
      this.setState({ formFields: formfields, branchEmail: data[0].email })
      //   console.log("locationselected", data)
      // this.setState({services: data[0].services})
      // this.setState({serviceTypeIds: data[0].serviceTypeList})
    } else if (reference === 'serviceName') {
      const data = this.state.services.filter((values) =>
        values.serviceName === event.target.value.serviceName)
      this.setState({ serviceTypes: data[0].serviceTypesList })
      formfieldService.serviceTypeName = ''
      formfieldService.serviceTime = ''
    } else if (reference === 'serviceType') {
      const data = this.state.serviceTypes.filter((values) =>
        values.serviceTypeName === event.target.value.serviceTypeName)
      this.setState({ serviceTimes: data[0].serviceHours })
      formfieldService.serviceTime = ''
    } else if (reference === 'serviceTime') {
      const data = this.state.serviceTimes.filter((values) =>
        values.serviceTime === event.target.value.serviceTime)
      const formfields = { ...this.state.formfields }
      formfields.price = data[0].servicePrice
      removedError.price = ''
      this.setState({ formfields })
    }
    this.setState({ removedError })
  }

  // construct the object for drop down with this name
  handleDropdownState (event) {
    const targetObj = (event.target.element.classList.contains('service_details')) ? this.state.formfieldService : this.state.formfields
    targetObj[event.target.name] = event.target.value[event.target.name]
    return targetObj[event.target.name]
  }

  giftCardSelection (giftName, e) {
    this.setState({ giftCardName: giftName })
  }

  componentDidMount () {
    if (this.props.selectedGift === 'voucher') {
      this.setState({ toggleIsChecked: true })
    }
    // this.setState({ defaultCity: window.localStorage.getItem('cityName').toLowerCase() })
    if (this.state.defaultCity !== '') {
      const data = this.state.cityDetails.filter((values) =>
        values.city.toLowerCase() === this.state.defaultCity.toLowerCase())
      // console.log(data)
      this.setState({ defaultCityId: data[0].cityId, locationDetails: data[0].locationList })
      this.setState({ services: data[0].serviceList })
    }
  }

  render () {
    return (
      <div id='onlinegift-container'>
        <div className='wrapper'>
          <OverlayHead overlaycontent={this.state.overlayHead} />
          {/* <div className='gift-card-container'>
            <div className='plan-outer'>
              <div className={'plan-inner' + bdWishes}>
                <img className='img-thumbnail' src='../../assets/images/ic_happy birthday.svg' alt='happy birthday' onClick={() => { this.giftCardSelection('Birthday Wishes') }} /> </div>
            </div>
            <div className='plan-outer'>
              <div className={'plan-inner' + HappyAn}>
                <img className='img-thumbnail' src='../../assets/images/ic_happy anniversary.svg' alt='happy birthday' onClick={() => { this.giftCardSelection('Happy Anniversary') }} /> </div>
            </div>
            <div className='plan-outer'>
              <div className={'plan-inner' + BestWises}>
                <img className='img-thumbnail' src='../../assets/images/ic_best wishes.svg' alt='happy birthday' onClick={() => { this.giftCardSelection('Best Wishes') }} /> </div>
            </div>
          </div> */}
          <div className='gift-card-container'>
            <div className='plan-outer'>
              <div className='plan-inner'>
                <input type='radio' className='img-radio' id='hb' name='giftcard' /><label className='img-parent'><label className='img-thumbnail' htmlFor='hb'><img className='img-thumbnail' src='../../assets/images/ic_happy birthday.svg' alt='happy birthday' onClick={() => { this.giftCardSelection('Birthday Wishes') }} /></label></label>
              </div>
            </div>
            <div className='plan-outer'>
              <div className='plan-inner'>
                <input type='radio' className='img-radio' id='ha' name='giftcard' /> <label className='img-parent'><label className='img-thumbnail ' htmlFor='ha'><img className='img-thumbnail' src='../../assets/images/ic_happy anniversary.svg' alt='happy birthday' onClick={() => { this.giftCardSelection('Happy Anniversary') }} /> </label></label>
              </div>
            </div>
            <div className='plan-outer'>
              <div className='plan-inner'>
                <input type='radio' className='img-radio' id='bw' name='giftcard' /><label className='img-parent'><label className='img-thumbnail' htmlFor='bw'><img className='img-thumbnail' src='../../assets/images/ic_best wishes.svg' alt='happy birthday' onClick={() => { this.giftCardSelection('Best Wishes') }} /> </label></label>
              </div>
            </div>
          </div>
          <ToggleButton checkedStatus={this.state.toggleIsChecked} toggleValue='Service,Amount' toggleClick={this.toggleClick} />
          {(!this.state.toggleIsChecked) 
            ? <OnlineGiftService itemRender={this.itemRender} handleDropDownChange={this.handleDropDownChange} isNumber={this.state.mobileNo} handleInputChange={this.handleOnChangeInput} currentState={this.state} /> 
            : <OnlineGiftAmount itemRender={this.itemRender} handleDropDownChange={this.handleDropDownChange} isreciptMobile={this.state.recipientMobile} handleInputChange={this.handleOnChangeInput} currentState={this.state} />
          }
          <div className='cls-err-msg'>{this.state.errMsg}</div>
          <div className={this.state.mailStatus}>{this.state.mailMessage}&nbsp;</div>
          <div className='form-submit-section'>
            <div className={this.state.disablebtn}>
              <div className='btn register-booking' data-testid = "buyNow" onClick={this.onFormSubmit}>BUY NOW</div>
            </div>
          </div>
        </div>

      </div>
    )
  }
}
export default withRouter(OnlineGift)
