import React from 'react'
import './Video.css'
export function Video (props) {
  return (
    <div className='video-container'>
      <video loop autoPlay muted controls width="100%" height ="100%">
        <source src='https://storage.googleapis.com/aromathai-assets/VIDEO-2020-08-20-11-02-20.mp4' type="video/mp4" />
      </video>
    </div>
  );
}