import React, { Component } from 'react'
import Slide from 'react-reveal/Slide'
// css
import './QuickMenu.css'
import './QuickMenuResponsive.css'

class QuickMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  externalLinks(link) {
    let win = window.open(link, '_blank')
    if (win) {
      win.focus() // opens the link in new tab
    }
  }

  render() {
    return (
      <div id='quick-menu' data-testid='quickmenucomponent'>
        <Slide right duration={1000}>
          <div className='row-outer buy-blk' onClick={this.props.handleOverlay}>
            <span className='cart-icon' />
            <p>buy <br />membership</p>
          </div>
          <div className='row-outer social-blk'>
            <span className='inst-icon'  onClick={() => this.externalLinks('https://www.instagram.com/aromathaispa/')} />
            <span className='fb-icon'  onClick={() => this.externalLinks('https://www.facebook.com/AromathaiDaySpa/')} />
          </div>
        </Slide>
      </div>
    )
  }
}

export default QuickMenu
