import React from 'react'
import { ccavencrypt, ccavAccessCode, ccavWorkingKey } from '../../global'

import './CCAvenue.css'

class CCAvenue extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      accessCode: ccavAccessCode,
      encRequest: ccavencrypt(this.props.location.state.orderKey, ccavWorkingKey) // ccavencrypt(data, workingkey)
    }
  }

  // Below code to submit and show ccavenue billing page
  componentDidMount () { document.redirect.submit() }

  render () {
    return (
      <form id='nonseamless' method='post' name='redirect' action='https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction'>
        <input type='hidden' id='encRequest' name='encRequest' value={this.state.encRequest} />
        <input type='hidden' id='access_code' name='access_code' value={this.state.accessCode} />
      </form>
    )
  }
}

export default CCAvenue
// Dont delete this code
// merchant_id=92471&order_id=12345&currency=INR&amount=1.00&redirect_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&cancel_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&language=EN&billing_name=Peter&billing_address=Santacruz&billing_city=Mumbai&billing_state=MH&billing_zip=400054&billing_country=India&billing_tel=9876543210&billing_email=testing%40domain.com&delivery_name=Sam&delivery_address=Vile+Parle&delivery_city=Mumbai&delivery_state=Maharashtra&delivery_zip=400038&delivery_country=India&delivery_tel=0123456789&merchant_param1=additional+Info.&merchant_param2=additional+Info.&merchant_param3=additional+Info.&merchant_param4=additional+Info.&merchant_param5=additional+Info.&promo_code=&customer_identifier=

// Sample request query param
// merchant_id=92471&
// order_id=12345&
// currency=INR&
// amount=1.00&
// redirect_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&
// cancel_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&
// language=EN&
// billing_name=Peter&
// billing_address=Santacruz&
// billing_city=Mumbai&
// billing_state=MH&
// billing_zip=400054&
// billing_country=India&
// billing_tel=9876543210&
// billing_email=testing%40domain.com&
// delivery_name=Sam&
// delivery_address=Vile+Parle&
// delivery_city=Mumbai&
// delivery_state=Maharashtra&
// delivery_zip=400038&
// delivery_country=India&
// delivery_tel=0123456789&
// merchant_param1=additional+Info.&
// merchant_param2=additional+Info.&
// merchant_param3=additional+Info.&
// merchant_param4=additional+Info.&
// merchant_param5=additional+Info.&
// promo_code=&
// customer_identifier=



/// Sample response
// order_id=778899001122&
// tracking_id=108708574523&
// bank_ref_no=null&
// order_status=Initiated&
// failure_message=&
// payment_mode=Credit Card&
// card_name=Visa&
// status_code=&
// status_message=card not supported&
// currency=INR&
// amount=1.00&
// billing_name=Radhakrishnan Y&
// billing_address=Tringapps Research Labs pvt ltd&
// billing_city=Chennai&
// billing_state=Tamilnadu&
// billing_zip=600113&
// billing_country=India&
// billing_tel=9789901130&
// billing_email=radhakrishnan.y@tringapps.com&
// delivery_name=Radhakrishnan Y&
// delivery_address=Tringapps Research Labs pvt ltd&
// delivery_city=Chennai&
// delivery_state=Tamilnadu&
// delivery_zip=600113&
// delivery_country=India&
// delivery_tel=9789901130&
// merchant_param1=&
// merchant_param2=&
// merchant_param3=&
// merchant_param4=&
// merchant_param5=&
// vault=N&
// offer_type=null&
// offer_code=null&
// discount_value=0.0&
// mer_amount=1.00&
// eci_value=&
// retry=null&
// response_code=&
// billing_notes=Checking payment&
// trans_date=19/11/2019 16:39:29&
// bin_country=
