import React, { Component } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'

class ServiceDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  componentDidMount () {
    // console.log('dsf',this.props.currentService.serviceTypes)
  }

  handleServiceNameList (data, cityName, locationId) {
    var newData = data
    var removeList = [1, 2 ,3]
    if(cityName === 'Delhi-NCR' && locationId === 10) {
      newData = data.filter((item) => removeList.indexOf(item.serviceId) === -1);
    }
    return newData
  }

  render () {
    // console.log('service====================', this.props.currentState)
    return (
      <div className='dynamic-service-sec' id={this.props.service_id} data-testid = "service">
        <div className='person-heading'>
          <hr />
          <label><span>PERSON {this.props.service_id}</span></label>
          <label className='service-close-icon'> <span name='check' data-id={this.props.service_dataset_id} onClick={(e) => this.props.handleServiceRemoveEvent(e)} /></label>
        </div>
        <div className='form-container'>
          <div className='left-container'>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Service*</label>
                <DropDownList
                  disabled={this.props.currentState.formFields.bookingCity === '' || this.props.currentState.formFields.bookingLocation === ''}
                  data={this.handleServiceNameList(this.props.currentState.serviceNameList, this.props.currentState.formFields.bookingCity, this.props.currentState.formFields.bookingLocationId)}
                  dataItemKey='serviceId'
                  textField='serviceName'
                  name='serviceName'
                  id={this.props.service_id}
                  className={this.props.currentService.serviceError.serviceName}
                  data-id={this.props.service_dataset_id}
                  value={{ serviceId: this.props.currentService.serviceTypeId, serviceName: (this.props.currentService.serviceDetail.serviceName !== '') ? this.props.currentService.serviceDetail.serviceName : 'Select Service' }}
                  onChange={(e) => this.props.handleServiceDropDownChange(e, 'serviceName')}
                />
              </div>
            </div>
            {/* to show this only desktop view */}
            <div className='input-container show-to-pc'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Service Time*</label>
                <DropDownList
                  disabled={this.props.currentService.serviceDetail.serviceTypeName === ''}
                  data={this.props.currentService.serviceTimes}
                  dataItemKey='serviceTime'
                  textField='serviceTime'
                  name='serviceTime'
                  id={this.props.service_id}
                  data-id={this.props.service_dataset_id}
                  value={{ id: null, serviceTime: (this.props.currentService.serviceDetail.serviceTime !== '') ? this.props.currentService.serviceDetail.serviceTime : 'Select Service Time' }}
                  className={this.props.currentService.serviceError.serviceTime}
                  onChange={(e) => this.props.handleServiceDropDownChange(e, 'serviceTime')}
                />
              </div>
            </div>
          </div>
          <div className='right-container'>
            <div className='input-container'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Service Type*</label>
                <DropDownList
                  disabled={this.props.currentService.serviceDetail.serviceName === ''}
                  data={this.props.currentService.serviceTypes}
                  dataItemKey='serviceTypeName'
                  textField='serviceTypeName'
                  name='serviceTypeName'
                  id={this.props.service_id}
                  data-id={this.props.service_dataset_id}
                  className={this.props.currentService.serviceError.serviceTypeName}
                  value={{ serviceTypeId: this.props.currentService.serviceTypeId, serviceTypeName: (this.props.currentService.serviceDetail.serviceTypeName !== '') ? this.props.currentService.serviceDetail.serviceTypeName : 'Select Service Type' }}
                  // defaultValue={{ serviceTypeId: this.props.currentService.serviceTypeId, serviceTypeName: (this.props.currentService.serviceDetail.serviceTypeName !== '') ? this.props.currentService.serviceDetail.serviceTypeName : '-Select Service Type-' }}
                  onChange={(e) => this.props.handleServiceDropDownChange(e, 'serviceTypeName')}
                />
              </div>
            </div>
            {/* to show this only for mobile view */}
            <div className='input-container show-to-mob'>
              <div className='input-groups input-dropdown'>
                <label className='field-caption'>Service Time*</label>
                {/* <label>{JSON.stringify(this.props.currentService.serviceDetail)}</label> */}
                <DropDownList
                  disabled={this.props.currentService.serviceDetail.serviceTypeName === ''}
                  data={this.props.currentService.serviceTimes}
                  dataItemKey='serviceTime'
                  textField='serviceTime'
                  name='serviceTime'
                  id={this.props.service_id}
                  data-id={this.props.service_dataset_id}
                  value={{ id: null, serviceTime: (this.props.currentService.serviceDetail.serviceTime !== '') ? this.props.currentService.serviceDetail.serviceTime : 'Select Service Time' }}
                  className={this.props.currentService.serviceError.serviceTime}
                  onChange={(e) => this.props.handleServiceDropDownChange(e, 'serviceTime')}
                />
              </div>
            </div>
            <div className='input-container'>
              <div className='input-groups'>
                <label className='field-caption'>Price</label>
                {/* <input type='text' value={this.props.currentService.serviceDetail.price} data-id={this.props.service_dataset_id} onChange={(e) => this.props.handleServiceDropDownChange(e)} name='price' id={this.props.price}  placeholder='₹ 0' readOnly /> */}
                <div className=' price'>₹ {this.props.currentService.serviceDetail.price}</div>
              </div>
            </div>

          </div>

        </div>
        {/* <div className={this.props.currentState.Addonclass}>
          <div className=''>This is addon service please choose addon</div>
          <div className='input-container show-to-pc'>
            <div className='input-groups input-dropdown'>
              <label className='field-caption'>Addon services*</label>
              <DropDownList
                disabled={this.props.currentService.serviceDetail.serviceTypeName === ''}
                data={this.props.currentService.Addons}
                // dataItemKey='serviceTime'
                // textField='serviceTime'
                // name='serviceTime'
                // id={this.props.service_id}
                // data-id={this.props.service_dataset_id}
                // value={{ id: null, serviceTime: (this.props.currentService.serviceDetail.serviceTime !== '') ? this.props.currentService.serviceDetail.serviceTime : '-Select Service Time-' }}
                // className={this.props.currentService.serviceError.serviceTime}
                onChange={(e) => this.props.handleServiceDropDownChange(e, 'serviceAddon')}
              />
            </div>
          </div>
        </div> */}

      </div>

    )
  }
}
export default ServiceDetails
