import React from 'react'
import './CCAVErrorHandler.css'

class CCAVErrorHandler extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  componentDidMount () {
    localStorage.removeItem('orderDetail')
    localStorage.removeItem('orderDetailType')
  }

  render () {
    return (
      <div id='ccav-block'>
        <div className='ccav-box'>
          <div className='ic-ccav-error' />
          <div className='ccav-succ-title'>Payment Failed</div>
          <div className='ccav-succ-sub-title'>Please try after sometime</div>
          <div className='ccav-go-back'>Click here to go back <a href='/'>Home</a></div>
        </div>
      </div>
    )
  }
}

export default CCAVErrorHandler
// Dont delete this code
// merchant_id=92471&order_id=12345&currency=INR&amount=1.00&redirect_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&cancel_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&language=EN&billing_name=Peter&billing_address=Santacruz&billing_city=Mumbai&billing_state=MH&billing_zip=400054&billing_country=India&billing_tel=9876543210&billing_email=testing%40domain.com&delivery_name=Sam&delivery_address=Vile+Parle&delivery_city=Mumbai&delivery_state=Maharashtra&delivery_zip=400038&delivery_country=India&delivery_tel=0123456789&merchant_param1=additional+Info.&merchant_param2=additional+Info.&merchant_param3=additional+Info.&merchant_param4=additional+Info.&merchant_param5=additional+Info.&promo_code=&customer_identifier=

// Sample data to pass into ccavenue
// merchant_id=92471&
// order_id=12345&
// currency=INR&
// amount=1.00&
// redirect_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&
// cancel_url=http%3A%2F%2Flocalhost%2FccavResponseHandler&
// language=EN&
// billing_name=Peter&
// billing_address=Santacruz&
// billing_city=Mumbai&
// billing_state=MH&
// billing_zip=400054&
// billing_country=India&
// billing_tel=9876543210&
// billing_email=testing%40domain.com&
// delivery_name=Sam&
// delivery_address=Vile+Parle&
// delivery_city=Mumbai&
// delivery_state=Maharashtra&
// delivery_zip=400038&
// delivery_country=India&
// delivery_tel=0123456789&
// merchant_param1=additional+Info.&
// merchant_param2=additional+Info.&
// merchant_param3=additional+Info.&
// merchant_param4=additional+Info.&
// merchant_param5=additional+Info.&
// promo_code=&
// customer_identifier=